<template>
  <div
      class="w-full"
      style="
      padding: 16px 16px 30px 16px;
      height: calc(100vh - 100px);
      overflow-y: scroll;
      background-color: #000a32;
    "
  >
    <div class="w-full h-full flex">
      <!-- 右侧内容 -->
      <div class="flex-1 flex ml-4" style="color: #fff">
        <!-- 设备内容 -->
        <div class="flex-1">
          <div class="w-full flex items-center">
            <div class="flex-1"></div>
            <div class="btn1 btn1-select">{{ langObj["单值"] }}单值</div>
            <div class="btn1" @click="redirectToGroupModel">{{ langObj["组合值"] }}组合值</div>
            <div class="flex-1"></div>
          </div>

          <div
              class="w-full flex px-4 mt-2"
              style="border: 1px solid #4a5983; background: #07249933">
            <div
                style="font-size: 18px"
                class="py-3 cursor-pointer"
                @click="selectMenu(1)"
                :style="{  borderBottom: menuSelect === 1 ? '2px solid #00FFF4' : ''}">
              {{ langObj["生成阈值"] || "生成阈值" }}
            </div>

            <div
                style="font-size: 18px"
                class="py-3 ml-12 cursor-pointer"
                @click="selectMenu(2)"
                :style="{borderBottom: menuSelect === 2 ? '2px solid #00FFF4' : ''}">
              {{ langObj["阈值模板管理"] || "阈值模板管理" }}
            </div>

            <div
                style="font-size: 18px"
                class="py-3 ml-12 cursor-pointer"
                @click="selectMenu(3)"
                :style="{ borderBottom: menuSelect === 3 ? '2px solid #00FFF4' : '' }">
              {{ langObj["阈值模板管理"] || "单值报警规则" }}
            </div>
          </div>
          <div v-show="menuSelect == 1||menuSelect==3">
            <div class="w-full flex mt-2">
              <!-- 左侧设备列表 -->
              <div v-show="menuSelect==1||menuSelect==3" style="min-width: 250px">
                <div style="width: 250px" :style="{ top: scrollTop }">
                  <machineListLeftComponent
                      @selectSensor="selectSensor"
                      :type="2"
                  >
                  </machineListLeftComponent>
                </div>
              </div>

              <div v-show="menuSelect==1" class="flex-1">
                <div class="flex-1 content ml-2">
                  <div class="w-full flex items-center">
                    <div class="flex-1"></div>
                    <a-radio-group
                        name="radioGroup"
                        v-model:value="rdoSimpleDataType"
                        @change="selectDataType"
                    >
                      <a-radio
                          :value="item.code"
                          :key="index"
                          v-for="(item, index) in compareDataType"
                      >{{ langObj[item.name] }}
                      </a-radio
                      >
                    </a-radio-group>
                    <div class="flex-1"></div>
                  </div>
                  <div class="flex items-center mt-4">
                    <div class="flex-1"></div>
                    <div class="flex ml-2">
                      <div
                          class="btn2 px-3 py-1"
                          :class="{ 'text-white': simpleSearchModel.type == '1' }"
                          :style="{
                          background:
                            simpleSearchModel.type == '1' ? '#072498' : '',
                        }"
                          style="border-radius: 5px 0 0 5px"
                          @click="changeSimpleDateType('1')"
                      >
                        {{ langObj["近一天"] }}
                      </div>
                      <div
                          class="btn2 px-3 py-1"
                          :class="{ 'text-white': simpleSearchModel.type == '2' }"
                          :style="{
                          background:
                            simpleSearchModel.type == '2' ? '#072498' : '',
                        }"
                          @click="changeSimpleDateType('2')"
                      >
                        {{ langObj["近一周"] }}
                      </div>
                      <div
                          class="btn2 px-3 py-1"
                          :class="{ 'text-white': simpleSearchModel.type == '3' }"
                          :style="{
                          background:
                            simpleSearchModel.type == '3' ? '#072498' : '',
                        }"
                          @click="changeSimpleDateType('3')"
                      >
                        {{ langObj["近一月"] }}
                      </div>
                      <div
                          class="btn2 px-3 py-1"
                          :style="{
                          background:
                            simpleSearchModel.type == '0' ? '#072498' : '',
                        }"
                          :class="{ 'text-white': simpleSearchModel.type == '0' }"
                          @click="simpleSearchModel.type = '0'"
                          style="border-radius: 0 5px 5px 0"
                      >
                        {{ langObj["自定义"] }}
                      </div>
                    </div>

                    <div class="ml-2" v-if="simpleSearchModel.type == '0'">
                      <a-range-picker
                          v-model:value="simpleSearchModel.date"
                          :disabled-date="disabledDate"
                          @calendarChange="onCalendarChange"
                      />
                    </div>

                    <div @click="quotaSearch" class="btn3 ml-2">
                      {{ langObj["查询"] }}
                    </div>

                    <div class="flex-1"></div>
                  </div>
                  <div class="w-full flex items-center">
                    <div id="chart1" style="width: 100%; height: 322px"></div>
                  </div>
                </div>

                <div class=" mt-4 content ml-2">
                  <div class="flex-1"></div>
                  <div>
                    <a-form
                        :model="form"
                        ref="formStateRef"
                        name="basic"
                        autocomplete="off">
                      <a-row :gutter="24" class="mb-2">
                        <a-col :span="2"></a-col>
                        <a-col :span="6" class="text-white">可用报警</a-col>
                        <a-col :span="6" class="text-white">警戒报警</a-col>
                        <a-col :span="6" class="text-white">故障报警</a-col>
                        <a-col :span="3" class="text-white">熔断报警</a-col>
                      </a-row>
                      <a-row :gutter="24" class="mb-2 text-white">
                        <a-col :span="2">
                          <span>初始阈值</span>
                        </a-col>
                        <a-col :span="6">
                          <span v-if="standardThreshold.level1Threshold!=null">
                            笔数：{{ standardThreshold.level1RangeCount ?? 0 }}
                            笔内累计{{ standardThreshold.level1LimitCount ?? 0 }}
                            笔大于阈值：{{ standardThreshold.level1Threshold }}
                          </span>
                        </a-col>
                        <a-col :span="6">
                          <span v-if="standardThreshold.level2Threshold!=null">
                            笔数：{{ standardThreshold.level2RangeCount ?? 0 }}
                            笔内累计{{ standardThreshold.level2LimitCount ?? 0 }}
                            笔大于阈值：{{ standardThreshold.level2Threshold }}
                          </span>
                        </a-col>
                        <a-col :span="6">
                          <span v-if="standardThreshold.level3Threshold!=null">
                            笔数：{{ standardThreshold.level3RangeCount ?? 0 }}
                            笔内累计{{ standardThreshold.level3LimitCount ?? 0 }}
                            笔大于阈值：{{ standardThreshold.level3Threshold }}</span>
                        </a-col>
                        <a-col :span="2">
                          <span v-if="standardThreshold.level4Threshold!=null">
                            阈值：{{ standardThreshold.level4Threshold }}
                          </span>
                        </a-col>
                        <a-col :span="2"
                               v-if="standardThreshold.level1Threshold && standardThreshold.level2Threshold && standardThreshold.level3Threshold">
                          <a-button type="primary" class="btn3" @click="onClickSelectThresholds(standardThreshold)">
                            选中
                          </a-button>
                        </a-col>
                      </a-row>
                      <a-row :gutter="24" class="mb-2 text-white">
                        <a-col :span="2">
                          <span>当前阈值</span>
                        </a-col>
                        <a-col :span="6">
                          <span v-if="currentThreshold.level1Threshold!=null">
                            笔数：{{ currentThreshold.level1RangeCount ?? 0 }}
                            笔内累计{{ currentThreshold.level1LimitCount ?? 0 }}
                            笔大于阈值：{{ currentThreshold.level1Threshold }}
                          </span>
                        </a-col>
                        <a-col :span="6">
                          <span v-if="currentThreshold.level2Threshold!=null">
                            笔数：{{ currentThreshold.level2RangeCount ?? 0 }}
                            笔内累计{{ currentThreshold.level2LimitCount ?? 0 }}
                            笔大于阈值：{{ currentThreshold.level2Threshold }}
                          </span>
                        </a-col>
                        <a-col :span="6">
                          <span v-if="currentThreshold.level3Threshold!=null">
                            笔数：{{ currentThreshold.level3RangeCount ?? 0 }}
                            笔内累计{{ currentThreshold.level3LimitCount ?? 0 }}
                            笔大于阈值：{{ currentThreshold.level3Threshold }}</span>
                        </a-col>
                        <a-col :span="2">
                          <span v-if="currentThreshold.level4Threshold!=null">
                            阈值：{{ currentThreshold.level4Threshold }}
                          </span>
                        </a-col>
                        <a-col :span="2"
                               v-if="currentThreshold.level1Threshold && currentThreshold.level2Threshold && currentThreshold.level3Threshold">
                          <a-button type="primary" class="btn3" @click="onClickSelectThresholds(currentThreshold)">
                            选中
                          </a-button>
                        </a-col>
                      </a-row>
                      <a-row :gutter="24" class="mb-2 text-white">
                        <a-col :span="2">
                          <span style="color: #fff">智能统计</span>
                        </a-col>
                        <a-col :span="6">
                          <span v-if="smartThreshold.level1Threshold!=null" style="color: #fff">
                            笔数：{{ smartThreshold.level1RangeCount ?? 0 }}
                            笔内累计{{ smartThreshold.level1LimitCount ?? 0 }}
                            笔大于阈值：{{ smartThreshold.level1Threshold }}
                          </span>
                        </a-col>
                        <a-col :span="6">
                          <span v-if="smartThreshold.level2Threshold!=null">
                            笔数：{{ smartThreshold.level2RangeCount ?? 0 }}
                            笔内累计{{ smartThreshold.level2LimitCount ?? 0 }}
                            笔大于阈值：{{ smartThreshold.level2Threshold }}
                          </span>
                        </a-col>
                        <a-col :span="6">
                          <span v-if="smartThreshold.level3Threshold!=null">
                            笔数：{{ smartThreshold.level3RangeCount ?? 0 }}
                            笔内累计{{ smartThreshold.level3LimitCount ?? 0 }}
                            笔大于阈值：{{ smartThreshold.level3Threshold }}</span>
                        </a-col>
                        <a-col :span="2" class="text-white">
                          <span v-if="smartThreshold.level4Threshold!=null">
                           阈值：{{ smartThreshold.level4Threshold }}
                          </span>
                        </a-col>
                        <a-col :span="2"
                               v-if="smartThreshold.level1Threshold && smartThreshold.level2Threshold && smartThreshold.level3Threshold">
                          <a-button type="primary" class="btn3" @click="onClickSelectThresholds(smartThreshold)">选中
                          </a-button>
                        </a-col>
                      </a-row>
                      <a-row :gutter="24" class="mb-2 text-white">
                        <a-col :span="2">
                          <span>新阈值</span></a-col>
                        <a-col :span="6">
                          笔数：
                          <a-input-number class="w30" v-model:value="form.level1RangeCount" type="number" min="0"
                                          step="1" :controls="false" size="small"/>
                          笔内累计
                          <a-input-number class="w30" v-model:value="form.level1LimitCount" type="number" min="0"
                                          step="1" :controls="false" size="small"/>
                          笔大于阈值：
                          <a-input-number class="w50" v-model:value="form.level1Threshold" type="number" min="0"
                                          :controls="false" size="small"/>
                        </a-col>
                        <a-col :span="6">
                          笔数：
                          <a-input-number class="w30" v-model:value="form.level2RangeCount" type="number" min="0"
                                          step="1" :controls="false" size="small"/>
                          笔内累计
                          <a-input-number class="w30" v-model:value="form.level2LimitCount" type="number" min="0"
                                          step="1" :controls="false" size="small"/>
                          笔大于阈值：
                          <a-input-number class="w50" v-model:value="form.level2Threshold" type="number" min="0"
                                          :controls="false" size="small"/>
                        </a-col>
                        <a-col :span="6">
                          笔数：
                          <a-input-number class="w30" v-model:value="form.level3RangeCount" type="number" min="0"
                                          step="1" :controls="false" size="small"/>
                          笔内累计
                          <a-input-number class="w30" v-model:value="form.level3LimitCount" type="number" min="0"
                                          step="1" :controls="false" size="small"/>
                          笔大于阈值：
                          <a-input-number class="w50" v-model:value="form.level3Threshold" type="number" min="0"
                                          :controls="false" size="small"/>
                        </a-col>
                        <a-col :span="3">
                          <span>
                            阈值： 
                            <a-input-number class="w80" v-model:value="form.level4Threshold" type="number" min="0"
                                            :controls="false" size="small"/>
                          </span>
                        </a-col>
                      </a-row>
                      <a-spin :spinning="isLoadingPrompt">
                        <a-row :gutter="24" class="mb-2 text-red-600">
                          <a-col :span="2"></a-col>
                          <a-col :span="6">
                          <span v-if="form.level1Prompt">
                           {{ form.level1Prompt }}
                          </span>
                          </a-col>
                          <a-col :span="6">
                          <span v-if="form.level2Prompt">
                          {{ form.level2Prompt }}
                          </span>
                          </a-col>
                          <a-col :span="6">
                          <span v-if="form.level3Prompt">
                            {{ form.level3Prompt }}
                          </span>
                          </a-col>
                          <a-col :span="3">
                          <span v-if="form.level4Prompt">
                            {{ form.level4Prompt }}
                          </span>
                          </a-col>
                        </a-row>
                      </a-spin>
                      <a-row class="text-white flex mb-2">
                        <div class="flex-1"></div>
                        <div>
                            <span>
                            阈值设置规则：在只存在健康标签的情况下，基于数据驱动获取可用状态的阈值，参考振动相对标准，警戒状态阈值为可用阈值*2，故障状态阈值边界为警戒阈值*2
                            </span>
                        </div>
                        <div class="flex-1"></div>
                      </a-row>
                      <a-row class="text-white flex">
                        <div class="flex-1"></div>
                        <div>
                          <a-button
                              type="primary"
                              class="ml-2 btn3"
                              :loading="isApplyingThreshold"
                              @click="onClickApplyThreshold"
                          >{{ langObj["应用阈值"] }}应用阈值及笔数
                          </a-button>
                        </div>
                        <div class="flex-1"></div>
                      </a-row>
                    </a-form>

                    <div class="flex" v-if="false">
                      <div class="flex-1"></div>
                      <a-button type="primary" class="btn3" @click="onClickApplyThreshold">{{
                          langObj["保存"]
                        }}
                      </a-button>
                      <div class="flex-1"></div>
                    </div>
                  </div>
                  <div class="flex-1"></div>
                </div>
              </div>

              <div v-show="menuSelect==3" class="flex-1 ms-2 pb-2">
                <alarm-item :sensor="sensorInfo" :key="sensorInfo.id" :machineId="sensorInfo.machineId"
                            @search="()=>{}"></alarm-item>
              </div>
            </div>
            <!-- 雷达图 -->
          </div>

          <div v-show="menuSelect == 2" class="mt-2">
            <simple-model :data-types="dataTypes"></simple-model>
          </div>

          <!--
          <div v-show="menuSelect==3">
            <div class="mb-2" style="padding-bottom: 20px">
              <alarm-item :sensor="sensorInfo" :key="sensorInfo.id" :machineId="sensorInfo.machineId"
                          @search="()=>{}"></alarm-item>
            </div>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate2, } from "@/common/tools";
import { v4 as uuidv4 } from "uuid";
import machineListLeftComponent from "../components/machine-list-left.vue";
import * as echarts from "echarts";
import { Dayjs } from "dayjs";
import { langList } from "@/common/lang";
import { message } from "ant-design-vue";
import SimpleModel from "@/components/model/simple-model.vue";
import AlarmItem from "@/components/system/common/AlarmItem.vue";

const router = useRouter();
let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();

const redirectToGroupModel = () => {
  router.push("group-model");
}

let sensorInfo: any = ref({});

class ThresholdItem {
  level1Threshold: number | null;
  level1RangeCount: number | null;
  level1LimitCount: number | null;
  level1IntervalDays: number | null = 7;
  level1IntervalTimes: number | null = 1;
  level2Threshold: number | null;
  level2RangeCount: number | null;
  level2LimitCount: number | null;
  level2IntervalDays: number | null = 7;
  level2IntervalTimes: number | null = 1;
  level3Threshold: number | null;
  level3RangeCount: number | null;
  level3LimitCount: number | null;
  level3IntervalDays: number | null = 7;
  level3IntervalTimes: number | null = 1;
  level4Threshold: number | null;
  level4IntervalDays: number | null = 7;
  level4IntervalTimes: number | null = 1;
}

let form: any = ref(new ThresholdItem());

let isLoadingPrompt = ref(false);

let thresholdChangeTimeout: any = null; // 防抖
watch([() => form.value.level1Threshold, () => form.value.level2Threshold, () => form.value.level3Threshold,
      () => form.value.level1RangeCount, () => form.value.level2RangeCount, () => form.value.level3RangeCount],
    ([val, val2, val3, val4, val5, val6], [old, old2, old3, old4, old5, old6]) => {

      // input变更时, 生成累计笔数提示信息, 生成的阈值不回写到input里

      if (!val4 && !val5 && !val6) return; // 必须填写一个阈值
      if (!val && !val2 && !val3) return;

      let changedCount = 0;
      if (val != old) changedCount++;
      if (val2 != old2) changedCount++;
      if (val3 != old3) changedCount++;
      if (val4 != old4) changedCount++;
      if (val5 != old5) changedCount++;
      if (val6 != old6) changedCount++;
      if (changedCount > 1) return;

      if (thresholdChangeTimeout) {
        clearTimeout(thresholdChangeTimeout)
      }

      thresholdChangeTimeout = setTimeout(async () => {
        isLoadingPrompt.value = true;
        let data = await doGenerateThreshold(null)
        let prompts = [];
        if (data && data.length >= 4) {
          prompts = data[3];
        }
        //SetGeneratedThreshold(data as any[]);
        setThresholdPrompt(prompts as any[]);
      }, 1500)
    });

let compareDataType: any = ref([]);

/** 初始阈值(标准/参考阈值) */
let standardThreshold: any = ref(new ThresholdItem());

/** 当前阈值/配置的阈值 */
let currentThreshold: any = ref(new ThresholdItem());

/** 智能统计/模板阈值 */
let smartThreshold: any = ref(new ThresholdItem());

let rdoSimpleDataType = ref(0);

let simpleSearchModel = ref({
  type: "1",
  date: "",
});

let menuSelect = ref(1);

let scrollTop = ref("120px");

const selectSensor = async (ev: any) => {
  let result = await request.get("/api/sensors/info?id=" + ev);
  if (result.data) {
    sensorInfo.value = result.data;
    await loadDataTypes();
    if (result.data.dataType) {
      compareDataType.value = dataTypes.value.filter((p: any) =>
          result.data.dataType.includes(p.name)
      );
    } else {
      compareDataType.value = [];
    }

    await getQuotaAlarmConfig();
    await selectDataType();
  }
};

onMounted(() => {
  loadDataTypes();
  myChart = (echarts as any).init(document.getElementById("chart1"));
});

const handleEvent = (e: any) => {
  if (e.target.localName == "body") {
    if (e.srcElement.scrollTop >= 120) {
      scrollTop.value = "5px";
    } else {
      scrollTop.value = "120px";
    }
  }
};

window.addEventListener("scroll", handleEvent, true);

onUnmounted(() => {
  getLang();
  window.removeEventListener("scroll", handleEvent, true);
});

// 四大区间起始索引
let availableThresholdStartIndex: number | null = null;
let warningThresholdStartIndex: number | null = null;
let faultThresholdStartIndex: number | null = null;

// 区间分割线像素
let availableThresholdSplitPixel: number;
let warningThresholdSplitPixel: number;
let faultThresholdSplitPixel: number;
let halfPixel: number;

// 四大区间百分比
let healthAreaPercent: number | null = null;
let availableAreaPercent: number | null = null;
let warningAreaPercent: number | null = null;
let faultAreaPercent: number | null = null;

let healthPointsPercent: number = 0;
let availablePointsPercent: number = 0;
let warningPointsPercent: number = 0;
let faultPointsPercent: number = 0;

let xData: number[] = [];
let yData: number[] = [];
let myChart: any = null;
const updateChart = (quotaMap: Map<number, number> | null,
                     threshold1: number = 0, threshold2: number = 0, threshold3: number = 0) => {
  if (!quotaMap || quotaMap.size == 0) {
    myChart.clear();
    return;
  }

  xData = [];
  yData = [];

  myChart.clear();

  // 若沒有传递阈值参数，使用表单里的阈值
  if (!threshold1 && !threshold2 && !threshold3) {
    threshold1 = Number(form.value.level1Threshold);
    threshold2 = Number(form.value.level2Threshold);
    threshold3 = Number(form.value.level3Threshold);
  }

  let hasThreshold: boolean = threshold1 > 0 && threshold2 > 0 && threshold3 > 0;

  if (threshold1 > 0 && (
      threshold1 >= (threshold2 ?? 0) ||
      threshold1 >= (threshold2 ?? 0))) {
    //可用报警阈值必须小于警戒报警阈值和故障报警阈值
    hasThreshold = false;
  }

  if (threshold2 > 0 && (threshold2 >= (threshold3 ?? 0))) {
    //警戒报警阈值必须小于故障报警阈值;
    hasThreshold = false;
  }

  // 四大区间起始索引
  availableThresholdStartIndex = null;
  warningThresholdStartIndex = null;
  faultThresholdStartIndex = null;

  // 四大区间百分比
  healthAreaPercent = null;
  availableAreaPercent = null;
  warningAreaPercent = null;
  faultAreaPercent = null;

  healthPointsPercent = 0;
  availablePointsPercent = 0;
  warningPointsPercent = 0;
  faultPointsPercent = 0;


  // x轴值分段数组
  let xtp0Arr: number[] = [];
  let xtp1Arr: number[] = [];
  let xtp2Arr: number[] = [];
  let xtp3Arr: number[] = [];

  // 四大区间点数
  let ytp0: number = 0;
  let ytp1: number = 0;
  let ytp2: number = 0;
  let ytp3: number = 0;

  for (let [key, value] of quotaMap) {
    xData.push(key);
    yData.push(value);

    // 根据阈值将x轴数据分为四个区间数组
    if (hasThreshold) {
      if (key <= threshold1) {
        xtp0Arr.push(key);
        ytp0 += value;
      } else if (key <= threshold2) {
        xtp1Arr.push(key);
        ytp1 += value;
      } else if (key <= threshold3) {
        xtp2Arr.push(key);
        ytp2 += value;
      } else {
        xtp3Arr.push(key);
        ytp3 += value;
      }
    }
  }


  let xName = "数据数值";
  if (selectedDataType.unit) {
    xName += `(${selectedDataType.unit})`;
  }

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params: any) {
        let result = "";
        if (params[0].componentIndex > 0) {
          params.forEach((item: any) => {
            result += item.marker + item.name + ": " + "<br/>";
          });
        } else {
          params.forEach((item: any) => {
            result += item.marker + item.name + ": " + item.value + "<br/>";
          });
        }

        return result;
      },
    },
    grid: [
      {
        id: "gridBar",
        show: true,
        top: 70,
        //bottom: 101,
        left: 50,
        right: 30,
      },
      {
        id: 'gridAreaLabel',
        show: true,
        height: 50,
        top: 10,
        bottom: 40,
        left: 50,
        right: 30,
        borderWidth: 0,
      }
    ],
    xAxis: [
      {
        // 主柱状图
        type: "category",
        id: "x0",
        name: xName,
        nameLocation: "center",
        nameTextStyle: {
          padding: [15, 0, 0, 0],
        },
        data: xData,
        gridIndex: 0,
        zLevel: 1
      },
      {
        // 阈值分块
        type: 'category',
        id: "xCategory",
        show: false,
        gridIndex: 1,
        axisLine: { show: false },
        splitLine: { show: false },
        axisTick: { show: false },
        tooltip: { show: true }
        //zLevel: 1,
      }
    ],
    yAxis: [
      {
        type: "value",
        name: "数据点数",
        gridIndex: 0,
      },
      {
        type: "value",
        gridIndex: 1,
        axisLabel: { show: false },
        axisLine: { show: false },
        splitLine: { show: false },
        axisTick: { show: false }
      }
    ],
    //series: series
  };

  option && myChart.setOption(option);

  updateChartSeries({
    xtp0Arr, xtp1Arr, xtp2Arr, xtp3Arr,
    ytp0, ytp1, ytp2, ytp3
  } as AreaOptions);

  let a = myChart.convertToPixel({ xAxisIndex: 0, gridIndex: 0, }, 0);
  let b = myChart.convertToPixel({ xAxisIndex: 0, gridIndex: 0, }, 1);
  halfPixel = (b - a) / 2;
  let initPixel = 5;

  availableThresholdSplitPixel = Math.ceil((myChart.convertToPixel({ xAxisId: "x0" }, availableThresholdStartIndex) || initPixel) + halfPixel);
  warningThresholdSplitPixel = Math.ceil((myChart.convertToPixel({ xAxisId: "x0" }, warningThresholdStartIndex) || initPixel + 3) + halfPixel);
  faultThresholdSplitPixel = Math.ceil((myChart.convertToPixel({ xAxisId: "x0" }, faultThresholdStartIndex) || initPixel + 6) + halfPixel);

  /*
  console.log("availableThresholdStartIndex", availableThresholdStartIndex);
  console.log("warningThresholdStartIndex", warningThresholdStartIndex);
  console.log("faultThresholdStartIndex", faultThresholdStartIndex);
  console.log("availableThresholdSplitPixel", availableThresholdSplitPixel);
  console.log("warningThresholdSplitPixel", warningThresholdSplitPixel);
  console.log("faultThresholdSplitPixel", faultThresholdSplitPixel);
   */

  updateSplitLine();
};

interface AreaOptions {
  // x轴值分段数组
  xtp0Arr: number[];
  xtp1Arr: number[];
  xtp2Arr: number[];
  xtp3Arr: number[];

  // 四大区间点数
  ytp0: number;
  ytp1: number;
  ytp2: number;
  ytp3: number;

  threshold1: number;
  threshold2: number;
  threshold3: number;
}

function pushSeries(areaWidthPercent: number, pointsPercent: number, point: number, name: string, series: any[] = []) {
  if (areaWidthPercent > 0) {
    series.push({
      type: "bar",
      xAxisIndex: 1,
      yAxisIndex: 1,
      data: [{ name: name + "\r\n" + `(点数：${point}、占比：${pointsPercent}%)`, value: 1 }],
      itemStyle: { color: "transparent" },
      barGap: 0,
      barWidth: areaWidthPercent + "%",
      label: {
        show: true,
        position: "inside",
        formatter: "{b}\n"
      }
    });
  }
}

function updateChartSeries(options: AreaOptions | null) {
  let xtp0Arr = options?.xtp0Arr || [];
  let xtp1Arr = options?.xtp1Arr || [];
  let xtp2Arr = options?.xtp2Arr || [];
  let xtp3Arr = options?.xtp3Arr || [];

  let ytp0 = options?.ytp0 || 0;
  let ytp1 = options?.ytp1 || 0;
  let ytp2 = options?.ytp2 || 0;
  let ytp3 = options?.ytp3 || 0;

  let threshold1 = 0, threshold2 = 0, threshold3 = 0;
  // 若沒有传递阈值参数，使用表单里的阈值
  if (!threshold1 && !threshold2 && !threshold3) {
    threshold1 = Number(form.value.level1Threshold);
    threshold2 = Number(form.value.level2Threshold);
    threshold3 = Number(form.value.level3Threshold);
  }

  let hasThreshold: boolean = threshold1 > 0 && threshold2 > 0 && threshold3 > 0;

  if (options == null && hasThreshold) {
    for (let [key, value] of currentQuotaMap) {
      // 根据阈值将x轴数据分为四个区间数组
      if (hasThreshold) {
        if (key <= threshold1) {
          xtp0Arr.push(key);
          ytp0 += value;
        } else if (key <= threshold2) {
          xtp1Arr.push(key);
          ytp1 += value;
        } else if (key <= threshold3) {
          xtp2Arr.push(key);
          ytp2 += value;
        } else {
          xtp3Arr.push(key);
          ytp3 += value;
        }
      }
    }
  }

  // 计算四大区间百分比，和起始索引
  let totalPoints = ytp0 + ytp1 + ytp2 + ytp3;
  if (xtp3Arr.length > 0) {
    faultThresholdStartIndex = (xtp0Arr.length + xtp1Arr.length + xtp2Arr.length) - 1
    faultAreaPercent = Number(((xtp3Arr.length / xData.length) * 100).toFixed(1));
    faultPointsPercent = Number(((ytp3 / totalPoints) * 100).toFixed(1));
  }
  if (xtp2Arr.length > 0) {
    warningThresholdStartIndex = (xtp0Arr.length + xtp1Arr.length) - 1
    warningAreaPercent = Number(((xtp2Arr.length / xData.length) * 100).toFixed(1));
    warningPointsPercent = Number(((ytp2 / totalPoints) * 100).toFixed(1));
  }
  if (xtp1Arr.length > 0) {
    availableThresholdStartIndex = xtp0Arr.length - 1
    availableAreaPercent = Number(((xtp1Arr.length / xData.length) * 100).toFixed(1));
    availablePointsPercent = Number(((ytp1 / totalPoints) * 100).toFixed(1));
  }
  if (xtp0Arr.length > 0) {
    healthAreaPercent = Number(((xtp0Arr.length / xData.length) * 100).toFixed(1));
    healthPointsPercent = Number(((ytp0 / totalPoints) * 100).toFixed(1));
  }

  let series = [
    {
      // 主柱状图y值
      data: yData,
      type: "bar",
      itemStyle: {
        color: "#00FFF4",
      },
      label: {
        show: false,
        position: "inside",
      },
    }
  ];
  pushSeries(healthAreaPercent ?? 0, healthPointsPercent, ytp0, '健康区间', series);
  pushSeries(availableAreaPercent ?? 0, availablePointsPercent, ytp1, '可用区间', series);
  pushSeries(warningAreaPercent ?? 0, warningPointsPercent, ytp2, '警戒区间', series);
  pushSeries(faultAreaPercent ?? 0, faultPointsPercent, ytp3, '故障区间', series);

  const option = {
    series: series
  };

  option && myChart.setOption(option);
}

function updateSplitLine() {
  let height = myChart.getHeight();
  let lineShape = {
    x1: 0,
    x2: 0,
    y1: 70,
    y2: height - 70
  };


  let gOptions = {
    graphic: [
      {
        id: "available",
        type: 'line',
        position: [availableThresholdSplitPixel, 0],
        z: 100,
        shape: lineShape,
        style: {
          stroke: "yellow",
          lineWidth: 2,
          lineDash: "dashed"
        },
        cursor: 'move',
        draggable: "horizontal",
        ondragend: (arg: any) => onSplitLineDragend(arg, 1)
      },
      {
        id: "warning",
        type: 'line',
        position: [warningThresholdSplitPixel, 0],
        z: 100,
        shape: lineShape,
        style: {
          stroke: "orange",
          lineWidth: 2,
          lineDash: "dashed"
        },
        cursor: 'move',
        draggable: "horizontal",
        ondragend: (arg: any) => onSplitLineDragend(arg, 2)
      },
      {
        id: "fault",
        type: 'line',
        position: [faultThresholdSplitPixel, 0],
        z: 100,
        shape: lineShape,
        style: {
          stroke: "red",
          lineWidth: 2,
          lineDash: "dashed"
        },
        cursor: "move",
        draggable: "horizontal",
        ondragend: (arg: any) => onSplitLineDragend(arg, 3)
      },
    ]
  };
  myChart.setOption(gOptions);
}

function onSplitLineDragend(arg: any, level: number) {
  if (level !== 1 && level !== 2 && level !== 3) return;
  // 当前x轴位置
  // console.log("target xPixel", arg.offsetX)
  let xIndex = myChart.convertFromPixel({ xAxisId: "x0" }, arg.offsetX)
  //console.log("xIndex", xIndex);
  // 落点index对应的x值为新阈值
  let xMovedPixel = myChart.convertToPixel({ xAxisId: "x0" }, xIndex);

  if (level === 1) {
    if (xIndex >= warningThresholdStartIndex || xIndex >= faultThresholdStartIndex) {
      message.warn("可用阈值必须小于警戒阈值和故障阈值")
      updateSplitLine();
      return;
    }
    availableThresholdStartIndex = xIndex;
    availableThresholdSplitPixel = xMovedPixel + halfPixel;
  } else if (level === 2) {
    if (xIndex >= faultThresholdStartIndex || xIndex <= availableThresholdStartIndex) {
      message.warn("警戒阈值必须大于可用阈值，且小于故障阈值")
      updateSplitLine();
      return;
    }
    warningThresholdStartIndex = xIndex;
    warningThresholdSplitPixel = xMovedPixel + halfPixel;
  } else {
    if (xIndex <= availableThresholdStartIndex || xIndex <= warningThresholdStartIndex) {
      message.warn("故障阈值必须大于警戒阈值和可用阈值")
      updateSplitLine();
      return;
    }
    faultThresholdStartIndex = xIndex;
    faultThresholdSplitPixel = xMovedPixel + halfPixel;
  }

  form.value[`level${level}Threshold`] = xData[xIndex];
  //console.log("fixed xPixel", xPixel)
  updateChartSeries(null);
  updateSplitLine();
}

let changeSimpleDateType = (type: string) => {
  simpleSearchModel.value.type = type;
};

let currentQuotaMap: Map<number, number> = new Map<number, number>();
let quotaSearch = async () => {
  let beginDate: any = "";
  let endDate: any = "";

  if (simpleSearchModel.value.type == "0") {
    if (
        simpleSearchModel.value.date &&
        simpleSearchModel.value.date.length === 2
    ) {
      let start = simpleSearchModel.value.date[0];
      let end = simpleSearchModel.value.date[1];
      beginDate = start.$d;
      endDate = end.$d;
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    let date = new Date().getTime();
    if (simpleSearchModel.value.type == "1") {
      beginDate = date - 1000 * 60 * 60 * 24 * 7;
    }
    if (simpleSearchModel.value.type == "2") {
      beginDate = date - 1000 * 60 * 60 * 24 * 30;
    }
    if (simpleSearchModel.value.type == "3") {
      beginDate = date - 1000 * 60 * 60 * 24 * 60;
    }
    endDate = date;
  }
  let begin = transformDate2(beginDate, 1);
  let end = transformDate2(endDate, 2);

  form.value = new ThresholdItem()

  let result = await request.post("/api/sensors-hardware/data", {
    begin,
    end,
    sensorId: sensorInfo.value.id,
    codes: [rdoSimpleDataType.value],
  });
  currentQuotaMap.clear();

  if (result && result.data) {
    function compareNumbers(a: { value: any }, b: { value: any }) {
      return a.value - b.value;
    }

    // 统计每个 value 值的个数
    result.data.list.sort(compareNumbers).forEach((obj: any) => {
      let value = parseFloat(parseFloat(obj.value).toFixed(2));
      let currentCount = currentQuotaMap.get(value) || 0;
      currentQuotaMap.set(value, currentCount + 1);
    });

    updateChart(currentQuotaMap);
  } else {
    updateChart(null);
  }

  let data = await doGenerateThreshold();
  if (data && data.length >= 3) {
    SetGeneratedSmartThreshold(data as any[]);
  }
};

let onClickSelectThresholds = (threshold: ThresholdItem) => {
  Object.assign(form.value, threshold);
  updateChart(currentQuotaMap)
}

let isApplyingThreshold = ref(false);
let onClickApplyThreshold = async () => {
  let body = {
    level1Threshold: Number(form.value.level1Threshold),
    level1RangeCount: Number(form.value.level1RangeCount),
    level1LimitCount: Number(form.value.level1LimitCount),
    level2Threshold: Number(form.value.level2Threshold),
    level2RangeCount: Number(form.value.level2RangeCount),
    level2LimitCount: Number(form.value.level2LimitCount),
    level3Threshold: Number(form.value.level3Threshold),
    level3RangeCount: Number(form.value.level3RangeCount),
    level3LimitCount: Number(form.value.level3LimitCount),
    level4Threshold: Number(form.value.level4Threshold),
    machineType: sensorInfo.value.machineTypeId,
    dataType: rdoSimpleDataType.value,
    sensorIds: [sensorInfo.value.id],
  };

  let dataType = dataTypes.value.find((p: any) => p.code == rdoSimpleDataType.value);
  if (!dataType) {
    message.warn("请选择数据类型")
    return;
  }

  // 检查阈值范围是否合法
  if (!body.level1RangeCount || !body.level1LimitCount ||
      !body.level2RangeCount || !body.level2LimitCount ||
      !body.level3RangeCount || !body.level3LimitCount) {
    message.warn("笔数必须大于0");
    return;
  }
  if (!body.level1Threshold || !body.level2Threshold || !body.level3Threshold || !body.level4Threshold) {
    message.warn("阈值必须大于0");
    return;
  }

  if (body.level1Threshold > 0 && (
      body.level1Threshold >= (body.level2Threshold ?? 0) ||
      body.level1Threshold >= (body.level3Threshold ?? 0))) {
    message.warn("可用报警阈值必须小于警戒报警阈值和故障报警阈值");
    return;
  }

  if (body.level2Threshold > 0 && (body.level2Threshold >= (body.level3Threshold ?? 0))) {
    message.warn("警戒报警阈值必须小于故障报警阈值");
    return;
  }

  if (body.level3Threshold > 0 && (body.level3Threshold) >= (body.level4Threshold ?? 0)) {
    message.warn("故障报警阈值必须小于熔断报警阈值");
    return;
  }

  isApplyingThreshold.value = true;
  let result;
  try {
    result = await request.post("/api/quota/simple-model", body);
  } catch (err) {
  }
  if (result?.status == 200) {
    message.success("保存模板成功!");
    //await loadSensorSimpleModel();
  }

  let config = Object.assign({
    dataType: dataType.name,
    machineId: sensorInfo.value.machineId,
    sensorId: sensorInfo.value.id,
    sensorPlace: sensorInfo.value.sensorPlace
  }, form.value);
  try {
    await request.put("/api/sensors/quota-alarm", { list: [config] })
  } catch (err) {
  } finally {
    isApplyingThreshold.value = false;
  }

  if (result?.status == 200) {
    message.success("保存报警规则成功!");
    await selectQuotaAlarmConfig(dataType)
  }
};

function setThresholdPrompt(promptArray: ({
  param: number,
  count: number,
  sum: number
} | string)[], maxThreshold: number = 0) {
  if (promptArray && promptArray.length > 0) {
    form.value.level1Prompt = promptArray.filter((val: any) => parseFloat(val.param.toFixed(5)) === form.value.level1Threshold)
        .map((val: any) => `累计${val.count}笔出现${val.sum}次`)
        .join('；');
    form.value.level2Prompt = promptArray.filter((val: any) => parseFloat(val.param.toFixed(5)) === form.value.level2Threshold)
        .map((val: any) => `累计${val.count}笔出现${val.sum}次`)
        .join('；');
    form.value.level3Prompt = promptArray.filter((val: any) => parseFloat(val.param.toFixed(5)) === form.value.level3Threshold)
        .map((val: any) => `累计${val.count}笔出现${val.sum}次`)
        .join('；');
    let geLevel4Threshold = parseFloat(parseFloat((promptArray[4] as string)).toFixed(5));
    form.value.level4Prompt = `历史统计：最高值${geLevel4Threshold}`;
  } else {
    form.value.level1Prompt = "";
    form.value.level2Prompt = "";
    form.value.level3Prompt = "";
  }

  if (maxThreshold) {
    let geLevel4Threshold = parseFloat(parseFloat(maxThreshold.toString()).toFixed(5));
    form.value.level4Prompt = `历史统计：最高值${geLevel4Threshold}`;
  } else {
    form.value.level4Prompt = ""
  }
}

function SetGeneratedSmartThreshold(thresholdArr: any[]) {
  if (thresholdArr && thresholdArr.length > 0) {
    let geLevel1Threshold = parseFloat(parseFloat(thresholdArr[0]).toFixed(5));
    let geLevel2Threshold = parseFloat(parseFloat(thresholdArr[1]).toFixed(5));
    let geLevel3Threshold = parseFloat(parseFloat(thresholdArr[2]).toFixed(5));
    let geLevel4Threshold = parseFloat(parseFloat(thresholdArr[4]).toFixed(5));

    /*
    form.value.level1Threshold = geLevel1Threshold;
    form.value.level2Threshold = geLevel2Threshold;
    form.value.level3Threshold = geLevel3Threshold;
    form.value.level4Threshold = geLevel4Threshold > geLevel3Threshold ? geLevel4Threshold : geLevel3Threshold;
     */

    smartThreshold.value.level1Threshold = geLevel1Threshold
    smartThreshold.value.level2Threshold = geLevel2Threshold
    smartThreshold.value.level3Threshold = geLevel3Threshold
    smartThreshold.value.level4Threshold = geLevel4Threshold > geLevel3Threshold ? geLevel4Threshold : geLevel3Threshold;

    smartThreshold.value.level1RangeCount = 10;
    smartThreshold.value.level1LimitCount = 1;
    smartThreshold.value.level2RangeCount = 10;
    smartThreshold.value.level2LimitCount = 1;
    smartThreshold.value.level3RangeCount = 10;
    smartThreshold.value.level3LimitCount = 1;

    //form.value.level4Prompt = `历史统计：最高值${geLevel4Threshold}`;
  }
}

let doGenerateThreshold = async (thresholds: number[] | null = null): Promise<any> => {
  let beginDate: any = "";
  let endDate: any = "";

  if (simpleSearchModel.value.type == "0") {
    if (
        simpleSearchModel.value.date &&
        simpleSearchModel.value.date.length === 2
    ) {
      let start = simpleSearchModel.value.date[0];
      let end = simpleSearchModel.value.date[1];
      beginDate = start.$d;
      endDate = end.$d;
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    let date = new Date().getTime();
    if (simpleSearchModel.value.type == "1") {
      beginDate = date - 1000 * 60 * 60 * 24 * 7;
    }
    if (simpleSearchModel.value.type == "2") {
      beginDate = date - 1000 * 60 * 60 * 24 * 30;
    }
    if (simpleSearchModel.value.type == "3") {
      beginDate = date - 1000 * 60 * 60 * 24 * 60;
    }
    endDate = date;
  }
  let begin = transformDate2(beginDate, 1);
  let end = transformDate2(endDate, 2);

  let winSize: number[];
  let params: number[] = [];
  if (thresholds !== null) {
    params = thresholds;
  } else if (form.value.level1Threshold && form.value.level2Threshold && form.value.level3Threshold) {
    params = [form.value.level1Threshold, form.value.level2Threshold, form.value.level3Threshold];
  }

  form.value.level1RangeCount = form.value.level1RangeCount || 10;
  form.value.level2RangeCount = form.value.level2RangeCount || 10;
  form.value.level3RangeCount = form.value.level3RangeCount || 10;

  //winSize = [form.value.level1RangeCount, form.value.level2RangeCount, form.value.level3RangeCount];
  winSize = [10, 10, 10]

  let result: any;
  try {
    result = await request.post("/model/alarm/norm", {
      values: [{ begin, end }],
      dataTypes: [
        {
          sensor: sensorInfo.value.id,
          code: [rdoSimpleDataType.value],
        },
      ],
      winSize: winSize,
      params: params
    });
  } catch (err) {
    throw err;
  } finally {
    isLoadingPrompt.value = false;
  }

  return result?.data;
}

let selectedDataType: { code: number, value: number, name: string, unit: string };
let selectDataType = async () => {
  if (rdoSimpleDataType.value > 0) {
    let type: any = dataTypes.value.find(
        (p: any) => p.code == rdoSimpleDataType.value
    );
    form.value = new ThresholdItem();
    if (type) {
      selectedDataType = type;
      await selectQuotaAlarmConfig(type);
      await quotaSearch();
    } else {
      updateChart(null);
    }
  }
};

const getQuotaAlarmConfig = async () => {
  if (!sensorInfo.value) return;
  let result = await request.get("/api/sensors/quota-alarm/info?sensorId=" + sensorInfo.value.id);
  if (result?.data) {
    sensorInfo.value.table = result.data;
  } else {
    sensorInfo.value.table = [];
  }
};

const selectQuotaAlarmConfig = async (dataType: any) => {
  if (!sensorInfo.value.table) {
    standardThreshold.value = new ThresholdItem();
    currentThreshold.value = new ThresholdItem();
    return;
  }

  let configItem = sensorInfo.value.table.find((p: any) => p.dataType == dataType.name);
  if (!configItem) {
    currentThreshold.value = new ThresholdItem();
    standardThreshold.value = new ThresholdItem();
    return;
  }

  Object.assign(currentThreshold.value, configItem)

  if (configItem?.initial) {
    Object.assign(standardThreshold.value, configItem.initial)
  } else {
    standardThreshold.value = new ThresholdItem();
  }
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

const selectMenu = (type: any) => {
  menuSelect.value = type;
  if (type == 3) {
    getQuotaAlarmConfig().catch(_ => {});
  }
};

let loadAlarmConfig = async () => {
  let result = await request.get("/api/sensors/quota-alarm/info?sensorId=" + sensorInfo.value.id);
  if (result?.data) {
    let configItem = result.data.find((p: any) => p.dataType == selectedDataType.name);
    Object.assign(form.value, configItem)
  }
};

let dataTypes: any = ref([]);

let isLoadingDataTypes: boolean = false;
let loadDataTypes = async () => {
  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  while (isLoadingDataTypes) {
    await sleep(10);
  }
  if (dataTypes.value.length > 0) {
    return;
  }
  let result = await request.post("/api/data-type", { tags: [10] });
  isLoadingDataTypes = false;
  if (result.data) {
    dataTypes.value = result.data;
  }
};

</script>

<style lang="less" scoped>
.sjz {
  margin-left: 4rem;
  margin-right: 4rem;
  width: calc(100% - 8rem);
  height: 20px;
  background: #999;
  padding: 0;
}

/deep/ .ant-input-affix-wrapper {
  background: #021768;
  border: 1px solid #0d53b7cc;
  color: #fff;
}

/deep/ .ant-input {
  background: #021768;
  color: #fff;
}

/deep/
.ant-select-single.ant-select-sm:not(.ant-select-customize-input),
.ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

/deep/ .ant-select-arrow {
  color: #fff;
}

/deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

/deep/ .ant-switch {
  background-color: #07259a;
}

/deep/ .ant-switch-checked {
  background-color: #07259a !important;
}

/deep/ .ant-picker {
  background-color: #021768;
  border: 1px solid #0d53b7;
}

/deep/ .ant-picker-suffix {
  color: #fff;
  opacity: 0.5;
}

/deep/ .ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #0d53b7cc !important;
}

/deep/ .ant-table-thead {
  border: #072499 !important
}

/deep/ .ant-table-tbody > tr > td {
  border-right: 0 solid #fff !important;
}

/deep/ table {
  border-top: 0 solid #0d53b7cc !important
}

/deep/ .ant-table-thead .ant-table-cell {
  background: #03155C !important;
  border-right: 0 !important;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}

.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 20px 10px;
  width: 100%;
  height: calc(100vh - 310px);
}

.machine-select {
  border-radius: 2px;
  background: #072499;
}

.content {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 20px 10px;
}

.btn1 {
  border: 1px solid #0d53b7;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
}

.btn3 {
  border: 1px solid #0d53b7;
  background-color: #072498;
  padding: 0.25rem 0.75rem;
  cursor: pointer;
}

.btn1-select {
  background: #072499;
  padding: 5px 10px;
  cursor: pointer;
}

.btn2 {
  border: 1px solid #0d53b7;
  cursor: pointer;
}

.opt6 {
  opacity: 0.6;
}

.opt8 {
  opacity: 0.8;
}

.size20 {
  font-size: 20px;
}

.size18 {
  font-size: 18px;
}

.size12 {
  font-size: 12px;
}

.w30 {
  width: 31px;
}

.w40 {
  width: 40px;
}

.w50 {
  width: 50px;
}

.w80 {
  width: 80px;
}

.imgShow {
  max-width: 15px;
  width: 15px;
  height: 15px;
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-table-tbody > tr > td {
  border-bottom: 0 solid #fff;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background: #021766;
}

/deep/ .ant-select-multiple .ant-select-selection-item {
  background: #07249933 !important;
}
</style>
