<template>
    <div>
        <div class="w-full flex items-center justify-center">
            <div class="btn1" :style="{'background': menuSelect === 1 ? '#072499' : ''}" @click="selelctMenu(1)">{{langObj['列表显示']}}</div>
            <div class="btn1" :style="{'background': menuSelect === 2 ? '#072499' : ''}" @click="selelctMenu(2)">{{langObj['台账显示']}}</div>
        </div>

        <div v-if="menuSelect === 1" class="mt-3">
            <a-button type="primary" class="plft" @click="add()"><plus-outlined />{{langObj['新增']}}</a-button>
            <div class="box mt-4" style="width: 100%;">
                <a-table bordered :columns="columns" :dataSource="dataList" :pagination="false" size="small">
                    <template #bodyCell="{ column, text, record }">
                        <template v-if="column.dataIndex === 'groups'">
                            <span>
                                <a-tag v-for="tag in text" :key="tag" :color="'volcano'">
                                {{ tag.name }}
                                </a-tag>
                            </span>
                        </template>
                        <template v-if="column.dataIndex === 'act'" >
                            <a-button type="primary" class="plft" @click="edit(record)"><edit-outlined />{{langObj['编辑']}}</a-button>
                        </template>
                    </template>
                </a-table>
            </div>
        </div>

        <div v-if="menuSelect === 2" class="flex w-full p-3 mt-3">
            <div
                class="w-64 p-2 overflow-y-auto scroll-box h-screen code-box"
                style="height: 75vh"
            >
                <a-input-search
                v-model:value="filterKeyWord"
                style="margin-bottom: 8px"
                :placeholder="langObj['搜索']"
                />
                <div @click="add()" class="w-full text-center py-1 cursor-pointer" style="background-color: #072499;">{{langObj['新建工厂']}}</div>

                <div class="w-full mt-2">
                    <div 
                        class="w-full flex items-center cursor-pointer facbox" 
                        v-for="(item, index) in dataList" :key="index"
                        @click="selectFactory(item._id)"
                        :style="{'background': item._id == factory_id ? '#3137AF' : ''}"
                    >
                        <img style="width: 16px;" src="../../assets/facIcon1.png" alt="">
                        <div class="ml-2">{{ item.factoryName }}</div>
                    </div>
                </div>
        
        
            </div>

            <div class="flex-1 ml-3">
                <div class="w-full code-box flex">
                    <div class="flex-1 ml-4">
                        <a-descriptions
                            layout="vertical"
                            :column="4"
                            :contentStyle="{ color: '#fff' }"
                            :labelStyle="{ color: '#7E8BA9' }"
                        >
                            <a-descriptions-item :label="langObj['工厂名称']">{{
                            factory_info.factoryName
                            }}</a-descriptions-item>
                            <a-descriptions-item :label="langObj['集团名称']">{{
                            factory_info.parentName
                            }}</a-descriptions-item>
                            <a-descriptions-item :label="langObj['区域']">
                                <!-- #00D2D6 -->
                                <div 
                                    v-for="(item, index) in factory_info.groups" :key="index"
                                    style="border: 1px solid #00D2D6; padding: 1px 3px;"
                                    :class="{'ml-2': index != 0}"
                                >
                                    {{ item.name }}
                                </div>
                            </a-descriptions-item>
                            
                        </a-descriptions>
                    </div>

                    <div
                        @click="edit(factory_info)"
                        class="mt-4 px-5 py-1 fff cursor-pointer"
                        style="
                            border: 1px solid #0d53b7cc;
                            background: #072499;
                            height: 33px;
                            color: #fff;
                            display: inline-block;
                        "
                    >
                    {{langObj['编辑工厂']}}
                    </div>


                </div>

                <div class="code-box mt-3" style="padding: 0">
                    <div class="w-full" style="border-bottom: 1px solid #495985">
                        <div class="flex items-center px-5 flex-wrap" style="font-size: 18px">
                            <div
                            class="py-3 cursor-pointer"
                            :class="{ 'txt-select': tagSelect === '1' }"
                            @click="selectTag('1')"
                            >
                            {{langObj['工厂图']}}
                            </div>
                            <div
                            v-for="(item, index) in taglist" :key="index"
                            class="py-3 cursor-pointer ml-24"
                            :class="{ 'txt-select': tagSelect === item.value }"
                            @click="selectTag(item.value)"
                            >
                            {{ item.label }}
                            </div>
                        </div>
                    </div>


                    <div class="w-full mt-3 px-5" >
                        <div v-if="tagSelect === '1'" class="flex"> 
                            <div style="width: 10%;"></div>
                            <div style="width: 80%;display: flex; justify-content: center; position: relative;">
                                <div 
                                    v-for="(item, index) in localeList" :key="index"
                                    style="position: absolute; " 
                                    :style="{'left': item[0] + 'px', 'top': item[1]+'px'}"
                                >
                                    <img style="width: 50px;" src="@/assets/facMark.png" alt="">
                                </div>
                                <img id="facImg" style="width: 100%" :src="facPic" alt="">
                            </div>
                            <div style="width: 10%;" class="flex">
                                <div class="flex-1"></div>
                                <div
                                    @click="editLocation()"
                                    class="mt-4 px-5 py-1 fff cursor-pointer"
                                    style="
                                        border: 1px solid #0d53b7cc;
                                        background: #072499;
                                        height: 33px;
                                        color: #fff;
                                        display: inline-block;
                                    "
                                >
                                {{langObj['编辑']}}
                                </div>
                            </div>
                        </div>



                        <div v-if="tagSelect !== '1'" class="flex">
                            <div style="width: 10%;"></div>
                            <div style="width: 80%;display: flex; justify-content: center; position: relative;">
                               
                                <div
                                id="yulan"
                                v-if="config_3d && config_3d.img"
                                style="
                                    margin: auto;
                                    padding: 20px;
                                    width: 1000px;
                                    height: 800px;
                                    position: relative;
                                "
                                >
                                <img
                                    :src="config_3d.picture"
                                    style="position: absolute"
                                    :style="{
                                    width: config_3d.img.width + 'px',
                                    height: config_3d.img.height + 'px',
                                    top: config_3d.img.y + 'px',
                                    left: config_3d.img.x + 'px',
                                    transform: 'rotate(' + config_3d.img.rotate + 'deg)',
                                    }"
                                />
                                <!-- 加载标点 -->
                                <div v-for="(item, index) in config_3d.marks" :key="index">
                                    <div
                                    class="d3_point"
                                    v-if="item.bind.indexOf('a-') > -1"
                                    :style="{ top: item.y + 'px', left: item.x + 'px' }"
                                    ></div>
                                </div>
                                <!-- 加载标签 -->
                                <div v-for="(item, index) in config_3d.marks" :key="index">
                                    <div
                                    class="d3_bq"
                                    v-if="item.bind.indexOf('b-') > -1"
                                    :style="{
                                        top: item.y - 50 + 'px',
                                        left: item.x - 100 + 'px',
                                        'border-color': getColor(0),
                                    }"
                                    style="width: 220px; height: auto"
                                    >
                                    <div class="w-full" style="padding: 5px 10px">
                                        <div class="w-full flex">
                                            <div style="font-size: 18px;" >{{ groupInfo?.name }}</div>
                                        </div>

                                        <div 
                                        v-for="(item, index) in machineList" :key="index" 
                                        class="w-full flex items-center"
                                        >
                                            <div >{{ item?.machineName}}</div>
                                            <div class="flex-1"></div>
                                            <div style="width: 16px;height: 16px;border-radius: 50%;min-width: 16px;" :style="{'background': getColor2(item.condition)}"></div>
                                        </div>
                                        
                                    </div>
                                    </div>
                                </div>
                                <!-- 划线 -->
                                <div v-for="(item, index) in line" :key="index">
                                    <div
                                    class="d3_line"
                                    :style="{
                                        top: item.y + 5 + 'px',
                                        left: item.x + 5 + 'px',
                                        width: item.distance + 'px',
                                        transform: 'rotate(' + item.angle + 'deg)',
                                    }"
                                    ></div>
                                </div>
                                </div>









                            </div>
                            <div style="width: 10%;" class="flex">
                                <div class="flex-1"></div>
                                <div
                                    @click="editGroup3D()"
                                    class="mt-4 px-5 py-1 fff cursor-pointer"
                                    style="
                                        border: 1px solid #0d53b7cc;
                                        background: #072499;
                                        height: 33px;
                                        color: #fff;
                                        display: inline-block;
                                    "
                                >
                                {{langObj['编辑']}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <a-modal :okText="langObj['确认']" :cancelText="langObj['取消']" :width="850" :maskClosable="false" v-model:visible="visible" :title="form.id? langObj['编辑'] : langObj['新增']"
        @ok="submit">
            <a-form :model="form" ref="formStateRef" name="basic" autocomplete="off"
            :label-col="{ style: { width: '150px' } }">
                <a-form-item :label="langObj['集团']" name="parentId" >
                    <a-select ref="select" v-model:value="form.parentId" :placeholder="langObj['请选择集团']" >
                        <a-select-option v-for="(item, index) in dataList" :key="index" :value="item._id">{{ item.factoryName}}</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item :label="langObj['工厂名称']" name="factoryName" required>
                <a-input v-model:value="form.factoryName" :placeholder="langObj['请输入工厂名称']" />
                </a-form-item>

                <a-form-item :label="langObj['区域']" name="groups" >
                    <div class="w-full mb-2">
                        <div v-for="(group, index) in form.groups" :key="index" class="flex items-center mt-2">
                            <a-input v-model:value="group.name" :placeholder="langObj['请输入车间名称']" />
                            <div class="mx-4" style="color: #fff;">-</div>
                            <a-input v-model:value="group.sonID" placeholder="" readonly />
                            <div class="cursor-pointer ml-4" @click="deleteGroup(index)">
                                <close-square-outlined style="color: #fff; font-size: 22px;" />
                            </div>
                        </div>  
                    </div>
                    
                    <a-button type="primary"  @click="addGroup()"><plus-outlined />{{langObj['新增']}}</a-button>
                    
                </a-form-item>


                <!-- <a-form-item label="工厂照片">
                <a-upload
                    v-model:file-list="facFileList"
                    list-type="picture-card"
                    :custom-request="customRequest"
                    :auto-upload="false"
                    accept="image"
                    :max-count="1"
                >
                    <div v-if="!facFileList || facFileList.length < 1">
                    <plus-outlined />
                    <div style="margin-top: 8px; color: #fff">上传</div>
                    </div>
                </a-upload>
                <div @click="editLocation" class="cursor-pointer" style="color: #fff">编辑地点</div>
                </a-form-item> -->


                <!-- <a-form-item label="定位" name="position">
                    <div class="w-full flex items-center">
                        <a-input-number id="inputNumber" v-model:value="form.position[0]" />
                        <div class="mx-4" style="color: #fff;">,</div>
                        <a-input-number id="inputNumber" v-model:value="form.position[1]" />
                    </div>
                
                </a-form-item> -->



            </a-form>
        </a-modal>


        <a-modal :okText="langObj['确认']" :cancelText="langObj['取消']" :width="1500" :maskClosable="false" v-model:visible="ELvisible" :title="langObj['工厂图']" @ok="subEL">
            <div class="flex">
                <span class="gh3d">
                    <a-upload
                        v-model:file-list="facFileList"
                        :custom-request="customRequest2"
                        :auto-upload="false"
                        accept="image"
                        :max-count="1"
                    >
                        <button id="repart" class="lipButton">
                        <div ><SwapOutlined style="font-size: 22px" /></div>
                        {{langObj['更换工厂图']}}
                        </button>
                    </a-upload>
                </span>

                <div class="ml-4" style="color: #fff;">(*{{langObj['左键添加标记']}})</div>
            </div>
            
            <div class="w-full flex">
                <div style="position: relative; width: 1000px;">
                    <div 
                        v-for="(item, index) in positionELList" :key="index"
                        style="position: absolute; " 
                        :style="{'left': item.ELX + 'px', 'top': item.ELY+'px'}"
                    >
                        <img style="width: 50px;" src="@/assets/facMark.png" alt="">
                    </div>
                    <img v-if="facPic2" id="myDiv" @click="clickEL" style="width: 100%" :src="facPic2" alt="">
                    <img v-if="!facPic2" id="myDiv" @click="clickEL" style="width: 100%" src="@/assets/map.png" alt="">
                </div>

                <div class="flex-1 ml-20" style="color: #fff;">
                    <div class="flex items-center mt-2" v-for="(item, index) in positionELList" :key="index">
                        <div>{{ index + 1 }}</div>
                        <div class="ml-2">{{langObj['坐标']}}:</div>
                        <div class="ml-2">({{ item.x }}, {{ item.y }})</div>
                        <delete-outlined @click="deleteEL(index)" class="ml-4 cursor-pointer"/>
                    </div>
                    
                </div>
            </div>
            
        </a-modal>

        <!-- 区域3D图编辑 -->
        <a-modal
        :okText="langObj['确认']" :cancelText="langObj['取消']"
        v-model:visible="groupVisible"
        :closable="false"
        :footer="null"
        :bodyStyle="{ background: '#020F46' }"
        style="top: 18%"
        width="1400px"
        :maskClosable="false"
        >
        <div style="color: #fff">
            <div class="w-full flex">
            <div class="flex-1"></div>
            <div class="cursor-pointer" @click="groupVisible = false">
                <close-outlined style="font-size: 20px" />
            </div>
            </div>
            <div class="text-center w-full" style="font-size: 24px">{{ groupInfo.name }}</div>
            <div class="tips">{{langObj['左键按住可拖动']}}，{{langObj['右键添加标记']}}</div>
            <div class="main">
            <div
                id="container"
                v-if="groupVisible && groupVisible"
                style="
                border: 1px solid #aaa;
                padding: 20px;
                background-color: rgb(2, 15, 70);
                width: 1000px;
                height: 800px;
                "
            >
                <img :src="config_3d.picture" />
                <span class="gh3d">
                <a-upload
                    @preview="handlePreview"
                    :custom-request="customRequest3D"
                    :auto-upload="false"
                    accept="image"
                    :max-count="1"
                >
                    <button id="repart" class="lipButton">
                    <div><SwapOutlined style="font-size: 22px" /></div>
                    {{langObj['更换3D图']}}
                    </button>
                </a-upload>
                </span>
            </div>
            <div class="m_r">
                <div class="list">
                <div class="item" v-for="(item, index) in markList" :key="index">
                    <div class="lleft">{{ item.id }}</div>
                    <div class="lright">
                    <a-select
                        v-model:value="item.bind"
                        style="width: 200px"
                        :options="machineOptions"
                    ></a-select>
                    </div>
                    <div
                    class="licon"
                    @click="deleteTag(item, index)"
                    v-if="index == markList.length - 1"
                    >
                    <DeleteOutlined />
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="w-full flex justify-center mt-5">
            <div @click="groupVisible = false" class="btn-default py-1 px-6">
                {{langObj['关闭']}}
            </div>
            <!-- <div @click="view3D" class="btn-default py-1 px-6 mrs">预览</div> -->
            <div @click="save3D" class="btn-default py-1 px-6 mrs">{{langObj['保存'] }}</div>
            </div>
        </div>
        </a-modal>


    </div>


    
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate, transformDate2, getGroupListByFactory, getRealFactoryIdList } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { langList } from "../../common/lang";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()
const columns:any = [
    {
        title: langObj.value["上级工厂"],
        dataIndex: "parentName",
        align: "center"
    },
    {
        title: langObj.value["工厂名称"],
        dataIndex: "factoryName",
        align: "center"
    },
    {
        title: langObj.value["区域"],
        dataIndex: "groups",
        align: "center"
    },
    {
        title: langObj.value["操作"],
        dataIndex: "act",
        align: "center"
    },
]

let dataList:any = ref([])
let visible = ref(false)
let form:any = ref({
    groups: [],
    parentId: null,
    factoryName: '',
    position: [null, null],
})

let menuSelect = ref(1)

onMounted(() => {
    init()
})

const init = () => {
    getListData()
}

const selelctMenu = (ev:any) => {
    menuSelect.value = ev
    init()
}

const getListData = async() => {
    let config: any = {
        params: {

        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/factories', config)

    if(result && result.data) {
        dataList.value = result.data
        dataList.value.map((d:any) => {
            if(d.parentId) {
                d.parentName = dataList.value.find((p:any) => p._id === d.parentId)?.factoryName
            }
        })
    }
}

// 新增
const add = () => {
    form.value = {
        groups: [],
        parentId: null,
        factoryName: '',
        position: [],
    }
    facFileList.value = []
    visible.value = true
}

// 编辑
const edit = async(ev:any) => {
    let data = JSON.parse(JSON.stringify(ev))
    facFileList.value = []

    let config: any = {
        params: {
            factoryId: data._id
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let reslut = await request.get('/api/factories/info', config)
 
    if(reslut && reslut.data && reslut.data.picture) {
        reslut.data.picture.type = ''
        facFileList.value = [reslut.data.picture]
    } 
    
    if(!data.position) {
        data.position = []
    } 
    form.value = data
    visible.value = true
}

const addGroup = () => {
    form.value.groups.push({
        name: '',
        sonID: new Date().getTime().toString()
    })
}

const deleteGroup = (index:any) => {
    form.value.groups.splice(index, 1)
}

const submit = async() => {
    let submit = JSON.parse(JSON.stringify(form.value))
    if(!submit.position || submit.position?.length !== 2) {
        submit.position = null
    } 

    for(let group of submit.groups) {
        if(!group.name) {
            message.warning(langObj.value['请填写区域名称'])
            return
        }
    }

    if(submit._id && submit.parentId && submit._id === submit.parentId) {
        message.warning(langObj.value['集团不能为自身工厂'])
        return
    }

    if(!submit.factoryName) {
        message.warning(langObj.value['请填写工厂名称'])
        return
    }

    if(facFileList.value?.length > 0) {
        submit.picture = facFileList.value[0].url
    }

    if(!submit._id) {
        submit.platform = 2
    }

    let result = await request.post('/api/factories', submit)
    if(result && result.status == 200) {
        message.success(langObj.value['操作成功']+'!')
        visible.value = false
        getListData()
    } else {
        message.warning(langObj.value['操作失败']+'!')
    }
}





// 台账显示
const filterKeyWord = ref('')
let factory_id = ref('')
let factory_info:any = ref({})
let tagSelect:any = ref('1')
let taglist:any = ref([])
let localeList:any = ref([])    //坐标列表

const selectFactory = async(ev:any) => {
    localeList.value = []
    facPic.value = ''
    taglist.value = []
    factory_id.value = ev
    let config: any = {
        params: {
            factoryId: ev
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let reslut = await request.get('/api/factories/info', config)
    if(reslut?.data) {
        factory_info.value = reslut.data
        factory_info.value._id = factory_info.value.id
        delete factory_info.value.id
        factory_info.value.parentName = dataList.value.find((p:any) => p._id === factory_info.value.parentId)?.factoryName
        if(factory_info.value?.groups?.length > 0) {
            taglist.value =  taglist.value.concat(factory_info.value.groups.map((d:any) => ({label: d.name, value: d.sonID})))
        }
        if(reslut.data.picture) {
            reslut.data.picture.type = ''
            facFileList.value = [reslut.data.picture]
        }
    }
    tagSelect.value = '1'
    getFactory3D()
}

// 选择工厂区域
const selectTag = (ev:any) => {
    tagSelect.value = ev
    if(ev !== '1') {
        selectGroup()
    }
}

// 工厂图片
let facFileList:any = ref([])
let facPic:any = ref('')

// 处理工厂3d图
const getFactory3D = () => {
    if(factory_info.value?.picture) {
        facPic.value = factory_info.value.picture?.url

        if(factory_info.value?.position?.length > 0) {
            let list:any = []
            setTimeout(() => {
                factory_info.value.position.map((d:any) => {
                    let left = 0
                    let right = 0

                    let div:any = document.getElementById('facImg');
                    let width = div.offsetWidth || 0;
                    let height = div.offsetHeight || 0;
                    left = width * (d[0] / 100) - 25
                    right = height * (d[1] / 100) - 25
                    list.push([left, right])
                }) 

                localeList.value = list
            }, 500);
           


            // localeList.value = factory_info.value.position
        }
    }
}


let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

let customRequest2 = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    facPic2.value = res.data
    onSuccess({ url: file.url, status: "done" });
  }
};


// 编辑地点
let ELvisible = ref(false)
let facPic2 = ref('')
const editLocation = () => {
    facPic2.value = ''
    positionELList.value = []

    if(facFileList.value && facFileList.value.length) {
        facPic2.value = facFileList.value[0].url
        ELvisible.value = true
        getEL()
    } else {
        ELvisible.value = true
    }
}

const getEL = () => {
    if(factory_info.value.position?.length > 0) {
        setTimeout(() => {
            let div:any = document.getElementById('myDiv');

            positionELList.value = factory_info.value.position.map((d:any) => {
                let width = div.offsetWidth || 0;
                let height = div.offsetHeight || 0;
                let ELX = width * (d[0] / 100) - 25
                let ELY = height * (d[1] / 100) - 25
                return {
                    ELY,
                    ELX,
                    x: d[0],
                    y: d[1],
                }
            })

        }, 500);
    }
}

let ELX:any = ref(0)
let ELY:any = ref(0)
let positionELList:any = ref([])
const clickEL = () => {
    let event:any = window.event
    ELX.value = event.layerX - 25
    ELY.value = event.layerY - 25
    let div:any = document.getElementById('myDiv');
    let width = div.offsetWidth || 0;
    let height = div.offsetHeight || 0;
    let x = (event.layerX / width) * 100
    let y = (event.layerY / height) * 100
    positionELList.value.push({
        ELX: event.layerX - 25,
        ELY: event.layerY - 25,
        x: Number(x.toFixed(0)),
        y: Number(y.toFixed(0)),
    })
}

const deleteEL = (index:any) => {
    positionELList.value.splice(index, 1)
}

const subEL = async() => {
    form.value = factory_info.value
    form.value.position = positionELList.value.map((d:any) => ([d.x, d.y]))

    
    let submit = JSON.parse(JSON.stringify(form.value))
    delete submit.parentName
    if(facFileList.value?.length > 0) {
        submit.picture = facFileList.value[0].url
    }

    let subForm = {
        position: submit.position,
        picture: submit.picture,
        _id: submit._id,
    }

    let result = await request.post('/api/factories', subForm)
    if(result && result.data) {
        ELvisible.value = false
        selectFactory(submit._id)
    } else {
        message.success(langObj.value['操作失败']+'!')
    }
}





// 区域3d图
let groupInfo:any = ref({})
let groupPic = ref('')
let groupVisible = ref(false)
let config_3d:any = ref({})
let markList:any = ref([])
let machineOptions:any = ref([]);
let machineList:any = ref([])

const selectGroup = () => {
    let groupList = factory_info.value?.groups || []
    groupInfo.value = groupList.find((p:any) => p.sonID === tagSelect.value) || {}
    getMachineList()
}

const getMachineList = async () => {
    machineOptions.value = []
    machineList.value = []
    let config: any = {
        params: {
            factoryId: factory_id.value,
            groupId:tagSelect.value
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/machines", config);
    if(result?.data) { 
        machineList.value = result.data
    } 
    machineOptions.value = [
        {
            label: "区域-标点",
            value: "a-区域",
        },
        {
            label: "区域-标签",
            value: "b-区域",
        }
    ]

    load3D(1)
};



const editGroup3D = () => {
    markList.value = []
    groupVisible.value = true
    load3D(2)
}

let line:any = ref([]);
const load3D = async(type:any) => {
    config_3d.value = { marks: [] };
    
    let config: any = {
        params: {
            groupId: groupInfo.value.sonID,
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/group-3D", config);
    if (result?.data) {
        config_3d.value = result.data

        markList.value = config_3d.value.marks;
    }


    if (type == 2) {
        setTimeout(() => {
        $("#container").ZoomMark({
            markColor: "red",
            afterMark: addRowToTabel,
            showMarkNumber: false,
            markList: [],
        });
        setTimeout(() => {
            if (config_3d.value.img) {
            $("#container").ZoomMark("config", config_3d.value.img);
            }

            setTimeout(() => {
            if (config_3d.value.marks.length) {
                $("#container").ZoomMark("addMarks", config_3d.value.marks);
            }
            }, 100);
        }, 100);

        $("#reset").click(function () {
            $("#container").ZoomMark("reset");
        });
        }, 100);
    } else {
        line.value = [];
        for (var i = 0; i < config_3d.value.marks.length; i++) {
            var item = config_3d.value.marks[i];
            if (item.bind.indexOf("a-") > -1) {
                var find = config_3d.value.marks.find(
                (t:any) =>
                    t.bind.substring(2, t.bind.length) ==
                    item.bind.substring(2, item.bind.length) &&
                    t.bind.indexOf("b-") > -1
                );
                if (find) {
                    var distance = Math.sqrt(
                        Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
                    );
                    var angle_d =
                        Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
                    line.value.push({
                        ...item,
                        distance: distance,
                        angle: angle_d,
                    });
                }
            }
        }
        load3DData()
    }
}

const load3DData = () => {
    machineList.value.map((d:any) => {
        if(d.workStatus && d.workStatus.stableCondition) {
            d.condition = d.workStatus.stableCondition
        } else {
            d.condition = 0
        }
    })
    
}


let customRequest3D = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
    groupVisible.value = false;
    // config_3d.value.picture = res.data.url;
    config_3d.value = {
      id: config_3d.value?.id,
      picture: res.data,
      marks: [],
    };
    markList.value = [];
    setTimeout(() => {
        groupVisible.value = true;
        setTimeout(() => {
            $("#container").ZoomMark({
            markColor: "red",
            afterMark: addRowToTabel,
            showMarkNumber: false,
            markList: [],
            });
        }, 800);
    }, 200);
  }
};

const deleteTag = (item: any, index: any) => {
  markList.value.splice(index);
  $("#container").ZoomMark("deleteMark", index);
};

function addRowToTabel(marks:any) {
  for (var i = 0; i < marks.length; i++) {
    if (!markList.value.filter((t:any) => t.id == marks[i].id).length) {
      markList.value.push({ ...marks[i], bind: "" });
    }
  }
  // $('#marksTable').find('tbody').append('<tr id="row_'+marks[marks.length-1].id+'"><td><span class="colorSpan"style="background-color:'+marks[marks.length-1].color+'">'+($('#marksTable').find('tbody').find('tr').length+1)+'</span></td><td >'+marks[marks.length-1].color+'</td><td><textarea name="a" style="width:200px;height:60px;"></textarea></td><td><img src="example/assets/delete.png" onclick="deleteMark('+marks[marks.length-1].id+')"></td></tr>');
}

const save3D = async() => {
    var setting = $("#container").ZoomMark("getSetting");
    if (markList.value.filter((t:any) => !t.bind).length > 1) {
        message.warning("请设置每个标点内容");
        return;
    }

    markList.value.forEach((t:any) => {
        var find = setting.marks.find((t2:any) => t2.id == t.id);
        t.x = find.x;
        t.y = find.y;
    });
    var postData = {
        id: config_3d.value?.id,
        groupId: groupInfo.value.sonID,
        picture: config_3d.value?.picture,
        marks: markList.value,
        img: setting.img,
    };

    if (postData.id) {
        let result = await request.put("/api/group-3D", postData);
        if (result && result.status == 200) {
            message.success("保存成功");
            groupVisible.value = false;
            load3D(1);
        }
    } else {
        let result = await request.post("/api/group-3D", postData);
        if (result && result.status == 200) {
            message.success("保存成功");
            groupVisible.value = false;
            config_3d.value._id = result.data;
            load3D(1);
        }
    }
}

let conditionList = [
  { label: langObj.value["健康"], value: [0], color: "#61c08f", color2: "#0D53B7", valueR: 1 },
  {
    label: langObj.value["可用"],
    value: [1, 2],
    color: "#d2de49",
    color2: "#d2de49",
    valueR: 2,
  },
  {
    label: langObj.value["警戒"],
    value: [3, 4, 5],
    color: "#cd5f3b",
    color2: "#cd5f3b",
    valueR: 3,
  },
  {
    label: langObj.value["故障"],
    value: [6, 7],
    color: "#921e37",
    color2: "#921e37",
    valueR: 4,
  },
];

const getColor = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  return a ? a.color2 : "#072499";
};

const getColor2 = (ev: any) => {
  let a: any = conditionList.find((p: any) => p.value.includes(ev));
  return a?.color;
};
const getShadow = (ev: any) => {
  // box-shadow: inset 0 0 34px 0 #00249b;
  let a = conditionList.find((p: any) => p.value.includes(ev));
  let c = a ? a.color2 : "#072499";

  return "inset 0 0 34px 0 " + c;
};

</script>
<style>
    .gh3d .ant-upload-list {
        display: none !important;
    }
</style>

<style lang="less" scoped>
    .btn1 {
        padding: 3px 10px;
        border-radius: 2px;
        border: 1px solid #072499;
        cursor: pointer;
    }

    .code-box {
        border: 1px solid #0d53b7cc;
        background: rgba(7, 36, 153, 0.2);
        border-radius: 2px;
        padding: 15px;
    }

    .facbox {
        padding: 5px 5px;
        border-radius: 3px;
    }

    .txt-select {
        border-bottom: 2px solid #00fff4;
    }

    

    .lipButton {
        margin-right: 20px;
    }

    .m_r {
        width: 400px;
        height: 600px;
        .list {
            width: 300px;
            margin-left: 30px;
            .item {
            margin-top: 10px;
            display: flex;
            .lleft {
                width: 50px;
                line-height: 30px;
                text-align: center;
            }
            .lright {
                width: 200px;
            }
            .licon {
                width: 50px;
                line-height: 24px;
                text-align: center;
            }
            }
        }
    }
    .mrs {
        margin-left: 20px;
    }
    .main {
        width: 1400px;
        display: flex;
        margin-top: 10px;
    }

    .zmt {
        min-height: 60px;
    }
    .fr {
        float: right;
        margin-right: 10px;
    }

    .danhang {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

</style>
