import request from "./request"
import axios from "axios";
let langObj:any = []

const getList = async() => {
    const reult = await axios.get('/language.json')
    langObj = reult?.data || []
}
getList()

export const getLanguage = (name:any, language:any) => {
    let title = name
    const list:any = langObj[name]
    if(list?.length > 0) {
        if(language === 'Chinese' && list[0]) {
            title = list[0]
        }
        if(language === 'English' && list[0]) {
            title = list[1]
        }
    } 
    return title
}