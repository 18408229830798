<template>
    <div>
        <a-modal 
            v-model:visible="visible"
            :closable="false"
            :footer="null"
            :bodyStyle= "{'background': '#020F46'}"
            style="top: 30%"
            width="1500px"
            :maskClosable="false"
        > 
            <div style="color: #fff" >
                <div class="w-full flex">
                    <div class="flex-1"></div>
                    <div class="cursor-pointer" @click="cancel">
                        <close-outlined style="font-size: 20px;" />
                    </div>
                    
                </div>
                <div class="text-center w-full" style="font-size: 24px;">{{langObj['选择设备']}}</div>

                <div class="p-3 w-full">
                    <a-form
                        :model="searchModel"
                        name="horizontal_login"
                        layout="inline"
                        autocomplete="off"
                    >
                        <a-form-item
                            :label="langObj['设备区域']"
                            name="groupId"
                        >
                            <a-select
                                v-model:value="searchModel.groupId"
                                style="width: 220px"
                                allowClear
                            >
                            <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id"> {{ item.name }} </a-select-option>
                            </a-select>
                        </a-form-item>

                        <!-- <a-form-item
                            label="搜索"
                            name="status"
                            >
                            <a-input v-model:value="searchModel.keyWord" placeholder="请输入设备名称/编号" />
                        </a-form-item> -->


                        <a-form-item>
                            <div class="flex items-center">
                                <div @click="getData" class="box px-5 py-1 fff cursor-pointer ml-4" style="background-color: #072499;">{{langObj['查询']}}</div>
                            </div>
                        </a-form-item>
                        

                    </a-form>

                    <div class="box mt-4" style="width: 100%;">
                        <a-table 
                            style="width: 100%;" 
                            size="small" 
                            :row-class-name="getRowClassName" 
                            :columns="machineColumns" 
                            :data-source="data"
                            :pagination="false"
                        >
                            <template #bodyCell="{ column, text, record }">
                                <template v-if="column.dataIndex === 'factoryId'">
                                <span>{{ getFactoryName(text) }}</span>
                                </template>

                                <template v-if="column.dataIndex === 'groupId'">
                                <span>{{ getGroupName(text) }}</span>
                                </template>

                                <template v-if="column.dataIndex === 'act'">
                                    <a @click="select(record)" style="color: #00FFF4 !important">{{langObj['选择']}}</a>
                                </template>
                            </template>
                        </a-table>


                    </div>



                </div>
            </div>
        </a-modal>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, defineExpose, defineEmits, computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { getFactoryName, getGroupName, transformDate2 } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { langList } from "../../common/lang";

let visible = ref(true)
let groupList = ref([])
const router = useRouter();
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()
const machineColumns = [
    {
        title: langObj.value['设备编号'],
        dataIndex: 'machineCode',
    },  
    {
        title: langObj.value['设备名称'],
        dataIndex: 'machineName',
    },  
    {
        title: langObj.value['所属工厂'],
        dataIndex: 'factoryId',
    },  
    {
        title: langObj.value['设备区域'],
        dataIndex: 'groupId',
    }, 
    {
        title: langObj.value['设备位置'],
        dataIndex: 'position',
    },  
    {
        title: langObj.value['设备厂家'],
        dataIndex: 'supplier',
    },  
    {
        title: langObj.value['设备型号'],
        dataIndex: 'model',
    },  
    {
        title: langObj.value['设备类型'],
        dataIndex: 'machineType',
    },  
    {
        title: langObj.value['设备类型缩写'],
        dataIndex: 'machineType',
    },  
    {
        title: langObj.value['设备SN号'],
        dataIndex: 'sn',
    },  
    {
        title: langObj.value['巡检序号'],
        dataIndex: 'sort',
    },  
    // {
    //     title: langObj.value['是否实验台'],
    //     dataIndex: 'experiment',
    // },  
    {
        title:langObj.value['设备描述'],
        dataIndex: 'remark',
    },  
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },  
]
const props: any = defineProps({
    visible: null,
});
let searchModel:any = ref({
    groupId: '',
})
let data:any = ref([])

const emits = defineEmits(["selectMachineSen"]);

watch(
    () => props.visible,
    (newValue, oldValue) => {
        visible.value = newValue
        init()
    }
);

onMounted(() => {
    init()
})

const cancel = () => {
    visible.value = false
    emits("selectMachineSen", false);
}

const select = (ev:any) => {
    visible.value = false
    emits("selectMachineSen", ev);
}

const init = () => {
    getGroupList()
    getData()
}

// 获取区域列表
const getGroupList = () => {
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    // console.log('memberInfo', memberInfo)
    groupList.value = memberInfo.groups
}

const getData = async() => {
    let config: any = {
        params: {
            groupId: searchModel.value.groupId,
            factoryId: localStorage.getItem('factory_id')
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request('/api/machinesall', config)
    if(result && result.status === 200) {
        data.value = result.data
        console.log(' data.value',  data.value)
    }
}

const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}
</script>

<style lang="less" scoped>
.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.fff {
    color: #fff;
}

/deep/ .ant-table {
    background: #020F46;
    color: #fff;
}

/deep/ .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: transparent;
}

/deep/ .ant-empty-normal {
    color: #fff;
}

/deep/ .ant-descriptions-item-content {
    color: #fff
}
/deep/ .ant-table-tbody > tr > td {
  border-bottom: 0px solid #fff
}
/deep/ .ant-select-clear span {
  background-color: #000 ;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
    background: #021766;
}
</style>