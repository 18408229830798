<template>
    <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="w-full flex" style="color: #fff;">
        <!-- 左侧 -->
        <div style="width: 220px;">
            <!-- <div class="w-full box p-2" style="width: 220px;">
                <a-radio-group v-model:value="searchModel.type" style="width: 100%;" @change="init">
                    <a-radio v-for="(item, index) in typeList" :key="index" style="display: block;" :value="item.value">{{ item.label }}</a-radio>
                </a-radio-group>
            </div> -->

            <div class="box" style="width: 220px;">
                <a-table 
                    style="width: 100%;" 
                    size="small" 
                    :row-class-name="getRowClassName" 
                    :columns="columns" 
                    :data-source="dataList"
                    :pagination="pagination"
                    @change="handleTableChange"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, hideSelectAll: true }"
                    rowKey="_id"
                >
                    <template #bodyCell="{ column, text }">
                        <template v-if="column.dataIndex === 'opt'">
                            <a style="color: #00FFF4 !important">{{langObj['下载']}}</a>
                        </template>
                        <template v-if="column.dataIndex === 'value'">
                            <span v-if="text || text==0"> {{ text.toFixed(2) }}</span>
                        </template>
                    </template>
                    
                </a-table>
            </div>

        </div>

        <!-- 右侧 -->
        <div class="flex-1 box ml-4 p-3">
            <div v-if="visible" id="chart9" style="width: 100%; height: 700px;"></div>
        </div>

    </div>
</a-config-provider>
</template>
<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../../common/request";
import { transformDate2, transformDate } from "../../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import 'echarts-gl';


import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { langList } from "../../../common/lang";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()


 let typeList = [
  { label: langObj.value["高频加速度(长)"], value: 2, type: "TW", unit: "m/s²" },
  { label: langObj.value["高频加速度"], value: 1, type: "HZ", unit: "m/s²" },
  // {label : '温度', value: 2},
  { label: langObj.value["低频加速度X"], value: 3, type: "LX", unit: "m/s²" },
  { label: langObj.value["低频加速度Y"], value: 4, type: "LY", unit: "m/s²" },
  { label: langObj.value["低频加速度Z"], value: 5, type: "LZ", unit: "m/s²" },
  { label: langObj.value["速度X"], value: 6, type: "SX", unit: "mm/s" },
  { label: langObj.value["速度Y"], value: 7, type: "SY", unit: "mm/s" },
  { label: langObj.value["速度Z"], value: 8, type: "SZ", unit: "mm/s" },
];



const columns = [
{
    title: langObj.value["时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["值"],
    dataIndex: "value",
  },
];


let dataList:any = ref([])
let chartA:any 
let visible = ref(true)

const props:any = defineProps({
    sensorInfo: null,
});
let sensorInfo:any = ref()
sensorInfo.value = props.sensorInfo
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let searchModel:any = ref({
    skip: 1,
    take: 20,
    total: 0,
    type: 1,
})
watch(
    () => props.sensorInfo,
    (newValue, oldValue) => {
        sensorInfo.value = props.sensorInfo
        init();
    }
);

onMounted(() => {
    init()
})

const init = () => {
    if(!sensorInfo.value || !sensorInfo.value.id) return
    getTrendListAll()
}

let selectedRowKeys:any = ref([])
const onSelectChange = (ev:any) => {
    selectedRowKeys.value = ev
    getChartData()
}


const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

const handleTableChange = (pagination: any) => {
    // 处理分页数据
    searchModel.value.skip = pagination.current
    searchModel.value.take = pagination.pageSize
    getTrendListAll();
};

//获取所有时间点数据
const getTrendListAll = async() => {
    dataList.value = []
    selectedRowKeys.value = []
    let config: any = {
        params: {
            machineId: sensorInfo.value.machineId ,
            sensorId: sensorInfo.value.id ,
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            begin: transformDate(new Date('2021/01/01 00:00:00')),
            end: transformDate(new Date()),
            type: 'HZ'
        },
        headers: {
            requestId: uuidv4(),
        },
    }; 

    let result = await request.get('/api/external/singles', config)

    if(result && result.data) {
        dataList.value = result.data.list
        searchModel.value.total = result.data.count 
    }
}

let chartDataList:any = []
const getChartData = async() => {
    chartDataList = []
    if(selectedRowKeys.value.length) {
        for(let index in selectedRowKeys.value) {
            let item = selectedRowKeys.value[index]
            let info = dataList.value.find((p:any) => p._id === item)
            let result = await request.post('/draw/api/fft', {url: [info.url]})
            console.log('result', result)
            chartDataList.push({
                data: {
                    f: result.data.axisX,
                    fft: result.data.axisY
                },
                date: info.date
            })
        }


        console.log('chartDataList', chartDataList)
        initChart();
        

    } else {
        chartA && chartA.clear()
    }
}

let myChart:any
const initChart = () => {
    if(!document.getElementById('chart9')) return
    const ec = echarts as any;
    myChart = ec.init(document.getElementById('chart9'));
    myChart.clear()

    let series:any[] = []
    console.log('chartDataList', chartDataList)
    chartDataList.forEach( (d:any) => {
      let data:any = []
      d.data && d.data.f && d.data.f.forEach( (p:any, i:any) => {
        data.push([p, d.date, d.data.fft?.[i]])
      })

      let serie = {
        name: d.date,
        type: 'line3D',
        data: data,
        lineStyle: {
          width: 4
        }
      }
      series.push(serie)
    })

    myChart.setOption({
        tooltip: {
            textStyle: {
            }
        },
        visualMap: {
            show: false,
            dimension: 2,
            inRange: {
                color: [
                    '#00FFF4',
                ]
            }
        },
        xAxis3D: {
            name: langObj.value['频率-Hz'],
            nameGap: 25, 
            nameTextStyle: {
                fontSize: 16,
                color: '#fff',
            },
            type: 'value',
            axisLabel: {
                color: '#fff',
                fontSize: 16,
            },
            lineStyle: {
                opacity: 0.3,
                color: '#fff'
            },
        },
        yAxis3D: {
            name: langObj.value['时间'] ,
            nameGap: 50, 
            type: 'category',
            nameTextStyle: {
                fontSize: 16,
                color: '#fff'
            },
            axisLabel: {
                color: '#fff',
                fontSize: 16,
            },
        },
        zAxis3D: {
            name: langObj.value['幅值-m/s²'],
            nameGap: 35, 
            nameTextStyle: {
            fontSize: 16,
            color: '#fff'
            },
            type: 'value',
            axisLabel: {
                color: '#fff',
                fontSize: 16,
            },
        },
        grid3D: {
            boxWidth: 70,
            boxDepth: 70,
		    boxHeight: 70,
            viewControl: {
                projection: 'orthographic'
            },
            axisPointer:{
                lineStyle:{
                    color:'#fff' 				//鼠标滑过分割线颜色
                }
            },
        },
        series: series
    });

}


</script>

<style lang="less" scoped>
/deep/ .ant-picker {
  background-color: #07249933 !important ;
}

.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
    width: 250px;
}

.btn1 {
    padding: 3px 10px;
}

/deep/ .ant-table-tbody > tr > td {
    border: 0px
}
/deep/ .ant-table {
    color: #fff
}
/deep/ .ant-table-thead .ant-table-cell {
    background-color: #020F46;
    color: #fff;
}

/deep/ table tbody tr:hover>td {
    background-color: #020F46 !important;
}

/deep/ .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #020F46 !important;
}

/deep/ .ant-table-tbody > tr > td {
    transition: 0s;
}

/deep/ .ant-table-tbody > tr.ant-table-row-selected > td {
    background: transparent;
    border-color: #fff;
}

/deep/ .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    background: #020F46;
}

/deep/ .ant-empty-description {
    color: #fff;
}
</style>
