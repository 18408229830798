<template>
  <div :style="showExpertCareOptions?{}:{'padding':'30px 0'}"
       class="bule_list_page">
    <a-layout-content style="padding: 0 20px">
      <a-row type="flex">
        <a-col :span="24" :order="2">
          <a-form layout="inline">
            <a-form-item>
              <a-button type="primary" @click="add">
                <template #icon>
                  <plus-outlined/>
                </template>
                {{ getLanguage('新增', language) }}
              </a-button>
            </a-form-item>
          </a-form>
          <div class="main">
            <a-table :emptyText="'暂无数据'" bordered :columns="columns" :data-source="dataSource" :pagination="false"
                     :loading="loading" @change="handleTableChange" size="small">
              <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'group'">
                  {{ record.group.Name || record.group.name }}
                </template>
                <template v-if="column.dataIndex === 'machines'">
                  <span v-for="(item, index) in text" :key="index">
                    <a-tag color="#2db7f5"
                           v-if="machineMatch.find((t: any) => t.id == item)">{{
                        machineMatch.find((t: any) => t.id == item).machineName
                      }}</a-tag>
                  </span>
                </template>
                <template v-if="column.dataIndex === 'alarmType'">
                  <span>
                    {{ record.alarmTactics == 0 ? getLanguage('分指标报警', language) : "" }}
                  </span>
                  <span>
                    {{
                      record.alarmTactics == 1 ? getLanguage('按设备整体状态', language) : ""
                    }} </span>,
                  <span class="ml-2">
                    {{ record.alarmType == 0 ? getLanguage('单点即刻报警', language) : "" }}
                  </span>
                  <span>
                    {{ record.alarmType == 1 ? getLanguage('短期重复出现报警', language) : "" }}
                  </span>
                </template>
                <template v-if="column.dataIndex === 'removeErrorData'">
                  {{ text ? getLanguage('是', language) : getLanguage('否', language) }}
                </template>
                <template v-if="column.dataIndex === 'exceptConfirmSend'">
                  {{ text ? getLanguage('是', language) : getLanguage('否', language) }}
                </template>
                <template v-if="column.dataIndex === 'act'">
                  <div class="w-full flex">
                    <div class="flex-1"></div>
                    <div style="width: 180px">
                      <a-popconfirm :title="getLanguage('确定删除此条数据？', language)"
                                    :ok-text="getLanguage('确定', language)"
                                    :cancel-text="getLanguage('取消', language)" @confirm="deleteThis(record)">
                        <a-button type="primary" danger>
                          <delete-outlined/>
                          {{ getLanguage('删除', language) }}
                        </a-button>
                      </a-popconfirm>
                      <a-button type="primary" class="plft" @click="editThis(record)">
                        <edit-outlined/>
                        {{ getLanguage('编辑', language) }}
                      </a-button>
                    </div>
                  </div>

                </template>
              </template>
            </a-table>
          </div>
        </a-col>
      </a-row>
    </a-layout-content>
    <a-modal :width="1200" v-model:visible="modalState.visible" :title="modalState.title" :maskClosable="false"
             :confirm-loading="modalState.confirmLoading" @ok="handleOk" :cancelText="getLanguage('取消', language)"
             :okText="getLanguage('确定', language)">
      <a-form :model="formInfo" name="basic" autocomplete="off" layout="inline" ref="formStateRef"
              :label-col="{ style: { width: '120px' } }" style="color: #fff;">
        <div class="freqx_formTitle">{{ getLanguage('基本信息', language) }}</div>
        <a-form-item :label="getLanguage('工厂', language)" name="factoryId" lab
                     :rules="[{ required: true, message: getLanguage('请选择', language) }]"
                     @change="clearValidate('factoryId')">
          <a-tree-select v-model:value="formInfo.factoryId" style="width: 200px" :tree-data="factoryList"
                         :search-placeholder="getLanguage('请选择', language)" @select="selectFactory"/>
        </a-form-item>
        <a-form-item :label="getLanguage('区域', language)" name="groupId"
                     :rules="[{ required: true, message: getLanguage('请选择', language) }]"
                     @change="clearValidate('groupId')">
          <a-select v-model:value="formInfo.groupId" :options="groupList" :placeholder="getLanguage('请选择', language)"
                    style="min-width: 150px"
                    @select="selectGroup">
          </a-select>
        </a-form-item>
        <a-form-item :label="getLanguage('设备', language)" name="machines"
                     :rules="[{ required: true, message: getLanguage('请选择', language) }]"
                     @change="clearValidate('machines')">
          <a-select v-model:value="formInfo.machines" style="width: 380px" :options="machinesList"
                    :placeholder="getLanguage('请选择', language)"
                    mode="multiple" :max-tag-count="5" :max-tag-text-length="6">
          </a-select>
        </a-form-item>

        <div class="freqx_formTitle">{{ getLanguage('策略配置', language) }}</div>

        <div class="code-box-meta markdown">
          <div class="code-box-title" style="background: #03155C;">{{ getLanguage('报警策略', language) }}</div>
          <div class="code-box-description">
            <a-form-item label="" name="alarmTactics">
              <span class="flex">
                <a-radio-group name="radioGroup" v-model:value="formInfo.alarmTactics">
                  <a-radio :value="0">{{ getLanguage('分指标报警', language) }}</a-radio>
                  <a-radio :value="1">{{ getLanguage('按设备整体状态', language) }}</a-radio>
                </a-radio-group>
                <a-tooltip :key="1" placement="top" color="#fff" :overlayStyle="{ 'max-width': '1600px' }">
                  <template #title>
                    <div class="ant-table ant-table-bordered ant-table-empty" style="width: 1500px">
                      <div class="ant-table-container">
                        <div class="ant-table-content">
                          <table>
                            <thead class="ant-table-thead">
                              <tr>
                                <th class="ant-table-cell" colspan="2" rowspan="2">
                                  <span class="bigFont">{{ getLanguage('智能诊断报警策略组合', language) }}</span>
                                </th>
                                <th class="ant-table-cell">
                                  <span class="bigFont">{{ getLanguage('单点即刻报警', language) }}</span>
                                </th>
                                <th class="ant-table-cell">
                                  <span class="bigFont">{{ getLanguage('短期重复出现报警', language) }}</span>
                                </th>
                              </tr>
                              <tr>
                                <th class="ant-table-cell">
                                  <span>{{ getLanguage('一旦监测到分数异常，即刻报警', language) }} </span>
                                </th>
                                <th class="ant-table-cell">
                                  <span>{{
                                      getLanguage('统计短期内重复出现同类型异常报警，去除工况突变、数据采集等异常值误报', language)
                                    }}
                                  </span>
                                </th>
                              </tr>
                              <tr>
                                <th class="ant-table-cell">
                                  <span class="bigFont">{{ getLanguage('分指标报警', language) }} </span>
                                </th>
                                <th class="ant-table-cell">
                                  <span>{{ getLanguage('每个指标发生分数恶化，都单独发送报警', language) }}
                                  </span>
                                </th>
                                <th class="ant-table-cell">
                                  <span>{{
                                      getLanguage('适合处于非健康状态，或指标磨合期的设备，需要故障模式报警', language)
                                    }}
                                  </span>
                                </th>
                                <th class="ant-table-cell">
                                  <span>{{ getLanguage('适合运行较稳定，需要故障模式报警', language) }} </span>
                                </th>
                              </tr>
                              <tr>
                                <th class="ant-table-cell">
                                  <span class="bigFont">{{ getLanguage('按设备整体状态', language) }} </span>
                                </th>
                                <th class="ant-table-cell">
                                  <span>{{ getLanguage('仅当设备整体状态恶化时，发送报警', language) }} </span>
                                </th>
                                <th class="ant-table-cell">
                                  <span>{{ getLanguage('用于对整体设备状态监测，非健康设备报警时效性要求高', language) }}
                                  </span>
                                </th>
                                <th class="ant-table-cell">
                                  <span>{{
                                      getLanguage('用于对整体设备状态监测，设备运行较稳定，趋势性变化突发异常概率低', language)
                                    }}
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </template>
                  <question-circle-outlined style="color: #fff;" class="freqx_tips"/>
                </a-tooltip>
              </span>
            </a-form-item>
          </div>
          <div class="code-box-description">
            <a-form-item label="" name="alarmType">
              <a-radio-group name="alarmType" v-model:value="formInfo.alarmType">
                <a-radio :value="0">{{ getLanguage('单点即刻报警', language) }}</a-radio>
                <a-radio :value="1">{{ getLanguage('短期重复出现报警', language) }}</a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="" name="deadline" style="width: 80px">
              <a-input suffix="笔内" :disabled="!formInfo.alarmType" v-model:value="formInfo.deadline"/>
            </a-form-item>
            <a-form-item label="" name="counter" style="width: 80px">
              <a-input suffix="次" :disabled="!formInfo.alarmType" v-model:value="formInfo.counter"/>
            </a-form-item>
          </div>
        </div>
        <div class="code-box-meta markdown">
          <div class="code-box-title" style="background: #03155C;">{{ getLanguage('工况异常处理', language) }}</div>
          <div class="code-box-description" style="line-height: 32px">
            <a-form-item label="" name="removeErrorData">
              <a-radio-group name="alarmType" v-model:value="formInfo.removeErrorData">
                <a-radio :value="true">{{ getLanguage('是', language) }}</a-radio>
                <a-radio :value="false">{{ getLanguage('否', language) }}</a-radio>
              </a-radio-group>
            </a-form-item>
            <div class="flex items-center">
              <div style="width: 140px;">{{ getLanguage('开关机影响时间范围', language) }}:</div>
              <a-input style="width: 230px;" :suffix="getLanguage('分钟（ 范围：0 - 30 ）', language)"
                       v-model:value="formInfo.affectTimeSpan"/>
            </div>
            <!-- <a-form-item :label="getLanguage('开关机影响时间范围', language)" name="alarmType" style="width: 400px">
              <a-input :suffix="getLanguage('分钟（ 范围：0 - 30 ）', language)" v-model:value="formInfo.affectTimeSpan" />
            </a-form-item> -->
          </div>
          <div class="code-box-description" style="line-height: 32px">
            {{ getLanguage('距最近开机', language) }}&nbsp;&nbsp;&nbsp;
            <a-form-item label="" name="nearBootStart" style="width: 100px">
              <a-input v-model:value="formInfo.nearBootStart"/>
            </a-form-item>
            -&nbsp;&nbsp;&nbsp;&nbsp;
            <a-form-item label="" name="nearBootEnd" style="width: 100px">
              <a-input v-model:value="formInfo.nearBootEnd"/>
            </a-form-item>
            {{ getLanguage('笔数据判断状态，向前', language) }}&nbsp;&nbsp;&nbsp;
            <a-form-item label="" name="prevNumber" style="width: 100px">
              <a-input v-model:value="formInfo.prevNumber"/>
            </a-form-item>
            {{ getLanguage('笔数据判断状态。', language) }}
          </div>
        </div>
        <a-form-item v-if="showExpertCareOptions" :label="getLanguage('是否专家看护', language)" name="alarmType">
          <a-radio-group name="alarmType" v-model:value="formInfo.exceptConfirmSend">
            <a-radio :value="true"> {{ getLanguage('是', language) }}</a-radio>
            <a-radio :value="false"> {{ getLanguage('否', language) }}</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { useRouter } from "vue-router";
import { computed, onMounted, watch } from "vue";
import { ref, reactive } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { getLanguage } from "../../common/translate"

const router = useRouter();
const loading = ref(false);
const formRef = ref();
/**操作日志记录 */
import { operateLogs } from "@/common/logs";

operateLogs('访问智能诊断报警');

let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
}
getLang()

interface Props {
  hideExpertCareOptions?: boolean // 是否隐藏专家看护选项，诊断管理中需要显示，报警管理中需要隐藏
}

const props = withDefaults(defineProps<Props>(), {
  hideExpertCareOptions: false,
})

let showExpertCareOptions = computed<boolean>(() => {
  return !props.hideExpertCareOptions
});

/**操作日志记录 */
const formState = reactive({
  keyword: "",
  factory: "",
  skip: 1,
  take: 10,
});
const formStateRef = ref<any>();

const modalState = reactive({
  visible: false,
  title: "新增",
  confirmLoading: false,
  roleName: "",
  meun: [],
});

const pagination = computed(() => ({
  total: 200,
  current: formState.skip,
  pageSize: formState.take,
}));

const dataSource = ref<any>();
let columns = ref<any[]>([
  {
    title: getLanguage("工厂", language.value),
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: getLanguage("区域", language.value),
    dataIndex: "group",
    align: "center"
  },
  {
    title: getLanguage("设备", language.value),
    dataIndex: "machines",
    align: "center"
  },
  {
    title: getLanguage("报警策略", language.value),
    dataIndex: "alarmType",
    align: "center"
  },
  {
    title: getLanguage("工况异常处理", language.value),
    dataIndex: "removeErrorData",
    align: "center"
  },
  {
    title: getLanguage("操作", language.value),
    dataIndex: "act",
    with: '200px',
    align: "center"
  }
]);

watch(showExpertCareOptions, (show) => {
  let idx = columns.value.findIndex(t => t.dataIndex === "exceptConfirmSend");
  if (show) {
    if (idx < 0) {
      columns.value.splice(columns.value.length - 1, 0, {
        title: getLanguage("是否专家看护", language.value),
        dataIndex: "exceptConfirmSend",
        align: "center"
      });
    }
  } else {
    if (idx >= 0) {
      columns.value.splice(idx, 1)
    }
  }
}, { immediate: true })

// 新增
const add = () => {
  modalState.title = getLanguage("新增", language.value);
  formStateRef.value?.clearValidate();
  formInfo = ref<any>({
    id: 0,
    factoryId: "",
    factoryName: "",
    groupId: "", //object =>  sonID  name
    group: {}, //object =>  sonID  name
    alarmTactics: 0, // 报警策略 0=分指标报警 1=按设备整体状态
    alarmType: 1, // 报警类型 0=单点即刻报警 1=短期重复出现报警

    removeErrorData: false, // 开关机工况异常数据剔除
    exceptConfirmSend: true, // 专家诊断确认后发送警报 bool
    machines: [], // 绑定的设备编号 array => string
    deadline: 12, // ? 小时内
    counter: 2, // 次数
    affectTimeSpan: 10, //  开关机影响时间范围 0-30 分钟
    nearBootStart: 12, // 距最近开机?笔数
    nearBootEnd: 24, // 距最近开机?笔数
    prevNumber: 72, // 向前?笔数
  });

  modalState.visible = true;
};
const editThis = (val: any) => {
  modalState.visible = true;
  modalState.title = getLanguage("编辑", language.value);

  // console.log('factoryList.value', factoryList.value)
  factoryList.value.map((f: any) => {
    if (f?.children?.length > 0) {
      let facInfo = f.children.find((p: any) => p.id === val.factoryId)
      if (facInfo) {
        groupList.value = facInfo.groups;
        let groupInfo = facInfo.groups.find((p: any) => p.id === val.groupId)
        formInfo.value.group = {
          sonID: groupInfo?.value,
          name: groupInfo?.label,
        };
        machinesList.value = groupInfo.machine
      }

    } else {
      if (f.id == val.factoryId) {
        groupList.value = f.groups;
        let groupInfo = f.groups.find((p: any) => p.id === val.groupId)
        formInfo.value.group = {
          sonID: groupInfo?.value,
          name: groupInfo?.label,
        };
        machinesList.value = groupInfo.machine
      }
    }


  })


  // factoryList.value.forEach((element: any) => {
  //   if (element.id == val.factoryId) {
  //     groupList.value = element.groups;
  //     selectedFactory.value = element.groups;
  //     machinesLists.value = element.machine;
  //     var node = groupList.value.find((t: any) => t.id == val.groupId);
  //     if (node) {
  //       formInfo.value.group = {
  //         sonID: node.value,
  //         name: node.label,
  //       };
  //       formInfo.value.machines = [];
  //       machinesList.value = (machinesLists.value || []).filter(
  //         (t: any) => t.groupId == node.value
  //       );
  //     }
  //   }

  //   (element.children||[]).forEach((sonFact: any) => {
  //     if (sonFact.type == "factory") {
  //       if (sonFact.id == val.factoryId) {
  //         groupList.value = sonFact.groups;
  //         selectedFactory.value = sonFact.groups;
  //         machinesLists.value = sonFact.machine;
  //         var node = groupList.value.find((t: any) => t.value == val.groupId);
  //         if (node) {
  //           formInfo.value.group = {
  //             sonID: node.value,
  //             name: node.label,
  //           };
  //           formInfo.value.machines = [];
  //           machinesList.value = (machinesLists.value || []).filter(
  //             (t: any) => t.groupId == node.value
  //           );
  //         }
  //       }
  //     }
  //   });
  // });

  // console.log(groupList.value);
  setTimeout(() => {
    formInfo.value = JSON.parse(JSON.stringify(val));
    formStateRef.value.clearValidate();
  }, 500);
};
const clearValidate = (name: any) => {
  formStateRef.value?.clearValidate(name);
};
const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/event-alarm/config", config).then((res) => {
    if (res) {
      operateLogs('删除智能诊断报警，id:' + val.id);
      message.success("操作成功");
      search();
    } else {
    }
  });
};
// 保存
const handleOk = () => {
  formStateRef.value
      .validate()
      .then((result: any) => {
        console.log(formInfo.value);
        formInfo.value = {
          ...formInfo.value,
          deadline: Number(formInfo.value.deadline), // ? 小时内
          counter: Number(formInfo.value.counter), // 次数
          affectTimeSpan: Number(formInfo.value.affectTimeSpan), //  开关机影响时间范围 0-30 分钟
          nearBootStart: Number(formInfo.value.nearBootStart), // 距最近开机?笔数
          nearBootEnd: Number(formInfo.value.nearBootEnd), // 距最近开机?笔数
          prevNumber: Number(formInfo.value.prevNumber), // 向前?笔数
        }
        if (formInfo.value.id) {
          const config = {
            headers: {
              requestId: uuidv4(),
            },
          };
          let body = formInfo.value;
          if (body.id && !showExpertCareOptions.value) {
            delete body.exceptConfirmSend;
          }
          request
              .put("/api/event-alarm/config", formInfo.value, config)
              .then((res) => {
                if (res) {
                  operateLogs('修改智能诊断报警，id:' + formInfo.value.id);
                  modalState.visible = false;
                  message.success("操作成功");
                  search();
                }
              });
        } else {
          const config = {
            headers: {
              requestId: uuidv4(),
            },
          };
          request
              .post("/api/event-alarm/config", formInfo.value, config)
              .then((res) => {
                if (res) {
                  operateLogs('添加智能诊断报警');
                  modalState.visible = false;
                  message.success("操作成功");
                  search();
                }
              });
        }
      })
      .catch((err: any) => {
        console.log(err); //失败后执行
      });
};

const handleTableChange = (val: any) => { };
// 查询
let machineMatch: any = ref([])
const search = async () => {
  const config = {
    params: {
      factoryId: localStorage.getItem('factory_id') || ''
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let ms = await request.get("/api/machines", config)
  if (ms && ms.data) {
    machineMatch.value = ms.data
  }


  request.get("/api/event-alarm/config", config).then((res: any) => {
    if (res) {
      dataSource.value = res.data;
    }
  });
};

let formInfo = ref<any>({
  id: 0,
  factoryId: "",
  factoryName: "",
  groupId: "", //object =>  sonID  name
  group: {}, //object =>  sonID  name
  alarmTactics: 0, // 报警策略 0=分指标报警 1=按设备整体状态
  alarmType: 1, // 报警类型 0=单点即刻报警 1=短期重复出现报警
  deadline: 12, // ? 小时内
  counter: 2, // 次数
  removeErrorData: false, // 开关机工况异常数据剔除
  exceptConfirmSend: true, // 专家诊断确认后发送警报 bool
  machines: [], // 绑定的设备编号 array => string

  affectTimeSpan: 10, //  开关机影响时间范围 0-30 分钟
  nearBootStart: 12, // 距最近开机?笔数
  nearBootEnd: 24, // 距最近开机?笔数
  prevNumber: 72, // 向前?笔数
});

onMounted(() => {
  var requestId = uuidv4();
  loadFactory(requestId);
  search();
});
let selectedFactory = ref<any>([]);
let machinesLists = ref<any>([]);
const selectFactory = (value: any, node: any, extra: any) => {
  formInfo.value.factoryName = node.label;
  formInfo.value.group = [];
  formInfo.value.machines = [];
  groupList.value = node.groups;
  selectedFactory.value = node.groups;
  machinesLists.value = []
};
const selectGroup = (value: any, node: any, extra: any) => {
  // console.log('node', node)
  formInfo.value.group = {
    sonID: node.value,
    name: node.label,
  };
  formInfo.value.machines = [];
  machinesList.value = node.machine
};

let machinesList = ref<any>();
let groupList = ref<any>();
let factoryList = ref<any>();
let allFactory = ref<any>([]);

const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
        groups: t.groups.map((group: any) => ({
          id: group.sonID,
          type: "group",
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory = [];
      for (var i = 0; i < list.length; i++) {
        var element = list[i];
        if (!element.parentId) {
          const children = list.filter((t: any) => t.parentId == element.id);
          if (children && children.length) {
            element.children = children
          }
          factory.push(element);
        }
      }
      factoryList.value = factory;
      loadMachine(requestId);
    }
  });
};
let allMachine = ref<any>([]);
const loadMachine = async (requestId: any) => {
  let factoryIds = allFactory.value.map((p: any) => p._id)
  let config = {
    params: { factoryIds: factoryIds },
    headers: {
      requestId: requestId,
    },
  }
  let res = await request.get("/api/machines", config)

  // console.log('factoryList.value', factoryList.value)

  // ret

  factoryList.value.forEach((element: any) => {
    if (res) {
      if (element?.children?.length > 0) {
        element.children.forEach((sonFact: any) => {
          if (sonFact.type == "factory" && sonFact.groups) {
            sonFact.groups.forEach((groupFact: any) => {
              if (groupFact.type == "group") {
                groupFact.machine = res.data
                    .filter((t: any) => t.groupId == groupFact.value)
                    .map((t: any) => ({
                      type: "machine",
                      ...t,
                      value: t.id,
                      label: t.machineName,
                      title: t.machineName,
                      key: t.id,
                    }));
              }
            });
          }
        });
      } else {
        element.groups.forEach((sonFact: any) => {
          if (sonFact.type == "factory" && sonFact.children) {
            sonFact.children.forEach((groupFact: any) => {
              if (groupFact.type == "group") {
                groupFact.machine = res.data
                    .filter((t: any) => t.groupId == groupFact.value)
                    .map((t: any) => ({
                      type: "machine",
                      ...t,
                      value: t.id,
                      label: t.machineName,
                      title: t.machineName,
                      key: t.id,
                    }));
              }
            });
          } else if (sonFact.type == "group") {
            sonFact.machine = res.data
                .filter((t: any) => t.groupId == sonFact.value)
                .map((t: any) => ({
                  type: "machine",
                  ...t,
                  value: t.id,
                  label: t.machineName,
                  title: t.machineName,
                  key: t.id,
                }));
          }
        });

      }


    }
  });

  // factoryList.value.forEach((element: any) => {
  //   const config = {
  //     params: { factoryId: element.value },
  //     headers: {
  //       requestId: requestId,
  //     },
  //   };
  //   request.get("/api/machines", config).then((res: any) => {
  //     if (res) {
  //       allMachine.value = res.data;
  //       res.data = res.data.map((t: any) => ({
  //         ...t,
  //         value: t.id,
  //         label: t.machineName,
  //         title: t.machineName,
  //         key: t.id,
  //       }));
  //       element.machine = res.data;
  //       element.children?.forEach((sonFact: any) => {
  //         if (sonFact.type == "factory") {
  //           sonFact.machine = res.data;
  //         }
  //       });
  //     }
  //   });
  // });
  setTimeout(() => {
    // console.log('factoryList.value2222222222', factoryList.value)
  }, 1000);
};

</script>
<style scoped>
.main {
  margin-top: 30px;
}

.bigFont {
  font-size: 22px;
  font-weight: 700;
}

.freqx_tips {
  margin-top: 4px;
}
</style>
