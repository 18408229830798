<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="flex">
      <div class="p-3 box  w-full flex-1">
        <div class="w-full flex px-4" style="border-bottom: 1px solid #4a5983;margin-bottom: 20px;display: none;">
          <div style="font-size: 18px" class="py-3 cursor-pointer" @click="selectMenu(1,[])"
            :style="{ borderBottom: menuSelect === 1 ? '2px solid #00FFF4' : '' }">
            {{ langObj['全部报告'] }}
          </div>

          <div style="font-size: 18px" class="py-3 ml-12 cursor-pointer" @click="selectMenu(2,[1,2,3,4,5,6,7])"
            :style="{ borderBottom: menuSelect === 2 ? '2px solid #00FFF4' : '' }">
            {{ langObj['报警报告'] }}
          </div>
          <div style="font-size: 18px" class="py-3 ml-12 cursor-pointer" @click="selectMenu(3,[0])"
            :style="{ borderBottom: menuSelect === 3 ? '2px solid #00FFF4' : '' }">
            {{ langObj['健康报告'] }}
          </div>
        </div>
        <a-form :model="searchModel" name="horizontal_login" layout="inline" autocomplete="off">
          <a-form-item :label="langObj['区域']" name="groupId" allowClear>
            <a-select v-model:value="searchModel.groupId" style="width: 200px" allowClear
              :placeholder="langObj['请选择区域']">
              <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="langObj['设备名称']" name="more">
            <a-input v-model:value="searchModel.keyWord" :placeholder="langObj['请输入']" />
          </a-form-item>

          <a-form-item :label="langObj['巡检报告类型']" name="type">
            <a-select v-model:value="searchModel.type" style="width: 120px" allowClear :placeholder="langObj['请选择']">
              <a-select-option v-for="(item, index) in typeLists" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>




          <a-form-item :label="langObj['日期']" name="date">
            <a-range-picker v-model:value="searchModel.date" :disabled-date="disabledDate"
              @calendarChange="onCalendarChange" />
          </a-form-item>
          <a-form-item :label="langObj['巡检人']" name="userNick">
            <a-input v-model:value="searchModel.userNick" :placeholder="langObj['请输入']" />
          </a-form-item>


          <a-form-item>
            <div class="flex items-center">
              <div @click="reset" class="box px-5 py-1 fff cursor-pointer ml-4">
                {{ langObj['重置'] }}
              </div>

              <div @click="getData" class="box px-5 py-1 fff cursor-pointer ml-4" style="background-color: #072499">
                {{ langObj['查询'] }}
              </div>

              <div @click="downLoad" class="box px-5 py-1 fff cursor-pointer ml-4" style="background-color: #072499">
                {{ langObj['导出'] }}
              </div>
              <div class="box px-5 py-1 fff cursor-pointer ml-4" style="background-color: #072499"
                @click="concatReport">
                {{ langObj['合并报告'] }}
              </div>

            </div>
          </a-form-item>
        </a-form>

        <div class="box mt-4" style="width: 100%">
          <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" 
            :columns="inspectionColumns" :data-source="data.list" :pagination="pagination" @change="handleTableChange">
            <template #bodyCell="{ column, text, record,index}">


              <template v-if="column.dataIndex === 'groupId'">
                <span>{{ getGroupName(record.machine?.groupId) }}</span>
              </template>

              <template v-if="column.dataIndex === 'type'">
                <span>{{ getReportType(record) }}</span>
              </template>



              <template v-if="column.dataIndex === 'condition'">
                <div class="flex items-center">
                  <div style="width: 14px; height: 14px; border-radius: 50%"
                    :style="{ background: getReportCondition(record)?.color }"></div>
                  <div class="ml-1" :style="{ color: getReportCondition(record)?.color }">
                    {{ langObj[getReportCondition(record)?.label] }}
                  </div>
                </div>
              </template>



              <template v-if="column.dataIndex === 'act'">
                <a @click="getReportInfo(record,index)" style="color: #00fff4 !important"> {{ langObj['查看报告'] }}</a>



              </template>
            </template>
          </a-table>
        </div>



      </div>
      <div class=" box  ml-4 p-3" style="border-left: 1px solid #0d53b7cc;width: 800px;">



        <reportInfo :reportIdObj="inspectionBase.reportIdObj"></reportInfo>


      </div>



      <a-modal v-model:visible="visibleReportCon" :closable="true" :title="langObj['合并报告']" :okText="langObj['合并报告']"
        :bodyStyle="{ background: '#020F46' }" style="top: 30%" width="2000px" :maskClosable="false"
        :confirmLoading="concatLoading" @ok="concatReportRequest">

        <a-form :model="mergeSearchModel" name="horizontal_login" layout="inline" autocomplete="off">
          <a-form-item :label="langObj['巡检日期']" name="date">
            <a-range-picker v-model:value="mergeSearchModel.date" />
          </a-form-item>
          <a-form-item :label="langObj['设备区域']" name="condition">
            <a-select v-model:value="mergeSearchModel.groupId" style="width: 200px" allowClear
              :placeholder="langObj['请选择区域']" @change="selectGroup">
              <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="langObj['设备']" name="userNick">
            <!-- <a-input style="width: 120px;" v-model:value="searchModel.keyWord" :placeholder="langObj['请输入设备名称']" /> -->
            <a-select v-model:value="mergeSearchModel.machineId" style="width: 200px" allowClear
              :placeholder="langObj['请选择设备']">
              <a-select-option v-for="(item, index) in machineList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="langObj['巡检报告类型']" name="type">
            <a-select v-model:value="mergeSearchModel.type" style="width: 120px" :placeholder="langObj['请选择']">
              <a-select-option v-for="(item, index) in typeLists" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>


          <a-form-item>
            <div class="flex items-center">
              <div @click="resetMerge" class="box px-5 py-1 fff cursor-pointer ml-4">
                {{ langObj['重置'] }}
              </div>

              <div @click="getMergeData" class="box px-5 py-1 fff cursor-pointer ml-4"
                style="background-color: #072499">
                {{ langObj['查询'] }}
              </div>



            </div>
          </a-form-item>
        </a-form>
        <a-spin tip="Loading..." :spinning="reportLoading">
          <div>

            <div v-for="(item1, index1) in mergeDataList" class="record flex items-center" style="color: #fff;">
              <div class="group mr-4">{{ item1.groupName }}</div>
              <div class="machine_box">
                <div v-for="(item2, index2) in item1.machines" class="machine flex items-center">
                  <div v-if="item2.sensors.length > 1" class="machine_name">{{ item2.machineName }}</div>
                  <div v-if="item2.sensors.length > 1" class="point_box flex ">
                    <div class="ml-4 point" v-for="(item3, index3) in item2.sensors">
                      <div class="point_name">{{ item3.sensorPlace }}</div>
                      <div v-for="(item4, index4) in item3.list" class="flex items-center info_box">
                        <div>
                          <a-select v-model:value="item4.selectValue" style="width: 60px" allowClear>
                            <a-select-option v-for="(item5, index5) in item3.list" :key="index5" :value="index5 + 1">
                              {{ index5 + 1 }}
                            </a-select-option>
                          </a-select>
                        </div>
                        <div class="info ml-4">
                          <div>{{ item4.date }}</div>
                          <div>巡检人：{{ item4.userNick }}</div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

              </div>

            </div>


          </div>
        </a-spin>
      </a-modal>
      <!-- 导出 -->
      <a-modal :width="2000" v-model:visible="downLoadVisible" :title="langObj['下载']" :maskClosable="false"
        :footer="false">
        <DownLoadComponent @downLoadCancel="downLoadCancel" :type="2" :dataList="downLoadList"></DownLoadComponent>
      </a-modal>

    </div>
  </a-config-provider>
</template>

<script lang="ts" setup>
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import * as echarts from "echarts";
import {

  getGroupName,
  transformDate2,
  getGroupListByFactory,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import reportInfo from "./reportInfo.vue";
import DownLoadComponent from "./downLoad.vue";

import { Dayjs } from 'dayjs';
const value: any = ref(1);
let groupList: any = ref([]);
let reportLoading = ref(false);
let concatLoading = ref(false);
let menuSelect = ref(2); 


let machineList: any = ref([]);
let dataTypeList = ref<any>();
let isEditable: any = ref(false);
let visibleReportCon = ref(false);
let langObj: any = ref({})
let language: any = ref('Chinese')

let conditionLists = [
  { label: "健康", value: 1, realValue: [0] },
  { label: "可用", value: 2, realValue: [1, 2] },
  { label: "警戒", value: 3, realValue: [3, 4, 5] },
  { label: "故障", value: 4, realValue: [6, 7] },
];

const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]

  conditionLists = [
    { label: langObj.value["健康"], value: 1, realValue: [0] },
    { label: langObj.value["可用"], value: 2, realValue: [1, 2] },
    { label: langObj.value["警戒"], value: 3, realValue: [3, 4, 5] },
    { label: langObj.value["故障"], value: 4, realValue: [6, 7] },
  ];
}
getLang()



let typeLists: any = [
  { label: "基础", value: 2 },
  { label: "智能", value: 1 },
];
let searchModel: any = ref({
  skip: 1,
  take: 10,
  keyWord: "",
  groupId: null,
  machineId: "",
  condition: null,
  status: null,
  sensorPlace: "",
  date: null,
  total: 0,
  type: null,
  userNick: "",
  // conditionList:[1,2,3,4,5,6,7]
});
let inspectionBase: any = ref({
  reportIdObj: {},
  reportInfo: {},
  id: '',
  machineInfo: {},
  fx_code1: 0,
  fx_code1_dis1: 0,
  fx_code2: 0,
  fx_code1_dis2: 0,
  fx_code3: 0,
  fx_code4: 0,
  fx_code5: 0,
  fx_code3_v: 0,
  fx_code4_v: 0,
  fx_code5_v: 0,
  jsdzD: {},
  sdD: {},
  tempD: {},
  directionA: ["z轴", "x轴", "y轴"],
  directionIn: 0,
  directionInB: 0,
  directionInC: 0,
  directionInD: 0,
  fx_code4_v_x: 0,
  fx_code4_v_y: 0,
  fx_code3_xy: 0,
  fx_code3_v_y: 0,
  resultTIME: {},
  resultFFT: {},
  listT: [],
  reportType: 2
})

let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];


let data = ref([]);

const inspectionColumns = [
  {
    title: langObj.value["设备名称"],
    dataIndex: "machineName",
  },

  {
    title: langObj.value["区域"],
    dataIndex: "groupId",
  },

  {
    title: langObj.value["巡检人"],
    dataIndex: "userNick",
  },
  {
    title: langObj.value["巡检日期"],
    dataIndex: "date",
  },
  {
    title: langObj.value["测点"],
    dataIndex: "position",
  },


  {
    title: langObj.value["巡检状态"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["巡检报告类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

const concatColumns = [
  {
    title: langObj.value["区域"],
    dataIndex: "groupName",
    slots: { customRender: 'group' },
    customCell: (_: any, index: any) => ({ rowspan: _.span, }),
    align: "center"
  },
  {
    title: langObj.value["设备名称"],
    dataIndex: "machineName",
    slots: { customRender: 'device' },
    customCell: (_: any, index: any) => ({ rowspan: _.span, }),
    align: "center"
  },
  {
    title: "测点1#",
    dataIndex: "sensorName1",
    slots: { customRender: 'sensor1' },
    align: "center"
  },
  {
    title: "测点2#",
    dataIndex: "sensorName2",
    slots: { customRender: 'sensor2' },
    align: "center"
  },
]

const pagination = computed(() => ({
  // showQuickJumper:false,
  // simple:true,
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
const handleTableChange = (pagination: any, filters: any, sorter: any) => {

  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  search();

};

onMounted(() => {
  isEditable.value = true
  // loadDatatype();

  getGroupList();
  search();
});

const loadDatatype = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/sensors/datatype", config).then((res: any) => {
    if (res) {
      dataTypeList.value = res.data.map((t: any) => ({
        value: t.name,
        label: langObj.value[t.name],
      }));
    }
  });
};

const getReportInfo = (info: any,index:any) => {
  // inspectionBase.value.reportType = info.type;
  inspectionBase.value.reportIdObj = info;
  selectedRowIndex.value = index;
};


// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

const search = () => {
  // searchModel.value.skip = 1;
  getData();
};

const getData = () => {
  getReportList()
};
const selectMenu = (type:any,conditionList:any)=>{
  menuSelect.value = type;
  searchModel.value.conditionList = conditionList;
  search();
}

//获取报告列表
const getReportList = async () => {
  data.value = [];
  let config: any = {
    params: {
      groupId: searchModel.value.groupId,
      factoryId: localStorage.getItem("factory_id"),
      keyword: searchModel.value.keyWord,
      skip: searchModel.value.skip,
      condition: searchModel.value.condition,
      userNick: searchModel.value.userNick,
      take: searchModel.value.take,
      sort: "date",
      order: -1,
      type: searchModel.value.type,
      conditionList:searchModel.value.conditionList
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }
  let result = await request.get("/api/external/inspection", config);

  if (result && result.status === 200) {
    data.value = result.data;
    if (result.data.list.length > 0) {
      getReportInfo(result.data.list[0],0)
    } else {
      getReportInfo({},0)
    }

    searchModel.value.total = result.data.count;
  }
};

// 重置
const reset = () => {
  searchModel.value = {
    ...searchModel.value,
    keyWord: "",
    date: null,
    groupId: null,
    machineId: "",
    type: null,
    condition: null,
    status: null,
    sensorPlace: "",
  };
  search()
};



// 获取报告健康状态
const getReportCondition = (ev: any) => {
  if (ev && ev.condition) {
    return conditionList.find(
      (p: any) => p.value === ev.condition
    );
  } else {
    return conditionList[0];
  }
};

// 获取报告类型
const getReportType = (ev: any) => {
  if (ev && ev.type) {
    return typeLists.find(
      (p: any) => p.value === ev.type
    ).label;
  } else {
    return typeLists[0].label;
  }
};


const selectedRowIndex: any = ref(0);
const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  let className = ""
  if (selectedRowIndex.value == index) {
    className += "active-table"
  } else {
    if (index % 2 === 0) {
      className = "even-row"
    } else {
      className = "odd-row"
    }
  }

  return className
};


const rowClick = (record: any, index: any) => {
  return {
    onclick: (e: any) => {
      selectedRowIndex.value = index;
      // getReportInfo(record)

    }
  }

}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};



// 合并巡检报告功能
let mergeSearchModel: any = ref({
  date: null,
  groupId: null,
  machineId: null,
  type: 1
})
let sensorList: any = ref([])
let mergeDataList: any = ref([])

const resetMerge = () => {
  mergeSearchModel.value = {
    date: null,
    groupId: null,
    machineId: null,
    type: 1
  }
  getMergeData()
}

const concatReport = async (ev: any) => {
  visibleReportCon.value = true;

  await getSensorList()
  getMergeData()
}

//获取测点数据
const getSensorList = async () => {
  let result = await request.get('/api/sensors/all')
  sensorList.value = result?.data || []
}

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "");
  mergeSearchModel.value.machineId = null;
  if (ev) {
    machineList.value = memberInfo.machines.filter(
      (p: any) => p.groupId === ev
    );
  } else {
    machineList.value = [];
  }
};

// 获取数据
const getMergeData = async () => {
  reportLoading.value = true
  let config: any = {
    params: {
      groupId: mergeSearchModel.value.groupId,
      factoryId: localStorage.getItem("factory_id"),
      machineId: mergeSearchModel.value.machineId,
      skip: 1,
      type: mergeSearchModel.value.type,
      take: 100000000
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (mergeSearchModel.value.date && mergeSearchModel.value.date.length === 2) {
    config.params.begin = transformDate2(new Date(mergeSearchModel.value.date[0]), 1);
    config.params.end = transformDate2(new Date(mergeSearchModel.value.date[1]), 2);
  }
  let result = await request.get("/api/external/inspection", config);

  dealWithData(result?.data?.list || [])
}

// 处理数据
const dealWithData = (dataList: any) => {
  let list: any = []
  let groups = []
  if (mergeSearchModel.value.groupId) {
    groups = groupList.value.filter((p: any) => p.id === mergeSearchModel.value.groupId)
  } else {
    groups = groupList.value
  }
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "{}");
  groups.map((g: any) => {
    let machines = []
    if (mergeSearchModel.value.machineId) {
      machines = memberInfo.machines.filter(
        (p: any) => p.id === mergeSearchModel.value.machineId && p.groupId === g.id
      );
    } else {
      machines = memberInfo.machines.filter(
        (p: any) => p.groupId === g.id
      );
    }
    let first: any = {
      groupName: g.name,
      groupId: g.id,
      machines: []
    }
    machines.map((m: any) => {
      let sensors = sensorList.value.filter((p: any) => p.machineId === m.id)
      let sL: any = []
      if (sensors.length > 1) {
        sensors.map((s: any) => {
          let rL = dataList.filter((p: any) => p.sensorId === s.id).map((p: any) => ({ id: p.id, userNick: p.userNick, machineId: p.machineId, sensorId: p.sensorId, date: p.date, selectValue: null }))
          if (rL.length > 0) {
            sL.push({
              id: s.id,
              sensorPlace: s.sensorPlace,
              list: rL
            })
          }


        })
        first.machines.push({
          machineName: m.name,
          machineId: m.id,
          sensors: sL
        })
      }



    })
    // list.push(first)

    // if(first.machines?.map((s:any)=>s.sensors.length>1)){
    //   list.push(first)
    // }
    first.machines?.map((s: any) => {
      if (s.sensors.length > 1 && !list.find((a: any) => a.groupId == first.groupId)) {
        list.push(first)
      }


    })





  })
  reportLoading.value = false
  mergeDataList.value = list

}
const concatReportRequest = (ev: any) => {


  let ids: any = []
  let list = JSON.parse(JSON.stringify(mergeDataList.value))

  for (let g of list) {
    if (g.machines?.length > 0) {
      for (let m of g.machines) {
        // let values = m.sensors.filter((p:any) => p.)
        let lists: any = []
        let numberList: any = []
        for (let s of m.sensors) {
          let values = s.list.filter((p: any) => p.selectValue).map((p: any) => p.selectValue)
          let length1 = values.length
          values = [... new Set(values)]
          let length2 = values.length
          numberList = numberList.concat(values)
          if (length1 !== length2) {
            message.warning('同测点请勿选择相同编号')
            return
          }
          lists.push(s.list.filter((p: any) => p.selectValue))
        }
        numberList = [... new Set(numberList)]
        numberList.map((n: any) => {
          let list: any = []
          lists.map((d: any) => {
            let info = d.find((p: any) => p.selectValue === n)
            if (info) {
              list.push(info.id)
            }
          })
          if (list?.length > 1) {
            ids.push(list)
          }
        })


      }
    }
  }

  console.log('ids', ids)

  if (!ids.length) {
    message.warning('请选择需要合并的报告')
    return
  }
  concatLoading.value = true


  request.post("/api/external/inspection/merge", ids).then((res: any) => {
    
    if (res && res.data) {
      message.success('合并成功');
      visibleReportCon.value = false;
      concatLoading.value = false
      // getMergeData()
      search()
    } else {
      message.error('合并失败');
      concatLoading.value = false;
    }
  })
};

// 导出报告
let downLoadVisible = ref(false)
let downLoadList: any = ref([])
const downLoad = async () => {

  let config: any = {
    params: {
      groupId: searchModel.value.groupId,
      factoryId: localStorage.getItem("factory_id"),
      keyword: searchModel.value.keyWord,
      skip: 1,
      condition: searchModel.value.condition,
      userNick: searchModel.value.userNick,
      take: 10000,
      sort: "date",
      order: -1,
      type: searchModel.value.type,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }
  let result = await request.get("/api/external/inspection/all", config);

  if (result && result.status === 200) {
    downLoadList.value = result.data.list;
  }



  downLoadVisible.value = true
}

const downLoadCancel = () => {
  downLoadVisible.value = false
}



</script>

<style lang="less" scoped>
.record {
  border: 1px solid #4D577D;

  .group {
    width: 15%;
    text-align: center;
    // border-right: 1px solid #4D577D;

  }

  .machine_box {
    width: 85%;
    // border-bottom: 1px solid #4D577D;
    border-left: 1px solid #4D577D;

    .machine {
      border-bottom: 1px solid #4D577D;

      &:last-child {
        border-bottom: none;
      }
    }


  }

  .machine_name {
    width: 15%;
    text-align: center;


  }

  .point_box {
    width: 80%;
    min-height: 25px;
    // border-top: 1px solid #4D577D;
    border-left: 1px solid #4D577D;
  }

  .point {
    width: 16.6%;
    padding-right: 20px;
    padding-bottom: 20px;
    border-right: 1px solid #4D577D;
  }

  .point_name {
    line-height: 40px;
    text-align: center;
  }

  .info_box {
    border-top: 1px solid #4D577D;
  }

  .info {
    div {
      line-height: 25px;
    }
  }
}

.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff;
}

.size20 {
  font-size: 20px;
}

.score-box {
  // margin-top: 20px;
  position: relative;

  .score-txt {
    position: absolute;
    top: 20px;
    left: -5px;
    font-size: 12px;
    color: #9a9ba0;
  }

  .score-80 {
    background: #1CC48B;
    width: 20%;
    height: 13px;
    border-radius: 6px 0 0 6px;
    position: relative;
  }

  .score-60 {
    background: #DDD246;
    width: 20%;
    height: 13px;
    position: relative;
  }

  .score-30 {
    background: #CB6A34;
    width: 30%;
    height: 13px;
    position: relative;
  }

  .score-0 {
    background: #B81212;
    width: 30%;
    height: 13px;
    border-radius: 0 6px 6px 0;
    position: relative;
  }

  .score-posi {
    position: absolute;
    right: 88%;
    top: -6px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #1CC48B;
    box-shadow: 0 0 2px 0 #00000040 inset;
  }
}

.score-default {
  margin-top: 10px;
  background: #072499;
  width: 100%;
  height: 13px;
  border-radius: 6px;
  position: relative;

  .score-txt {
    position: absolute;
    top: 20px;

    font-size: 12px;
    color: #9a9ba0;

    &:nth-of-type(1) {
      left: 0px;
    }

    &:nth-of-type(2) {
      right: 0px;
    }

  }

  .score-posi {
    position: absolute;
    right: 45%;
    top: -6px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #072499;
    box-shadow: 0 0 2px 0 #00000040 inset;
  }
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-table-tbody>tr>td {
  border-bottom: 0px solid #fff;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background: #021766;
}

/deep/ .ant-select-multiple .ant-select-selection-item {
  background: #07249933 !important;
}
</style>