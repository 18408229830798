<template>
  <a-config-provider :locale="locale === 'en' ? enUS : zhCN">
    <a-layout v-if="config.menuVersion === 'V1'">
      <a-layout-sider
        v-if="!ifream"
        v-model:collapsed="collapsed"
        :trigger="null"
        theme="dark"
        collapsible
        :style="{
          height: state.windowHeight + 'px',
        }"
      >
        <div class="logoText" v-show="collapsed">PHM</div>
        <div class="logo ml-4" style="background-color: #fff;" v-show="!collapsed">
          <img style="height: 100%;" src="@/assets/logo.png" />
        </div>
        <a-menu
          v-model:selectedKeys="selectedKeys2"
          v-model:openKeys="openKeys"
          mode="inline"
          :style="{
            height: state.windowHeight - 100 + 'px',
          }"
          class="menuOv"
          theme="dark"
        >
          <span v-for="(item, index) in menuList" :key="index">
            <a-menu-item
              v-if="item.type == 2"
              :key="item.id"
              @click="linkTo(item.route)"
            >
              <template #icon>
                <component v-if="item.icon" :is="item.icon + '-outlined'" />
              </template>
              {{ item.title }}</a-menu-item
            >
            <a-sub-menu v-else :key="item.id + '_'">
              <template #icon>
                <component v-if="item.icon" :is="item.icon + '-outlined'" />
              </template>
              <template #title>
                <span> {{ item.title }} </span>
              </template>
              <a-menu-item
                v-for="children in item.children"
                :key="children.id"
                @click="linkTo(children.route)"
                >{{ children.title }}</a-menu-item
              >
            </a-sub-menu>
          </span>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header
          v-if="!ifream"
          style="padding: 0"
          class="flex"
          theme="dark"
        >
          <span>
            <menu-unfold-outlined
              v-if="collapsed"
              class="trigger"
              @click="() => (collapsed = !collapsed)"
            />
            <menu-fold-outlined
              v-else
              class="trigger"
              @click="() => (collapsed = !collapsed)"
            />
          </span>

          <!-- <span class="plantName">海信设备故障预测与健康管理平台</span> -->
          <span class="plantName">{{ config?.projectName }}</span>
          <div class="flex item-center">
            <a-cascader
              v-if="visibleFactory"
              class="mycasc"
              v-model:value="factoryIds"
              :options="factoryList"
              placeholder="切换工厂(全部)"
              style="width: 250px; margin-right: 20px"
              @change="factoryChange"
            />
            <img
              class="hidden xl:inline-block"
              src="@/assets/people.png"
              width="20"
              height="20"
            />
            <span class="text-gray-50 ml-2">{{
              member.nickName || member.userName
            }}</span>
            <!-- <a-select
              style="width: 120px"
              :options="factoryList"
              @change="factoryChange"
            >
              <template #suffixIcon><smile-outlined /></template>
            </a-select> -->

            <i
              class="inline xl:hidden cursor-pointer text-base mx-2"
              nz-icon
              nzType="logout"
              nzTheme="outline"
            ></i>

            <a class="mx-5 hidden xl:inline-block" @click="logOut">
              <span class="text-gray-50">[安全退出]</span>
            </a>
          </div>
        </a-layout-header>
        <a-layout-content
          id="ant-layout-content"
          :style="{
            margin: '3px 3px',
            padding: '0px 0',
            background: '#fff',
            minHeight: '680px',
            height:
              (ifream ? state.windowHeight - 20 : state.windowHeight - 200) +
              'px',
          }"
          class="menuOv"
        >
          <router-view v-if="roterAlive" />
        </a-layout-content>
        <!-- <a-layout-footer v-if="!ifream" style="text-align: center">
          设备故障预测与健康管理平台 ©2023 Created by Freqx
        </a-layout-footer> -->
      </a-layout>
    </a-layout>

    <a-drawer
      :width="500"
      title="切换工厂"
      placement="right"
      :visible="visible"
      @close="onClose"
    >
    </a-drawer>

    <div id="ant-layout-content" v-if="config.menuVersion === 'V2'">
      <div class="w-full flex" style="height: 100px; background-color: #041C7A; color: #fff;">

        <div class="header-logo flex items-center">
          <img v-if="picUrl && picUrl !== '1'" style="height: 50px;" class="ml-4" :src="picUrl"  />
          <img v-if="!picUrl" style="height: 50px;" class="ml-4" src="@/assets/logo.png" />
          <div class="header-logo-txt ml-2 flex-1">{{ langObj[title] || title }}</div>
          <div class="header-v">v4.1</div>
        </div>


        <!-- 路由 -->
        <div class="flex-1" style="position:relative">
          <div v-if="Number(routerContentWidth)>Number(routerWidth)" class="leftbtn" @click="scrollBar('right')"><CaretLeftOutlined /></div>
          <div v-if="Number(routerContentWidth)>Number(routerWidth)" class="rightbtn" @click="scrollBar('left')"><CaretRightOutlined /></div>
          <!-- 主菜单 -->
          <div style="height: 70px;position:relative" :style="{'width': routerWidth+ 'px'}" class="overflow-hidden" ref="wrapper" id="wrapper">
            
            <div class="flex items-center h-full content" :style="{'width': routerContentWidth+ 'px'}">
              <div v-for="(menu, index) in menuList" :key="index">
                <div  @click="linkTo(menu.code)" style=" width: 250px; height: 50px; font-size: 18px;" class="ml-4" :class="[selectParentCode === menu.code ? 'parallelogram-select' : 'parallelogram-2']" >
                  <div style="transform: skew(30deg); text-align: center;line-height: 43px;">{{ langObj[menu.title] || menu.title }}</div>
                  <!-- <div style="transform: skew(30deg); text-align: center;line-height: 43px;">{{ menu.title }}</div> -->
                  <div class="w-full flex " style=" justify-content: center; transform: skew(30deg)">
                    <div class="text-line" :style="{'background-color': selectParentCode === menu.code ? '#00FAFC' : 'transparent'}"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 子菜单 -->
          <div style="height: 30px;" :style="{'width': routerChildWidth+ 'px'}" class="overflow-hidden" id="wrapper2"> 
            <div class="flex items-center h-full content" :style="{'width': (childrenMenuList.length * 191) + 'px'}">
              <div v-for="(menu, index) in childrenMenuList" :key="index">
                <div  @click="linkToChild(menu.route)"  style=" width: 175px; height: 28px; font-size: 14px;" class="ml-4" :class="[selectChilMenu === menu.route ? 'parallelogram-select' : 'parallelogram-2']" >
                  <div style="transform: skew(30deg); text-align: center;line-height: 28px;">{{ langObj[menu.title] || menu.title  }}</div>
                  <!-- <div style="transform: skew(30deg); text-align: center;line-height: 28px;">{{ menu.title  }}</div> -->
                </div>
              </div>
            </div>

           
          </div>
        </div>

        <!-- 账号工厂 -->
        <div style="position: relative; min-width: 230px;">
          <div class="flex items-center" style="height: 70px;">
            <div class="cursor-pointer" :style="{'color': getLangColor('Chinese')}" @click="selectLang('Chinese')">中文</div>
            <div class="ml-1 mr-1">/</div>
            <div class="cursor-pointer" :style="{'color': getLangColor('English')}" @click="selectLang('English')">English</div>

            <img
              class="ml-2"
              src="@/assets/people.png"
              width="20"
              height="20"
            />
           
            <div class="text-gray-50 ml-2 flex-1" 
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
            >
              {{ member.nickName || member.userName }}
            </div>

            <a class="mx-2" @click="logOut">
              <span class="text-gray-50">{{ langObj["[安全退出]"] }}</span>
            </a>
          </div>
          <div tabindex="-1" @blur="cancalVis" >
            <div class="parallelogram-1">
              <div @click="visibleFac=!visibleFac"  class="flex items-center w-full h-full cursor-pointer" style="transform: skew(35deg);">
                <div class="flex-1" style="text-align: center;">{{ selectFactoryName }}</div>
                <CaretDownOutlined />
              </div>
            </div>
            <!-- selectFactory -->
            <div v-if="visibleFac" class="list-box" >
              <div class="mb-2">
                <a-input v-model:value="facName" placeholder="请输入工厂名称" @change="changeFacName" />
                
              </div>
              <div v-for="(item, index) in factoryList" :key="index">
                <div @click="selectFac(item)" class="list-info" :class="{'list-info-select': selectFactory === item.id }">
                  <span>{{ item.label }}</span>
                </div>
                <div v-if="item.children && item.children.length" >
                  <div v-for="(child, index2) in item.children" :key="index2">
                    <div @click="selectFac(child)" class="list-info" style="padding: 10px 40px;" :class="{'list-info-select': selectFactory === child.id}">
                      <span>{{ child.label }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          


        </div>
      </div>

      <a-modal
        :width="550"
        :maskClosable="false"
        v-model:visible="modalState.visible"
        title="修改密码"
        :confirm-loading="modalState.confirmLoading"
        @ok="changePassword"
      >
        <a-form
          :model="modalFormPwd"
          ref="formPasswordRef"
          name="basic"
          :rules="rules"
          autocomplete="off"
          :label-col="{ style: { width: '150px' } }"
        >
          <a-form-item label="原密码" name="oldPassword" required>
            <a-input
              type="password"
              v-model:value="modalFormPwd.oldPassword"
              placeholder="请输入原密码"
            />
          </a-form-item>
          <a-form-item label="新密码" name="password" required>
            <a-input
              type="password"
              v-model:value="modalFormPwd.password"
              placeholder="请输入新密码"
            />
          </a-form-item>
          <a-form-item label="再次输入新密码" name="checkPassword" required>
            <a-input
              type="password"
              v-model:value="modalFormPwd.checkPassword"
              placeholder="再次输入新密码"
            />
          </a-form-item>
        </a-form>
      </a-modal>


      <router-view v-if="roterAlive" />
    </div>
  </a-config-provider>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
// import Header from '@/components/layout/Header.vue';
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { memberStore, setMemberStore } from "@/common/state";
import { ConfigProvider } from "ant-design-vue";
import request from "../common/request";
import { icons } from "../common/icons";
import { v4 as uuidv4 } from "uuid";
import Icon from "@ant-design/icons-vue";
import { config } from "../../project.config";
import BScrollCore from "better-scroll-core";

import { langList } from "../common/lang";

import "dayjs/locale/zh-cn";
import moment from 'moment'
import { message } from "ant-design-vue";


moment.locale('zh-cn')

export default defineComponent({
  setup() {
    let member = ref<any>({});
    member.value = memberStore.member || {};
    const router = useRouter();
    const locale = zhCN.locale;
    let selectedKeys2 = ref<string[]>([]);
    let openKeys = ref<string[]>([]);

    const { currentRoute } = useRouter();
    let ifream = ref(false);
    const route = currentRoute.value;
    ifream.value = route.query.ifream ? true : false;

    const colorState = reactive({
      primaryColor: "#00aaa6",
      errorColor: "#ff4d4f",
      warningColor: "#faad14",
      successColor: "#52c41a",
      infoColor: "#1890ff",
    });

    ConfigProvider.config({
      theme: colorState,
    });

    const scrollBox1 = ref<any>();
    let bsInstance1 = ref<any>();
    let bsInstance2 = ref<any>();

    let selectMenu: any = ref("");
    let selectChilMenu: any = ref(null);
    let selectParentCode: any = ref(null);
    let childrenMenuList: any = ref([]);
    let facName: any = ref("");

    watch(
      () => router.currentRoute.value,
      (newValue: any) => {
        selectMenu.value = newValue.path;
        setTimeout(() => {
          getRouterSelect();
        }, 200);
      },
      { immediate: true }
    );
    const scrollBar = (type: any) => {
      let bor: any = document.getElementById("wrapper");
      if (type == "left") {
        var left: number = bor.scrollLeft + 200;
        if (
          left >
          Number(routerContentWidth.value) - Number(routerWidth.value)
        ) {
          left = Number(routerContentWidth.value) - Number(routerWidth.value);
        }
        bor.scrollTo({
          left: left,
          behavior: "smooth", // 平滑滚动效果
        });
      } else {
        var left2: number = bor.scrollLeft - 200;
        if (left2 < 0) {
          left2 = 0;
        }
        bor.scrollTo({
          left: left2,
          behavior: "smooth", // 平滑滚动效果
        });
      }
    };
    // 处理路由显示拆分
    const getRouterSelect = () => {
      if (!selectMenu.value) return;
      if (!menuList.value) return;

      let menuFirst = menuList.value.find(
        (p: any) => p.route === selectMenu.value
      );
      if (menuFirst) {
        selectParentCode.value = menuFirst.code;
      } else {
        menuList.value.map((d: any) => {
          if (
            d.children &&
            d.children.find((p: any) => p.route === selectMenu.value)
          ) {
            selectParentCode.value = d.code;
            childrenMenuList.value = d.children;
            selectChilMenu.value = d.children.find(
              (p: any) => p.route === selectMenu.value
            ).route;
          }
        });
      }
    };

    const linkTo = (code: any) => {
      let path = "";
      // selectMenu = path
      let menuInfo = menuList.value.find((p: any) => p.code === code);
      selectParentCode.value = menuInfo.code;
      childrenMenuList.value = menuInfo?.children || [];

      if (menuInfo.children && menuInfo.children.length) {
        path = menuInfo.children[0].route;
        selectChilMenu.value = menuInfo.children[0].route;
      } else {
        path = menuInfo.route;
      }

      if (path.indexOf("http") > -1) {
        router.push({ path: "/nonstandard", query: { url: path } });
      } else {
        router.push(path);
      }
    };

    const linkToChild = (path: any) => {
      selectChilMenu.value = path;
      menuList.value.map((d: any) => {
        if (d.children && d.children.find((p: any) => p.route === path)) {
          childrenMenuList.value = d.children;
        }
      });

      if (path.indexOf("http") > -1) {
        router.push({ path: "/nonstandard", query: { url: path } });
      } else {
        router.push(path);
      }
    };

    let contentHeight = window.innerHeight;

    const state = reactive({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });

    const resizeHandler = () => {
      state.windowWidth = window.innerWidth;
      state.windowHeight = window.innerHeight;
      changeWidth();
    };
    
    let langObj:any = ref({})
    let language:any = ref('Chinese')
    const getLang = () => {
      language.value = localStorage.getItem('language') || 'Chinese'
      langObj.value = langList[language.value]
    }

    let modalState = ref({
      visible: false,
      confirmLoading: false,
    });

    let logoutTimer: any = {};
    let logoutTimerClock = 30 * 60 * 1000;
    let isLogout:any = false;
    let picUrl:any = ref('1')
    let title:any = ref('')

    const loadfactorySetting = async() => {
      let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '{}')
      if(memberInfo?.factoryId) {
        let config2 = {
          params: {
              factoryId: memberInfo.factoryId
          },
          headers: {
              requestId: uuidv4(),
          },
        }
        let result = await request.get('/api/factory/setting', config2)


        if (result?.data?.autoLogout) {
          isLogout = true
          if (result.data.autoLogoutSecond > 0) {
            logoutTimerClock = result.data.autoLogoutSecond * 1000;
          }
        } else {
          logoutTimerClock = 300000 * 60 * 1000;
        }

        if (!memberInfo.pwdExpire) {
          message.warning("首次登录请修改密码！");
          modalState.value.visible = true;
          return;
        }

        if (result.data.pwdExpire) {
          if (memberInfo.pwdExpire) {
            if (
              new Date().getTime() - new Date(memberInfo.pwdExpire).getTime() >=
              0
            ) {
              message.warning("您的密码已经过期，请及时修改！");
              modalState.value.visible = true;
            }
          }
        }


      }
    }

    const getFactoryPT = async() => {
      if(!localStorage.getItem('factory_id')) {
        picUrl.value = null
        title.value = config.projectName
        document.title = title.value
        return
      } 

      let config2 = {
          params: {
              factoryId: localStorage.getItem('factory_id')
          },
          headers: {
              requestId: uuidv4(),
          },
        }
        let result = await request.get('/api/factory/setting', config2)

      picUrl.value = result?.data?.picture 
      title.value = result?.data?.title || config.projectName
      document.title = title.value


      if(picUrl.value) {
        let $favicon:any = document.querySelector('link[rel="icon"]');
        if ($favicon !== null) {
          $favicon.href = picUrl.value;
        } else {
          $favicon = document.createElement("link");
          $favicon.rel = "icon";
          $favicon.href = picUrl.value;
          document.head.appendChild($favicon);
        } 
      } else {
        let $favicon:any = document.querySelector('link[rel="icon"]');
        if ($favicon !== null) {
          $favicon.href = "/favicon.ico";
        } else {
          $favicon = document.createElement("link");
          $favicon.rel = "icon";
          $favicon.href = "/favicon.ico";
          document.head.appendChild($favicon);
        } 
      }
     

    }

    let resetLogoutTimer = () => {
      clearTimeout(logoutTimer);
      if(isLogout) {
        // 设置30分钟的定时器，超时后执行登出操作
        logoutTimer = setTimeout(() => {
          // 执行登出逻辑，例如调用登出接口或清除用户信息
          logOut();
        }, logoutTimerClock); // 30分钟，单位为毫秒
      }
    };

    let formPasswordRef = ref<any>();
    let modalFormPwd = ref({
      oldPassword: "",
      password: "",
      checkPassword: "",
    });
    const rules = {
      password: [
        {
          max: 22,
          min: 6,
          required: true,
          pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{12,}$/),
          message:
            "密码至少包含数字，大小写字母和字符的四种组合，长度在12-22之间",
        },
      ],
      checkPassword: [
        {
          max: 22,
          min: 6,
          required: true,
          pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{12,}$/),
          message:
            "密码至少包含数字，大小写字母和字符的四种组合，长度在12-22之间",
        },
      ],
    };

    let changePassword = () => {
      formPasswordRef.value.validate().then(async () => {
        if (modalFormPwd.value.password != modalFormPwd.value.checkPassword) {
          return message.warning("输入的新密码不一致!");
        }

        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        let result = await request.put(
          "/api/member/password",
          modalFormPwd.value,
          config
        );
        if (result && result.data) {
          message.success("修改成功!");
          modalState.value.visible = false;
        }
      });
    };

    onMounted(() => {
      let nameS = localStorage.getItem('userName')
      if(!nameS) {
        let member:any = localStorage.getItem('memberInfo') || null
        localStorage.setItem('userName', JSON.stringify(JSON.parse(member)?.userName))
      }

      window.addEventListener("resize", resizeHandler);
      // 监听用户操作事件，重置计时器
      window.addEventListener("keydown", resetLogoutTimer);
      window.addEventListener("mousedown", resetLogoutTimer);
      getLang()
      changeWidth();
      getMember()
    });

    onUnmounted(() => {
      window.removeEventListener("resize", resizeHandler);
      window.removeEventListener("keydown", resetLogoutTimer);
      window.removeEventListener("mousedown", resetLogoutTimer);
      bsInstance1.value = null;
      bsInstance2.value = null;
    });

    // 中英文切换
    const selectLang = (ev:any) => {
      language.value = ev
      localStorage.setItem('language', ev)
      
      roterAlive.value = false;
      setTimeout((t: any) => {
        roterAlive.value = true;
        getLang()
        changeWidth();
      }, 10);
    }

    const getLangColor = (ev:any) => {
      if(language.value == ev) {
        return '#3339b4'
      } else {
        return '#fff'
      }
    }

    const getMember = async() => {
      let result2 = await request.get("/api/members/info");
      localStorage.setItem('memberInfo', JSON.stringify(result2.data))
    }

    // 路由宽度
    let routerWidth = ref(0);
    let routerContentWidth = ref(0);
    let routerChildWidth = ref(0);
    let routerChildContentWidth = ref("");
    const changeWidth = () => {
      let Pwidth = window.innerWidth;
      if(language.value == 'Chinese') {
        routerWidth.value = Pwidth - 460 - 235 - 40 ;
      } else {
        routerWidth.value = Pwidth - 460 - 235 - 40 - 31;
      }
      
      routerChildWidth.value = Pwidth - 460 - 446 - 40 ;

      setTimeout(() => {
        let bor: any = document.getElementById("wrapper");
        bsInstance1.value = new BScrollCore(bor, {
          mouseWheel: true,
          scrollX: true,
        });
        let bor2: any = document.getElementById("wrapper2");
        bsInstance2.value = new BScrollCore(bor2, {
          mouseWheel: true,
          scrollX: true,
        });
      }, 200);
    };

    let roterAlive = ref(true);
    let menuList = ref();
    // member.menuIds
    const getMenu = () => {
      var menuIds = member.value.menus.map((t: any) => t.id);
      request.get("/api/menus").then((res: any) => {
        if (res) {
          res.data = res.data.filter((p: any) => p.status);
          var opend = res.data.filter((t: any) => t.route == route.path);
          if (opend && opend.length) {
            selectedKeys2.value = [opend[0].id];
            openKeys.value = [opend[0].id];
          }
          var mlist = ref<any>([]);
          var oneLevel = res.data.filter((t: any) => t.parentId == 0);
          oneLevel.forEach((t: any) => {
            var list = res.data
              .filter((t2: any) => t2.parentId == t.id)
              .filter((value: any) => menuIds.includes(value.id));
            if (list.length) {
              t.children = list;
              mlist.value.push(t);
            } else if (menuIds.includes(t.id)) {
              mlist.value.push(t);
            }
          });

          function compare(property: any) {
            return function (a: any, b: any) {
              var value1 = a[property];
              var value2 = b[property];
              return value1 - value2;
            };
          }
          mlist.value.sort(compare("sort"));

          mlist.value.map((d: any) => {
            if (d.children && d.children.length) {
              d.children.sort(compare("sort"));
            }
          });

          menuList.value = mlist.value;
          routerContentWidth.value = menuList.value.length * 273;
          getRouterSelect();
        }
      });
    };
    getMenu();
    let factoryIds = ref<any>([]);

    /**工厂切换 */
    const visible = ref(false);
    const onClose = () => {
      visible.value = false;
    };

    const showChange = () => {
      visible.value = true;
    };
    const logOut = () => {
      /**操作日志记录 */
      // operateLogs('退出登录');
      /**操作日志记录 */
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      localStorage.clear();
      setMemberStore(null);
      router.push("/login");
    };
    const getFactory = () => {
      const config = {
        params: {},
        headers: {
          requestId: uuidv4(),
        },
      };
      request.get("/api/factories", config).then((res: any) => {
        if (res) {
          if (!res?.data?.length) return;

          let factory_id = localStorage.getItem("factory_id");
          let info: any = {};
          if (factory_id) {
            info = res.data.find((p: any) => p._id === factory_id);
            if (!info) {
              info = res.data[0];
            }
          } else {
            info = res.data[0];
          }
          selectFactory.value = info._id;
          selectFactoryName.value = info.factoryName;
          localStorage.setItem("factory_id", selectFactory.value);
          roterAlive.value = false;
          setTimeout(() => {
            roterAlive.value = true;
          }, 10);

          if (
            (res.data && res.data.length === 1) ||
            (res.data && res.data.length === 0)
          ) {
            visibleFactory.value = false;
          } else {
            visibleFactory.value = true;
          }

          // 2级工厂
          var list = res.data.map((t: any) => ({
            key: t._id,
            title: t.factoryName,
            value: t._id,
            label: t.factoryName,
            parentId: t.parentId,
            id: t._id,
            type: "factory",
          }));
          let factory = [];
          for (var i = 0; i < list.length; i++) {
            var element = list[i];
            if (!element.parentId) {
              // factoryId = element.id
              const children = list.filter(
                (t: any) => t.parentId == element.id
              );
              if (children && children.length) {
                element.children = children;
              }
              factory.push(element);
            }
          }
          factoryListAll.value = factory;

          changeFacName("");

          if (localStorage.getItem("currentFactory")) {
            factoryIds.value = JSON.parse(
              localStorage.getItem("currentFactory") || ""
            );
          }

          getFactoryPT()
          loadfactorySetting();
        }
      });
    };
    getFactory();
    let factoryListAll = ref<any>([]);
    let factoryList = ref<any>([]);
    let visibleFactory = ref(false); //是否显示工厂选择

    const changeFacName = (ev: any) => {
      let list = JSON.parse(JSON.stringify(factoryListAll.value));
      factoryList.value = [];
      if (facName.value) {
        list.map((d: any) => {
          if (d.children && d.children.length) {
            let list1 = d.children.filter((p: any) =>
              p.label.includes(facName.value)
            );
            if (list1.length) {
              d.children = list1;
            }
            factoryList.value.push(d);
          } else {
            if (d.label.includes(facName.value)) {
              factoryList.value.push(d);
            }
          }
        });
      } else {
        factoryList.value = list;
      }
    };

    const factoryChange = (val: any) => {
      factoryIds.value = val;
      if (val) {
        localStorage.setItem("currentFactory", JSON.stringify(val));
      } else {
        localStorage.removeItem("currentFactory");
      }

      roterAlive.value = false;
      setTimeout(() => {
        roterAlive.value = true;
      }, 10);
      // router.push({
      //   path:router.currentRoute.value.path+"?t="+new Date().getTime()
      // })
    };

    let selectFactory: any = ref("");
    let selectFactoryName: any = ref("");
    let visibleFac: any = ref(false);
    const cancalVis = (ev: any) => {
      if (!ev.relatedTarget) {
        visibleFac.value = false;
      }
    };

    const selectFac = (ev: any) => {
      selectFactory.value = ev.id;
      selectFactoryName.value = ev.label;
      localStorage.setItem("factory_id", ev.id);
      let version = '1';
      if(ev.version){
        version = String(ev.version)
      }
      localStorage.setItem("factory_version", version);
      visibleFac.value = false;

      roterAlive.value = false;
      setTimeout((t: any) => {
        roterAlive.value = true;
        getFactoryPT()
      }, 10);
    };

    return {
      factoryIds,
      showChange,
      visible,
      onClose,
      selectedKeys: ref<string[]>(["1"]),
      collapsed: ref<boolean>(false),
      selectedKeys2,
      openKeys,
      linkTo,
      locale,
      enUS,
      zhCN,
      member,
      contentHeight,
      state,
      menuList,
      icons,
      ifream,
      logOut,
      factoryList,
      factoryChange,
      roterAlive,
      config,
      visibleFactory,
      selectMenu,
      selectChilMenu,
      childrenMenuList,
      linkToChild,
      selectParentCode,
      scrollBox1,
      routerWidth,
      routerContentWidth,
      routerChildContentWidth,
      routerChildWidth,
      cancalVis,
      visibleFac,
      selectFactory,
      selectFac,
      selectFactoryName,
      facName,
      changeFacName,
      scrollBar,
      selectLang,
      langObj,
      language,
      getLangColor,
      modalState,
      changePassword,
      modalFormPwd,
      rules,
      formPasswordRef,
      picUrl,
      title
    };
  },
});
</script>

<style scoped lang="less">
.leftbtn {
  position: absolute;
  left: -24px;
  top: 7px;
  width: 30px;
  height: 30px;
  z-index: 999;
  cursor: pointer;
  font-size: 28px;
}
.rightbtn {
  position: absolute;
  top: 7px;
  right: 0px;
  width: 30px;
  height: 30px;
  z-index: 999;
  cursor: pointer;
  font-size: 28px;
}
/deep/ .mycasc .ant-select-selector {
  background-color: #444 !important;
  color: white !important;
  border: #444 !important;
}
/deep/ .mycasc .ant-select-arrow {
  color: white;
}
/deep/ .mycasc .ant-select-clear {
  color: white;
  background: #444;
}
.ant-pro-setting-drawer-handle {
  position: absolute;
  top: 240px;
  right: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  padding-left: 10px;
  padding-right: 10px;
  height: 48px;
  font-size: 16px;
  text-align: center;
  background: #00aaa6;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  pointer-events: auto;
  color: white;
}

.item-center {
  position: absolute;
  right: 0;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 12px;
  cursor: pointer;
  transition: color 0.3s;
  color: white;
}

.plantName {
  font-size: 18px;
  line-height: 50px;
  padding: 10px 10px;
  transition: color 0.3s;
  color: white;
}

.trigger:hover {
  color: #00aaa6;
}

.logo {
  height: 54px;
  color: #00aaa6;
  font-weight: 600;
  font-size: 22px;
  /* padding: 4px 0 0 4px */
}

.logoText {
  height: 30px;
  margin: 16px;
  color: #00aaa6;
  font-weight: 600;
  font-size: 22px;
  margin-top: 20px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.parallelogram {
  transform: skew(-20deg);
}

.parallelogram-1 {
  position: absolute;
  right: -9px;
  bottom: 0px;
  width: 425px;
  height: 30px;
  border: 1px solid #375faa;
  background: linear-gradient(180deg, #222688 0%, #242984 100%);
  transform: skew(-35deg);
  padding: 0 30px;
}

.list-box {
  height: 500px;
  width: 400px;
  background: #222688;
  color: #fff;
  position: absolute;
  top: 110px;
  left: -185px;
  z-index: 9999;
  overflow: auto;

  .list-info {
    border-bottom: 1px solid rgb(75 85 99);
    padding: 10px 20px;
    cursor: pointer;
  }

  .list-info-select {
    background-color: #64748b;
  }
}

.parallelogram-select {
  border-right: 1px solid;
  border-left: 1px solid;
  border-image: linear-gradient(180deg, transparent 0%, #7e9cd2 100%) 2 2 2 2;
  border-bottom: 1px solid #7e9cd2;
  transform: skew(-30deg);
  background: linear-gradient(180deg, #222688 0%, #3339b4 100%);
  cursor: pointer;
}

.parallelogram-2 {
  border-right: 1px solid;
  border-left: 1px solid;
  border-image: linear-gradient(180deg, #7e9cd2 0%, transparent 100%) 2 2 2 2;
  border-top: 1px solid #7e9cd2;
  transform: skew(-30deg);
  background: linear-gradient(180deg, #222688 0%, #242984 100%);
  cursor: pointer;
}

.header-logo {
  min-width: 460px;
  height: 100px;
  background-image: url("@/assets/header-1.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.header-logo-txt {
  color: #ffffff;
  font-size: 28px;
  font-weight: 700;
}

.header-v {
  position: absolute;
  bottom: 0px;
  right: 62px;
}

.text-line {
  width: 20px;
  height: 5px;
  margin-left: 25px;
}
</style>
