<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
  <div>
    <a-layout-content style="padding: 0 20px">
      <a-row type="flex">
        <a-col :span="24" :order="2">
          <a-form layout="inline">
            <a-form-item :label="langObj['项目']">
              <a-select v-model:value="searchModel.factoryId" style="width: 200px;" :dropdownMatchSelectWidth="false" @change="selectFactorySearch"
                allowClear :placeholder="langObj['请选择项目']" >
                <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{ item.factoryName
                }}</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item :label="langObj['区域']">
              <a-select v-model:value="searchModel.groupId" style="width: 200px;" :dropdownMatchSelectWidth="false" @change="selectGroupSearch"
                allowClear :placeholder="langObj['请选择']" >
                <a-select-option v-for="(item, index) in groupSearchList" :key="index" :value="item.id">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item :label="langObj['设备']">
              <a-select v-model:value="searchModel.machineId" style="width: 200px;" :dropdownMatchSelectWidth="false" allowClear :placeholder="langObj['请选择设备']">
                <a-select-option v-for="(item, index) in machineSearchList" :key="index" :value="item.id">{{
                  item.machineName }}</a-select-option>
              </a-select>
            </a-form-item>

            <!-- <a-form-item  label="事件进展">
              <a-select
                v-model:value="searchModel.status"
                style="width: 150px;"
                :options="statusList"
                allowClear
              ></a-select>
            </a-form-item> -->

            <a-form-item :label="langObj['发生时间']">
              <a-range-picker v-model:value="searchModel.date" style="width: 400px" show-time format="YYYY/MM/DD HH:mm:ss"
                allowClear  :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
            </a-form-item>

            <a-form-item label="诊断经理">
              <a-select allowClear :showSearch="true" v-model:value="searchModel.diagnosticManager" style="width: 180px">
                  <a-select-option v-for="(item, index) in diagnosticManagerList" :key="index" :value="item._id">{{ item.nickName }}</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="客户经理">
              <a-select allowClear :showSearch="true" v-model:value="searchModel.accountManager" style="width: 180px">
                  <a-select-option v-for="(item, index) in accountManagerList" :key="index" :value="item._id">{{ item.nickName }}</a-select-option>
              </a-select>
            </a-form-item>



            <a-form-item>
              <a-button type="primary" @click="searchModel.skip=1, search()">{{langObj['查询']}}</a-button>
              <a-button type="primary" @click="add" class="ml-4">
                <template #icon><plus-outlined /></template>{{langObj['新增']}}</a-button>
            </a-form-item>
          </a-form>

          <div class="w-full flex items-center mt-4">
            <div style="margin-left: 50px; font-size: 16px; padding: 0 10px 5px 10px; cursor: pointer"
              :class="{ 'select-tag': selectTag == 0 }" @click="tagSelect(0)">
              {{langObj['诊断工单(待完成)']}}
            </div>
            <div style="margin-left: 50px; font-size: 16px; padding: 0 10px 5px 10px; cursor: pointer"
              :class="{ 'select-tag': selectTag == 1 }" @click="tagSelect(1)">
              {{langObj['诊断工单(已完成)']}}
            </div>



            <div class="flex-1"></div>
            <div class="flex items-center event-btns">
              <a-button @click="sure" :disabled="isCommit">{{langObj['确认提交']}}</a-button>
              <a-button @click="cancelClick" class="ml-2">{{langObj['取消选择']}}</a-button>
            </div>
           
          </div>

          <div class="main">
            <a-table bordered :emptyText="'暂无数据'" :columns="columns" :data-source="dataList" :pagination="pagination"
              :loading="loading" @change="handleTableChange" size="small" :row-class-name="getRowClassName" >
              <template #bodyCell="{ column, text, record, index }">
                <template v-if="column.dataIndex === 'factoryName'"> {{ factoryAll.find(p => p._id ===
                  record.factoryId)?.factoryName }} </template>
                <template v-if="column.dataIndex === 'machineName'"> 
                  <div v-if="record.cancel">
                    <del>{{ text }}</del>
                  </div>
                  <div v-else>{{ text }}</div>
                </template>
                <template v-if="column.dataIndex === 'condition'"> {{ langObj[conditionList.find(p => p.value === text)?.label] }}
                </template>
                <template v-if="column.dataIndex === 'prevCondition'"> {{ langObj[conditionList.find(p => p.value === text)?.label]
                }} </template>
                <template v-if="column.dataIndex === 'changed'"> {{ langObj[switchList.find(p => p.value === text)?.label] }}
                </template>
                <template v-if="column.dataIndex === 'display'"> {{ langObj[switchList.find(p => p.value === text)?.label] }}
                </template>

                <template v-if="column.dataIndex === 'expertDiagnosisResult'">
                  <a-select v-if="record.status !== 3" v-model:value="record.expertDiagnosisResult" style="width: 100%"
                    :options="conditionList" @change="verifySure(index, record)"></a-select>

                  <span v-if="record.status === 3">{{ conditionList.find(p => p.value ===
                    record.expertDiagnosisResult)?.label }}</span>
                </template>

                <template v-if="column.dataIndex === 'expertProcess'">
                  <a-select v-if="record.status !== 3" v-model:value="record.expertProcess" style="width: 100%"
                    :options="expertProcessList" @change="verifySure(index, record)"></a-select>
                  <span v-if="record.status === 3">{{ expertProcessListAll.find(p => p.value === record.expertProcess)?.label
                  }}</span>
                </template>

                <template v-if="column.dataIndex === 'status'"> 
                  <div v-if="record.cancel">{{langObj['已处理']}}</div>
                  <div v-else>{{ statusList.find(p => p.value === text)?.label }}</div>
                  
                </template>

                <template v-if="column.dataIndex === 'select'">
                  <input v-if="!record.cancel && record.status !== 3" type="checkbox"
                    style="width: 16px; height: 16px; cursor: pointer" v-model="record.isClick"
                    @change="verifySure(index, record)" />
                </template>

                <template v-if="column.dataIndex === 'act'">
                  <a @click="viewDetail(record)">{{langObj['查看']}}</a>
                  <a-divider v-if="admin"  type="vertical" />
                  <a-popconfirm
                    v-if="admin" 
                    :title="langObj['确认删除']+'？'"
                    :ok-text="langObj['确认']"
                    :cancel-text="langObj['取消']"
                    @confirm="deleteOrder(record)"
                  >
                    <a>{{langObj['删除']}}</a>
                  </a-popconfirm>
                </template>

              </template>
            </a-table>
          </div>
        </a-col>
      </a-row>
    </a-layout-content>


    <!-- 新增工单 -->
    <a-modal :width="1500" v-model:visible="visible" :title="langObj['新增']" :footer="null" :maskClosable="false">

      <a-table ref="addTable" style="background-color: transparent" :columns="addColumns" :data-source="form.list" :pagination="false">
        <template #bodyCell="{ column, record }" size="small">
          <template v-if="column.dataIndex === 'factoryId'">
            <a-select v-model:value="record.factoryId" style="min-width: 150px;" @change="selectFactory">
              <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{ item.factoryName
              }}</a-select-option>
            </a-select>
          </template>

          <template v-if="column.dataIndex === 'machineId'">
            <a-select v-model:value="record.machineId" style="min-width: 150px;" @change="selectMachine">
              <a-select-option v-for="(item, index) in machineList" :key="index" :value="item.id">{{ item.machineName
              }}</a-select-option>
            </a-select>
          </template>

          <template v-if="column.dataIndex === 'sensorId'">
            <a-select v-model:value="record.sensorId" style="min-width: 150px;">
              <a-select-option v-for="(item, index) in sensorList" :key="index" :value="item.id">{{ item.sensorPlace
              }}</a-select-option>
            </a-select>
          </template>

          <template v-if="column.dataIndex === 'scoreName'">
            <a-select v-model:value="record.scoreName" style="min-width: 150px;">
              <a-select-option v-for="(item, index) in faultList" :key="index" :value="item.column">{{ item.title
              }}</a-select-option>
            </a-select>
          </template>

          <template v-if="column.dataIndex === 'date'">
            <a-date-picker v-model:value="record.date" show-time :placeholder="langObj['请选择日期']"/>
          </template>

          <template v-if="column.dataIndex === 'condition'">
            <span>{{ conditionList.find(p => p.value === record.condition)?.label }}</span>
          </template>

          <template v-if="column.dataIndex === 'expertDiagnosisResult'">
            <a-select v-model:value="record.expertDiagnosisResult" style="min-width: 80px;">
              <a-select-option v-for="(item, index) in conditionList" :key="index" :value="item.value">{{ item.label
              }}</a-select-option>
            </a-select>
          </template>

          <template v-if="column.dataIndex === 'expertProcess'">
            <a-select v-model:value="record.expertProcess" style="min-width: 100px;">
              <a-select-option v-for="(item, index) in expertProcessList" :key="index" :value="item.value">{{ item.label
              }}</a-select-option>
            </a-select>
          </template>

          <template v-if="column.dataIndex === 'display'">
            <a-select v-model:value="record.display" style="width: 100%">
              <a-select-option v-for="(item, index) in switchList" :key="index" :value="item.value">{{ item.label
              }}</a-select-option>
            </a-select>
          </template>


        </template>
      </a-table>


      <a-form style="margin-top: 20px" :model="form" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item :label="langObj['诊断判断备注']" name="diagnosticNotes" :rules="[{ required: true, message: langObj['请填写诊断判断备注'] }]">
          <a-textarea v-model:value="form.diagnosticNotes" placeholder="" :rows="4" />
        </a-form-item>

        <a-form-item :label="langObj['专家报告id']" name="专家报告id">
          <a-input v-model:value="form.reportId" placeholder="" readonly />
        </a-form-item>

        <a-form-item :wrapper-col="{ offset: 4, span: 20 }">
          <a-button @click="addSubmit" style="background-color: #fff;" :loading="subLoading">{{langObj['提交']}}</a-button>
          <a-button @click="addReport" class="ml-4" type="primary">{{langObj['添加专家报告']}}</a-button>
        </a-form-item>
      </a-form>
    </a-modal>


    <!-- 新增专家报告 -->
    <a-modal width="800px" v-model:visible="visibleAddRreport" :title="langObj['新增']" :footer="null" :maskClosable="false" :zIndex="1001">
      <addReportComponent :reportMachineInfo="reportMachineInfo" :reportCreateInfo="reportCreateInfo" @sendId="getReportId"></addReportComponent>
    </a-modal>

    <!-- 诊断去处理 -->
    <a-modal :width="1300" v-model:visible="visibleEdit" :title="langObj['诊断处理']" :footer="null" :maskClosable="false">
      <a-form style="margin-top: 20px" :model="editForm" name="basic" :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }">
        <a-form-item :label="langObj['诊断判断内部备注']" name="internalNotes" :rules="[{ required: true, message: langObj['请填写'] }]">
          <a-select
            v-model:value="editForm.internalNotes"
            style="width: 100%"
            :options="internalNotesList.map(item => ({ value: item }))"
          >
            <template #dropdownRender="{ menuNode: menu }">
            <v-nodes :vnodes="menu" />
            <a-divider style="margin: 4px 0" />
            <div
                style="padding: 4px 8px; cursor: pointer"
                @mousedown="e => e.preventDefault()"
                @click="addinternalNote()"
            >
                <div class="flex items-center" style="color: #000">
                    <plus-outlined />
                    添加
                </div>
            </div>
            </template>
          </a-select>
        </a-form-item>

        <a-form-item :label="langObj['诊断判断备注']" name="diagnosticNotes" :rules="[{ required: true, message: langObj['请填写诊断判断备注'] }]">
          <a-textarea v-model:value="editForm.diagnosticNotes" placeholder="" :rows="4" />
        </a-form-item>

        <a-form-item :label="langObj['专家报告id']" name="reportId">
          <a-input v-model:value="editForm.reportId" placeholder="" readonly />
        </a-form-item>

        <a-form-item :label="langObj['是否前台显示']" name="display">
          <a-radio-group v-model:value="editForm.display">
            <a-radio :value="true">{{langObj['是']}}</a-radio>
            <a-radio :value="false">{{langObj['否']}}</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item :wrapper-col="{ offset: 4, span: 20 }">
          <a-button @click="editSubmit" :loading="subLoading">{{langObj['保存任务处理']}}</a-button>
          <a-button @click="addEditReport" class="ml-4" type="primary">{{langObj['添加专家报告']}}</a-button>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 查看 -->
    <a-modal :width="1300" v-model:visible="visibleView" :title="langObj['查看']" :footer="null" :maskClosable="false">
      <a-table ref="viewTable" :columns="viewColumns" :data-source="viewForm.list" :pagination="false">
        <template #bodyCell="{ column, record, text }" size="small">
          <template v-if="column.dataIndex === 'factoryId'">
            <span>{{ factoryAll.find(p => p._id === text)?.factoryName }}</span>
          </template>

          <template v-if="column.dataIndex === 'condition'">
            <span>{{langObj[conditionList.find(p => p.value === text)?.label]  }}</span>
          </template>

          <template v-if="column.dataIndex === 'expertDiagnosisResult'">
            <span>{{ langObj[conditionList.find(p => p.value === text)?.label]  }}</span>
          </template>

          <template v-if="column.dataIndex === 'expertProcess'">
            <span>{{ langObj[expertProcessList.find(p => p.value === text)?.label]  }}</span>
          </template>

          <template v-if="column.dataIndex === 'display'">
            <span>{{ langObj[switchList.find(p => p.value === text)?.label]  }}</span>
          </template>

        </template>
      </a-table>

      <a-form style="margin-top: 20px" :model="viewForm" name="basic" :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }">
        <a-form-item :label="langObj['诊断判断内部备注']" name="internalNotes">
          <a-textarea readonly v-model:value="viewForm.internalNotes" placeholder=" " :rows="4" />
        </a-form-item>

        <a-form-item :label="langObj['诊断判断备注']" name="diagnosticNotes">
          <a-textarea readonly v-model:value="viewForm.diagnosticNotes" placeholder=" " :rows="4" />
        </a-form-item>

        <a-form-item :label="langObj['专家报告id']" name="专家报告id">
          <a-input v-model:value="viewForm.reportId" placeholder="" readonly />
        </a-form-item>
      </a-form>

    </a-modal>

    <!-- 新增诊断判断内部备注 -->
    <a-modal :width="400" :maskClosable="false" v-model:visible="addIVisible" title="新增诊断判断内部备注" @ok="submitAddInternalNote" >
        <a-input style="width: 350px" v-model:value="addIString" />
    </a-modal>

  </div>
</a-config-provider>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { transformDate, getGroupListByFacID, getGroupName } from "../../common/tools";
import { useRouter } from "vue-router";
import { computed, defineComponent, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import { message, TableProps } from "ant-design-vue";
import { v4 as uuidv4 } from "uuid";
import addReportComponent from "../report4/diagnosticReport-add/diagnosticReport-add.vue";
import { Dayjs } from 'dayjs';
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
let langObj: any = ref({})
    let language: any = ref('Chinese')
    const getLang = () => {
        language.value = localStorage.getItem('language') || 'Chinese'
        langObj.value = langList[language.value]
    }
    getLang()

let conditionList = [
  { label: langObj.value['健康'], value: 0 },
  { label: langObj.value['可用上'], value: 1 },
  { label: langObj.value['可用下'], value: 2 },
  { label: langObj.value['警戒上'], value: 3 },
  { label: langObj.value['警戒中'], value: 4 },
  { label: langObj.value['警戒下'], value: 5 },
  { label: langObj.value['故障上'], value: 6 },
  { label: langObj.value['故障下'], value: 7 },
  { label: ' ', value: -1 },
]

let switchList = [
  { label: langObj.value['是'], value: true },
  { label: langObj.value['否'], value: false },
]

let expertProcessList = [
  { label: langObj.value['调整阈值'], value: 0 },
  { label: langObj.value['专家报告'], value: 1 },
  { label: langObj.value['其他'], value: 3 },
  { label: langObj.value['未处理'], value: -1 }
]

let expertProcessListAll = [
  { label: langObj.value['调整阈值'], value: 0 },
  { label: langObj.value['专家报告'], value: 1 },
  { label: langObj.value['状态清零'], value: 2 },
  { label: langObj.value['其他'], value: 3 },
  { label: langObj.value['未处理'], value: -1 }
]

let statusList = [
  { label: langObj.value['诊断待处理'], value: 0 },
  { label: langObj.value['完成'], value: 3 },
]

let internalNotesList:any = ref([
  '开关机异常',
  '偶然冲击',
  '工况干扰',
  '数据清洗',
  '阈值问题',
  '通用指标不合理',
  '通用指标有问题',
  '专业指标不合理',
  '转频提取错误',
  '特征提取错误',
  '信号处理',
  '硬件采集问题',
  '硬件位置异常',
  '程序错误',
])

const VNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true,
    },
  },
  render() {
    return this.vnodes;
  },
});

let addIString:any = ref('')
let addIVisible = ref(false)  //新增诊断判断内部备注

const router = useRouter();
const loading = ref(false);
const formRef = ref();
let visible = ref(false)
let visibleEdit = ref(false)
let visibleAddRreport = ref(false)
let visibleView = ref(false)
let reportMachineInfo: any = ref(null)
let isCommit = ref(true)
let form: any = ref({
  list: [{
    factoryId: '',
    machineId: '',
    sensorId: '',
    scoreName: '',
    date: null,
    condition: null,
    expertDiagnosisResult: null,
    expertProcess: null,
    display: false,
  }],
  diagnosticNotes: '',
  reportId: '',
})

let viewForm: any = ref({
  list: [{
    factoryId: '',
    machineName: '',
    sensorPlace: '',
    scoreName: '',
    date: null,
    condition: null,
    expertDiagnosisResult: null,
    expertProcess: null,
    display: false,
  }],
  diagnosticNotes: '',
  reportId: '',
})

let editSelectOrder: any = ref([])
let editForm: any = ref({
  diagnosticNotes: '',
  reportId: '',
  display: false,
})

let factoryList: any = ref([])
let machineList: any = ref([])
let machineSearchList: any = ref([])
let groupSearchList: any = ref([])
let sensorList: any = ref([])
let faultList: any = ref([])

const searchModel: any = ref({
  skip: 1,
  take: 20,
  total: 0,
  factoryId: null,
  machineId: null,
  date: null,
  sorter: true,
});

const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
  showSizeChanger: true,
}));

let dataList: any = ref([]);
const columns = [
  {
    title: langObj.value["项目"],
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: langObj.value["区域"],
    dataIndex: "groupName",
    align: "center"
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
    align: "center"
  },
  {
    title: langObj.value["测点"],
    dataIndex: "sensorPlace",
    align: "center"
  },
  {
    title: langObj.value["指标名称"],
    dataIndex: "faultName",
    align: "center"
  },
  {
    title: langObj.value["发生时间"],
    dataIndex: "date",
    defaultSortOrder: 'ascend',
    sorter: true,
    align: "center"
  },
  {
    title: langObj.value["内部事件类型"],
    dataIndex: "createBy",
    align: "center"
  },
  {
    title: langObj.value["当前状态"],
    dataIndex: "condition",
    align: "center"
  },
  {
    title: langObj.value["上一小时稳定状态"],
    dataIndex: "prevCondition",
    align: "center"
  },
  {
    title: langObj.value["是否改变状态"],
    dataIndex: "changed",
    align: "center"
  },
  // {
  //   title: "是否前台显示",
  //   dataIndex: "display",
  // },
  {
    title: langObj.value["稳定状态"],
    dataIndex: "expertDiagnosisResult",
    align: "center"
  },
  {
    title: langObj.value["诊断处理"],
    dataIndex: "expertProcess",
    align: "center"
  },
  {
    title: langObj.value["事件进展"],
    dataIndex: "status",
    align: "center"
  },
  {
    title: langObj.value["选择"],
    dataIndex: "select",
    align: "center"
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
    align: "center"
  },
];

const addColumns = [
  {
    title: langObj.value["项目"],
    dataIndex: "factoryId",
    align: "center"
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineId",
    align: "center"
  },
  {
    title: langObj.value["测点"],
    dataIndex: "sensorId",
    align: "center"
  },
  {
    title: langObj.value["指标名称"],
    dataIndex: "scoreName",
    align: "center"
  },
  {
    title: langObj.value["发生时间"],
    dataIndex: "date",
    align: "center"
  },
  {
    title: langObj.value["当前状态"],
    dataIndex: "condition",
    align: "center"
  },
  {
    title: langObj.value["稳定状态"],
    dataIndex: "expertDiagnosisResult",
    align: "center"
  },
  {
    title: langObj.value["诊断处理"],
    dataIndex: "expertProcess",
    align: "center"
  },
  {
    title: langObj.value["是否前台显示"],
    dataIndex: "display",
    align: "center"
  },
]

const viewColumns = [
  {
    title: langObj.value["项目"],
    dataIndex: "factoryId",
    align: "center"
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
    align: "center"
  },
  {
    title: langObj.value["测点"],
    dataIndex: "sensorPlace",
    align: "center"
  },
  {
    title: langObj.value["指标名称"],
    dataIndex: "scoreName",
    align: "center"
  },
  {
    title: langObj.value["发生时间"],
    dataIndex: "date",
    align: "center"
  },
  {
    title: langObj.value["当前状态"],
    dataIndex: "condition",
    align: "center"
  },
  {
    title: langObj.value["稳定状态"],
    dataIndex: "expertDiagnosisResult",
    align: "center"
  },
  {
    title:langObj.value[ "诊断处理"],
    dataIndex: "expertProcess",
    align: "center"
  },
  {
    title: langObj.value["是否前台显示"],
    dataIndex: "display",
    align: "center"
  },
]

let selectTag = ref(0)  //0: 待完成 1: 已完成
let factoryAll: any = ref([])
let reportCreateInfo:any = ref(null)
let diagnosticManagerList:any = ref([])
let accountManagerList:any = ref([])

onMounted(() => {
  init()
})

const init = () => {
  getManagers()
  getAdmin()
  search()
  getFactoryList()
}

// 获取诊断/客户经理列表
const getManagers = async() => {
    const config = {
        params: {
            factoryId: "65e29561f747d7e32bbed049",
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let resultRole = await request.get("/api/roles", config)
    let rolelist:any = []
    if(resultRole?.data?.list) {
        rolelist = resultRole.data.list
    }
    let diagnosticManagerRoleId = rolelist.find((p:any) => p.name === '诊断工程师')?.id
    let accountManagerRoleId = rolelist.find((p:any) => p.name === '客户支持')?.id

    if(diagnosticManagerRoleId) {
        let config2 = {
            params: {
                roleId: diagnosticManagerRoleId,
                factoryId: "65e29561f747d7e32bbed049",
            },
            headers: {
                requestId: uuidv4(),
            },
        };
        let result2 = await request.get("/api/members", config2)
        if(result2?.data?.list) {
            diagnosticManagerList.value = result2.data.list
        }
    }

    if(accountManagerRoleId) {
        let config2 = {
            params: {
                roleId: accountManagerRoleId,
                factoryId: "65e29561f747d7e32bbed049",
            },
            headers: {
                requestId: uuidv4(),
            },
        };
        let result2 = await request.get("/api/members", config2)
        if(result2?.data?.list) {
            accountManagerList.value = result2.data.list
        }
    }
}

let admin = ref(false)
const getAdmin = () => {
    let member = JSON.parse(localStorage.getItem('memberInfo') || '')
    admin.value = member?.admin
}

// 获取工厂列表
const getFactoryList = async () => {
  let result = await request.get('/api/factories')
  if (result && result.data) {
    factoryAll.value = result.data
    let parentIds = result.data.map((p: any) => p.parentId)
    parentIds = [...new Set(parentIds)]
    let list = result.data.filter((p: any) => !parentIds.includes(p._id))
    let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
    list = list.filter((p: any) => memberInfo.factoryIds.includes(p._id))
    factoryList.value = list
  }
}

// 获取查询设备列表
const selectFactorySearch = async (ev: any) => {
  if (ev) {
    groupSearchList.value = getGroupListByFacID(ev)
    console.log('groupSearchList', groupSearchList.value)
  } else {
    groupSearchList.value = []
  }
  searchModel.value.groupId = null
  searchModel.value.machineId = null
}

// 选择查询区域
const selectGroupSearch = async (ev:any) => {
  if (ev) {
    const config = {
      params: {
        factoryId: searchModel.value.factoryId,
        groupId: searchModel.value.groupId,
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.get('/api/machines', config)
    if (result && result.data) {
      machineSearchList.value = result.data
    } else {
      machineSearchList.value = []
    }
  } else {
    machineSearchList.value = []
  }
}

const tagSelect = (ev: any) => {
  searchModel.value.skip = 1
  selectTag.value = ev
  search()
}

const handleTableChange = (ev: any, a: any, sorter: any) => {
  searchModel.value = {
    ...searchModel.value,
    skip: ev.current,
    take: ev.pageSize,
  }
  if (sorter.order && sorter.field) {
    searchModel.value.sorter = sorter.order === 'descend' ? false : true
  }
  search()
}

// 查询
const search = async () => {
  if (searchModel.value.date && searchModel.value.date.length === 2) {
    searchModel.value.begin = transformDate(searchModel.value.date[0])
    searchModel.value.end = transformDate(searchModel.value.date[1])
  } else {
    searchModel.value.begin = ''
    searchModel.value.end = ''
  }

  const config = {
    params: {
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      factoryId: searchModel.value.factoryId,
      machineId: searchModel.value.machineId,
      status: selectTag.value === 0 ? 0 : 3,
      begin: searchModel.value.begin,
      end: searchModel.value.end,
      sorter: searchModel.value.sorter,
      diagnosticManager: searchModel.value.diagnosticManager,
      accountManager: searchModel.value.accountManager,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get('/api/workflows/info', config)
  if (result && result.data) {
    dataList.value = result.data.list
    let indexMD = 0
    let dataMD = ''
    dataList.value.map((d:any) => {
      d.groupName = getGroupName(d.groupId)
      let md = d.machineId + d.date
      if(dataMD === md) {
        d.indexMD = indexMD
      } else {
        indexMD ++
        d.indexMD = indexMD
        dataMD = md
      }
    })
    // searchModel.value.skip = 1
    searchModel.value.total = result.data.total
  }
};


// 新增
const add = () => {
  form.value = {
    list: [{
      factoryId: '',
      machineId: '',
      sensorId: '',
      scoreName: '',
      date: null,
      condition: null,
      expertDiagnosisResult: null,
      expertProcess: null,
      display: false,
    }],
    diagnosticNotes: '',
    reportId: '',
  }
  visible.value = true;
};

// 选择工厂
const selectFactory = async (ev: any) => {
  const config = {
    params: {
      factoryId: ev,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/machines', config)
  if (result && result.data) {
    machineList.value = result.data
  } else {
    machineList.value = []
  }
}

// 选择设备
const selectMachine = async (ev: any) => {
  const config = {
    params: {
      machineId: ev,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/sensors', config)
  if (result && result.data) {
    sensorList.value = result.data
  } else {
    sensorList.value = []
  }


  let result2 = await request.get('/api/machines/faultnames', config)
  if (result2 && result2.data) {
    faultList.value = result2.data.faultNameExpects.concat(result2.data.faultNames)
  } else {
    faultList.value = []
  }

  // 获取当前状态
  const configS = {
    params: {
      id: ev,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result3 = await request.get('/api/external/machines/workstatus/info', configS)
  if (result3 && result3.data && result3.data.workStableStatus && result3.data.workStableStatus.condition) {
    form.value.list[0].condition = result3.data.workStableStatus.condition
  } else {
    form.value.list[0].condition = 0
  }
}

// 添加专家报告
const addReport = () => {
  if (!form.value.list[0].factoryId) {
    message.warning('请选择项目')
    return
  }
  if (!form.value.list[0].machineId) {
    message.warning('请选择设备')
    return
  }
  if (!form.value.list[0].sensorId) {
    message.warning('请选择测点')
    return
  }

  let machine = machineList.value.find((p: any) => p.id === form.value.list[0].machineId)
  machine.sensorId = form.value.list[0].sensorId
  reportMachineInfo.value = machine
  visibleAddRreport.value = true
}

// 获取专家报告Id
const getReportId = (ev: any) => {
  if (ev) {
    form.value.reportId = ev
    editForm.value.reportId = ev
    visibleAddRreport.value = false
  }
}

// 提交
let subLoading:any = ref(false)
const addSubmit = async () => {
  if (!form.value.list[0].factoryId) {
    message.warning('请选择项目')
    return
  }
  if (!form.value.list[0].machineId) {
    message.warning('请选择设备')
    return
  }
  if (!form.value.list[0].sensorId) {
    message.warning('请选择测点')
    return
  }
  if (!form.value.list[0].scoreName) {
    message.warning('请选择指标名称')
    return
  }
  if (!form.value.list[0].date) {
    message.warning('请选择发生时间')
    return
  }
  if (!form.value.list[0].expertDiagnosisResult) {
    message.warning('请选择稳定状态')
    return
  }
  if (!form.value.list[0].expertProcess) {
    message.warning('请选择诊断处理')
    return
  }
  subLoading.value = true

  let submitForm = {
    display: form.value.list[0].display,
    machineId: form.value.list[0].machineId,
    sensorId: form.value.list[0].sensorId,
    date: transformDate(form.value.list[0].date),
    expertDiagnosisResult: form.value.list[0].expertDiagnosisResult,
    condition: form.value.list[0].condition,
    expertProcess: form.value.list[0].expertProcess,
    scoreName: form.value.list[0].scoreName,
    faultName: faultList.value.find((p: any) => p.column === form.value.list[0].scoreName)?.title,
    status: 3,
    diagnosticNotes: form.value.diagnosticNotes,
    reportIds: form.value.reportId ? [form.value.reportId] : [],
  }

  let result = await request.post('/api/workflows/info', submitForm)
  if (result && result.status === 200) {
    subLoading.value = false
    message.success('操作成功')
    visible.value = false
    search()
  } else {
    subLoading.value = false
    message.warning('新增失败，请联系管理员')
  }

}

// 验证数据
const verifySure = (index: any, ev: any) => {
  if (!ev.isClick) {
    isCommit.value = true
    return
  }

  let list = dataList.value.filter((p: any) => p.isClick)
  let list1: any
  let dates = list.map((p: any) => p.date)
  dates = [...new Set(dates)]
  if (!dates || !dates.length) {
    isCommit.value = true
    return
  }
  if (dates && dates.length > 1) {
    message.warning('请选中所有同一时间的工单！')
    isCommit.value = true
    return
  } else {
    list1 = dataList.value.filter((p: any) => p.date === dates[0] && p.machineId === ev.machineId)
    if (list.length !== list1.length && !ev.isClick) {
      isCommit.value = true
      return
    }
    list1.map((d: any) => { d.isClick = true })
  }

  for (let item of list1) {
    if ((!item.expertDiagnosisResult && item.expertDiagnosisResult !== 0) || item.expertDiagnosisResult < 0) {
      isCommit.value = true
      message.warning('请选择稳定状态！')
      return
    }

    if ((!item.expertProcess && item.expertProcess !== 0) || item.expertProcess < 0 || item.expertProcess == 4) {
      isCommit.value = true
      message.warning('请选择诊断处理！')
      return
    }
  }
  isCommit.value = false
}

// 确认提交
const sure = async () => {
  let list = dataList.value.filter((p: any) => p.isClick)
  console.log('list', list)
  let sensorPlaces = [... new Set(list.map((p:any) => p.sensorPlace))]
  console.log('sensorPlaces', sensorPlaces)
  let string = ''

  sensorPlaces.map((d:any) => {
    let str:any = `${d}：`
    let infos = list.filter((p:any) => p.sensorPlace === d)
    infos.map((s:any, i:any) => {
      if(i == infos.length-1) {
        str = str + s.faultName + '；'
      } else {
        str = str + s.faultName + '，'
      }
    })
    string = string + str
  })

  // list.map((d:any) => {
  //   string = string + d.sensorPlace + '：' + d.faultName + '；'
  // })

  const config = {
    params: {
      machineId: list[0].machineId,
      date: list[0].date,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/workflows/info/count', config)
  if (result.status === 200 && result.data) {
    if (list.length === result.data) {
      editSelectOrder.value = list

      editForm.value = {
        diagnosticNotes: string,
        reportId: '',
        display: false,
      }

      visibleEdit.value = true
    } else {
      message.warning('所选测点当前时间点存在未选择的工单')
    }
  }
}

// 添加诊断判断内部备注
const addinternalNote = () => {
  addIString.value = ''
  addIVisible.value = true
}

const submitAddInternalNote = () => {
  internalNotesList.value.push(addIString.value)
  editForm.value.internalNotes = addIString.value
  addIVisible.value = false
}

// 保存任务处理
const editSubmit = async () => {
  subLoading.value = true
  let list = JSON.parse(JSON.stringify(editSelectOrder.value))
  list.map((d: any) => {
    delete d.isClick
    d.status = 3
  })

  let submitForm = {
    display: editForm.value.display,
    diagnosticNotes: editForm.value.diagnosticNotes,
    internalNotes: editForm.value.internalNotes,
    reportIds: editForm.value.reportId ? [editForm.value.reportId] : [],
    workflowInfos: list,
  }

  let result = await request.put('/api/workflows/info', submitForm)

  if (result && result.status === 200) {
    subLoading.value = false
    message.success('操作成功')
    visibleEdit.value = false
    search()
  } else {
    subLoading.value = false
    message.warning('操作失败, 请联系管理员!')
  }

}

// 专家处理添加专家报告
const addEditReport = async() => {
  // return
  let list = editSelectOrder.value.map((p:any) => ({
    faultName: p.faultName,
    scoreName: p.scoreName,
    expertDiagnosisResult: p.expertDiagnosisResult,
    id: p.id,
    sensorId: p.sensorId,
  }))
  let conditionL = list.map((p:any) => p.expertDiagnosisResult )
  let max = Math.max(...conditionL)
  let info = list.find((p:any) => p.expertDiagnosisResult === max)
  const config = {
    machineId: editSelectOrder.value[0].machineId,
    date: editSelectOrder.value[0].date,
    conditionList: list,
    sensorId: info?.sensorId
  };
  let result = await request.post('/api/reports-export-auto', config)

  reportCreateInfo.value = null
  if(result && result.data) {
    reportCreateInfo.value = result.data
  }

  reportMachineInfo.value = {
    machineName: editSelectOrder.value[0].machineName,
    id: editSelectOrder.value[0].machineId,
    factoryId: editSelectOrder.value[0].factoryId,
  }
  visibleAddRreport.value = true
}


// 取消选择
const cancelClick = () => {
  dataList.value.map((d: any) => { d.isClick = false })
  isCommit.value = true
}


// 查看
const viewDetail = async (ev: any) => {
  viewForm.value = ref({
    list: [{
      factoryId: '',
      machineName: '',
      sensorPlace: '',
      scoreName: '',
      date: null,
      condition: null,
      expertDiagnosisResult: null,
      expertProcess: null,
      display: null,
    }],
    diagnosticNotes: '',
    internalNotes: '',
    reportId: '',
  })
  visibleView.value = true

  const config = {
    params: {
      id: ev.id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get('/api/workflows/infos', config)
  if (result && result.data) {
    let info = result.data
    viewForm.value = {
      list: [{
        factoryId: info.factoryId,
        machineName: info.machineName,
        sensorPlace: info.sensorPlace,
        scoreName: info.scoreName,
        date: info.date,
        condition: info.condition,
        expertDiagnosisResult: info.expertDiagnosisResult,
        expertProcess: info.expertProcess,
        display: info.display,
      }],
      diagnosticNotes: info.diagnosticNotes,
      internalNotes: info.internalNotes,
      reportId: info.reportIds && info.reportIds.length ? info.reportIds[0] : '',
    }
  }


}

// 删除
const deleteOrder = async(ev:any) => {
  let config = {
    params: { id: ev.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.delete('/api/workflows/info', config)
  if(result && result.data) {
    setTimeout(() => {
      search()
    message.success('删除成功')
    }, 1000);
  } else {
    message.success('删除失败')
  }
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return record.indexMD % 2 === 0 ? "odd-row" : "even-row";
};

</script>
<style scoped lang="less">

.code-box {
  position: relative;
  display: inline-block;
  width: 90%;
  margin: 0 0px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  transition: all 0.2s;
  padding: 15px;
}

.main {
  margin-top: 30px;
}

.select-tag {
  border-bottom: 1px solid #1890ff;
  color: #1890ff;
}


.event-btns {
  /deep/ .ant-btn {
    background-color: rgb(7, 36, 153);
    border: 1px solid #0d53b7cc;;
    color: #fff ;
  }
}


</style>
