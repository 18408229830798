<template>
    <div class="w-full flex px-3 py-2"
        style="background-color: #000A31; color: #fff;overflow-y: scroll; height: calc(100vh - 100px);">
        <!-- 左侧部分 -->
        <div class="flex flex-col">
            <div class="title flex items-center">
                <div class="shu mr-3"></div>
                <span style="font-size:20px"> {{ langObj['监测范围'] }}</span>
                <!-- <span style="font-size:20px"> {{ getLanguage('监测范围', language) }}</span> -->
                <!-- <question-circle-outlined  class="ml-3"/> -->
                <a-tooltip placement="topLeft">
                    <template #title>
                        <div style="background: #fff">
                            <div>{{ langObj['监控设备数：用户所选组织下的监测设备数统计'] }}</div>
                            <div>{{ langObj['监控测点数：用户所选组织下的监测测点数统计'] }}</div>
                            <div>{{ langObj['运行设备数：用户所选组织下的监测设备中运行设备数统计'] }}</div>
                            <div>{{ langObj['运行率：用户所选组织下的监测设备中运行设备数/监测设备总数'] }}</div>
                        </div>
                    </template>
                    <question-circle-outlined style="font-size:14px" class="ml-2" />
                </a-tooltip>
            </div>

            <div class="flex items-center mt-2 " style=" color: #06DEF5;">
                <div class="flex items-center">
                    <img src="@/assets/cliqueD3.png" style="width: 40px">
                    <div class="ml-2 enW">
                        <div class="flex items-center">
                            <span style="font-size: 20px">{{ machineList.length }}</span>
                            <!-- <div class="flex-1"></div> -->
                            <span style="font-size: 14px">{{getLanguage("台", language)}}</span>
                        </div>
                        <div style="color: #fff;">{{ langObj['监控设备数'] }}</div>
                    </div>
                </div>

                <div class="flex items-center " style="color: #5B9BFF">
                    <img src="@/assets/cliqueD1.png" style="width: 40px">
                    <div class="ml-2 enW" >
                        <div class="flex items-center">
                            <span style="font-size: 20px">{{ sensorList.length }}</span>
                            <!-- <div class="flex-1"></div> -->
                            <span style="font-size: 14px">{{getLanguage("个", language)}}</span>
                        </div>
                        <div style="color: #fff">{{ langObj['监控测点数'] }}</div>
                    </div>
                </div>

                <div class="flex items-center" style="color: #5BFEB7">
                    <img src="@/assets/cliqueD2.png" style="width: 40px">
                    <div class="ml-2 enW">
                        <div class="flex items-center">
                            <span style="font-size: 20px">{{ getRunMachine(machineList).num }}</span>
                            <!-- <div class="flex-1"></div> -->
                            <span style="font-size: 14px">{{getLanguage("台", language)}} ({{ getRunMachine(machineList).rate }}%)</span>
                        </div>
                        <div style="color: #fff">{{ langObj['运行设备数(运行率)'] }}</div>
                    </div>
                </div>

            </div>

            <div class="title flex items-center mt-7">
                <div class="shu mr-3"></div>
                <span style="font-size:20px">{{ langObj['全厂运行情况'] }}</span>
            </div>

            <div class="flex items-center mt-5">
                <div class="box1">
                    <img class="w-full h-full" src='@/assets/cliqueD4.png' alt="">
                    <div class="content">
                        <div class="flex w-full items-center" style="justify-content: center">
                            <div style="font-size:20px">{{ totalTime }}</div>
                            <div class="ml-2" style="font-size:14px">{{getLanguage("小时", language)}}</div>

                            <a-tooltip placement="topLeft">
                                <template #title>
                                    <div style="background: #fff">
                                        {{ langObj['显示该系统为工厂上线运行的时间'] }}
                                        
                                    </div>
                                </template>
                                <question-circle-outlined style="font-size:14px" class="ml-2" />
                            </a-tooltip>
                        </div>
                        <div class="w-full" style="text-align: center; color: #fff">
                            
                            {{ langObj['系统持续服务运行 '] }}
                        </div>
                    </div>
                </div>

                <div class="box1 ml-5">
                    <img class="w-full h-full" src='@/assets/cliqueD4.png' alt="">
                    <div class="content">
                        <div class="flex w-full items-center" style="justify-content: center">
                            <div style="font-size:20px">{{ weightedAverage }}</div>
                            <div class="ml-3" style="font-size:14px">{{getLanguage("分", language)}}</div>
                            <a-tooltip placement="topLeft">
                                <template #title>
                                    <div style="background: #fff">
                                        {{ langObj['针对运行中的设备, 状态得分的加权平均数计算为全厂健康分数, 健康设备记100分, 可用设备记80分, 警戒设备记60分, 故障设备记30分'] }}
                                        
                                    </div>
                                </template>
                                <question-circle-outlined style="font-size:14px" class="ml-2" />
                            </a-tooltip>
                        </div>
                        <div class="w-full" style="text-align: center; color: #fff">
                            {{ langObj['全厂运行设备健康分数'] }}
                        </div>
                    </div>
                </div>

            </div>

            <div class="title flex items-center mt-7">
                <div class="shu mr-3"></div>
                <span style="font-size:20px">  {{ langObj['设备健康状态统计'] }}</span>
            </div>

            <div class="w-full">
                <div class="w-full flex ">
                    <div class="flex-1"></div>
                    <div class="mr-5">
                        <a-select ref="select" v-model:value="conditionSelectFac" style="width: 120px" size="small"
                            @change="getConditionChartData" :dropdownMatchSelectWidth="false">
                            <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{
                                item.factoryName
                            }}</a-select-option>
                        </a-select>
                    </div>
                </div>

                <div class="w-full flex items-center">
                    <div style="width: 200px; height: 200px" id="chartA"></div>
                    <div class="ml-12">
                        <div v-if="isShowRate" class="flex items-center">
                            <div > {{ '可用率' }}：</div>
                            <div>{{ availabilityRatio }}%</div>
                            <!-- <a-tooltip placement="topLeft">
                                <template #title>
                                    <div style="background: #fff">
                                        可用率 =（健康设备数+可用设备数）/ 设备总数
                                    </div>
                                </template>
                                <question-circle-outlined style="font-size:14px" class="ml-2" />
                            </a-tooltip> -->
                        </div>
                        <div class="flex items-center cursor-pointer mt-3" @click="selectMachineCondition(1)">
                            <div style="width:14px; height:14px; background-color: #61c08f"></div>
                            <div class="ml-2"> {{ langObj['健康'] }}：</div>
                            <div>{{ conditionChartData[0].num }}{{getLanguage("台", language)}}</div>
                        </div>
                        <div class="flex items-center cursor-pointer mt-3" @click="selectMachineCondition(2)">
                            <div style="width:14px; height:14px; background-color: #d2de49"></div>
                            <div class="ml-2"> {{ langObj['可用'] }}：</div>
                            <div>{{ conditionChartData[1].num }}{{getLanguage("台", language)}}</div>
                        </div>
                        <div class="flex items-center cursor-pointer mt-3" @click="selectMachineCondition(3)">
                            <div style="width:14px; height:14px; background-color: #cd5f3b"></div>
                            <div class="ml-2"> {{ langObj['警戒'] }}：</div>
                            <div>{{ conditionChartData[2].num }}{{getLanguage("台", language)}}</div>
                        </div>
                        <div class="flex items-center cursor-pointer mt-3" @click="selectMachineCondition(4)">
                            <div style="width:14px; height:14px; background-color: #921e37"></div>
                            <div class="ml-2"> {{ langObj['故障'] }}：</div>
                            <div>{{ conditionChartData[3].num }}{{getLanguage("台", language)}}</div>
                        </div>
                    </div>
                </div>



            </div>


            <div class="title flex items-center mt-7">
                <div class="shu mr-3"></div>
                <span style="font-size:20px">{{ langObj['设备运行状态统计'] }}</span>
            </div>

            <div class="w-full">
                <div class="w-full flex ">
                    <div class="flex-1"></div>
                    <div class="mr-5">
                        <a-select ref="select" v-model:value="statusSelectFac" style="width: 120px" size="small"
                            @change="getStatusChartData" :dropdownMatchSelectWidth="false">
                            <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{
                                item.factoryName
                            }}</a-select-option>
                        </a-select>
                    </div>
                </div>

                <div class="w-full flex items-center">
                    <div id="chartB" style="width: 200px; height: 200px"></div>
                    <div class="ml-12">
                        <div class="flex items-center cursor-pointer" @click="selectMachineStatus(1)">
                            <div style="width:14px; height:14px; background-color: #06DEF5"></div>
                            <div class="ml-2">{{ langObj['运行'] }}：</div>
                            <div>{{ statusChartData[0].num }}{{getLanguage("台", language)}}</div>
                        </div>
                        <div class="flex items-center cursor-pointer mt-3" @click="selectMachineStatus(0)">
                            <div style="width:14px; height:14px; background-color: #ADADAD"></div>
                            <div class="ml-2">{{ langObj[stopLabel] }}：</div>
                            <div>{{ statusChartData[1].num }}{{getLanguage("台", language)}}</div>
                        </div>

                    </div>
                </div>
            </div>

        </div>





        <!-- 中间部分 -->
        <div class="flex-1">
            <div class="w-full flex" style="justify-content: center">
                <div class="title2 text-center mt-12">
                    {{ factory_name }}
                </div>
            </div>
            <div class="w-full flex">
                <div style="width: 5%;"></div>
                <div style="position: relative; width: 90%">
                    <div 
                        v-for="(item, index) in localeList" :key="index"
                        style="position: absolute; color: red; " 
                        :style="{'left': item[0] + 'px', 'top': item[1]+'px'}"
                    >
                        <img style="width: 50px;" src="@/assets/facMark.png" alt="">
                    </div>
                    <img id="facImg" style="width: 100%;" :src="facPic" alt="">
                    
                </div>
            </div>
            

        </div>


        <!-- 右侧部分 -->
        <div class="flex flex-col">

            <div class="title flex items-center">
                <div class="shu mr-3"></div>
                <span style="font-size:20px">{{ langObj['设备报警数量统计'] }}</span>
            </div>

            <div class="mt-4">
                <div class="flex items-center">
                    <a-select ref="select" v-model:value="machineWarningTime" style="width: 120px" size="small"
                        @change="getMachineWarningChartData">
                        <a-select-option :value="1">{{ langObj['本年'] }}</a-select-option>
                        <a-select-option :value="2">{{ langObj['本季度'] }}</a-select-option>
                        <a-select-option :value="3">{{ langObj['本月'] }}</a-select-option>
                        <a-select-option :value="4">{{ langObj['本周'] }}</a-select-option>
                    </a-select>

                    <a-select ref="select" v-model:value="machineWarningFac" style="width: 120px; margin-left:10px"
                        size="small" @change="getMachineWarningChartData" :dropdownMatchSelectWidth="false">
                        <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{
                                item.factoryName
                            }}</a-select-option>
                    </a-select>
                </div>

                <div class="mt-4 class flex items-center">
                    <div v-if="visibleChartC" id="chartC" style="width: 300px; height: 150px"></div>
                    <div class="ml-12">
                        <div class="flex items-center" @click="selectWarningStatus(1)">
                            <div style="width:14px; height:14px; background-color: #d2de49"></div>
                            <div class="ml-2">{{ langObj['可用报警'] }}</div>
                        </div>
                        <div class="flex items-center mt-3">
                            <div style="width:14px; height:14px; background-color: #cd5f3b"></div>
                            <div class="ml-2">{{ langObj['警戒报警'] }}</div>
                        </div>
                        <div class="flex items-center mt-3">
                            <div style="width:14px; height:14px; background-color: #921e37"></div>
                            <div class="ml-2">{{ langObj['故障报警'] }}</div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="title flex items-center mt-7">
                <div class="shu mr-3"></div>
                <span style="font-size:20px">{{ langObj['各厂报警数量排行'] }}</span>
            </div>

            <div class="mt-4">
                <a-select ref="select" v-model:value="factoryWarningTime" style="width: 120px" size="small"
                    @change="getFactoryWarningChartData">
                    <a-select-option :value="1">{{ langObj['本年'] }}</a-select-option>
                        <a-select-option :value="2">{{ langObj['本季度'] }}</a-select-option>
                        <a-select-option :value="3">{{ langObj['本月'] }}</a-select-option>
                        <a-select-option :value="4">{{ langObj['本周'] }}</a-select-option>
                </a-select>

                <div class="mt-4 class flex items-center">
                    <div v-if="visibleChartD" id="chartD" style="width: 300px; height: 150px"></div>
                    <div class="ml-12">
                        <div class="flex items-center">
                            <div style="width:14px; height:14px; background-color: #d2de49"></div>
                            <div class="ml-2">{{ langObj['可用报警'] }}</div>
                        </div>
                        <div class="flex items-center mt-3">
                            <div style="width:14px; height:14px; background-color: #cd5f3b"></div>
                            <div class="ml-2">{{ langObj['警戒报警'] }}</div>
                        </div>
                        <div class="flex items-center mt-3">
                            <div style="width:14px; height:14px; background-color: #921e37"></div>
                            <div class="ml-2">{{ langObj['故障报警'] }}</div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="title flex items-center mt-7">
                <div class="shu mr-3"></div>
                <span style="font-size:20px">{{ langObj['设备报警趋势'] }}</span>
            </div>

            <div class="mt-4">
                <a-select ref="select" v-model:value="warningTrendTime" style="width: 120px" size="small"
                    @change="getwarningTrendChartData">
                    <a-select-option :value="1">{{ langObj['本月'] }}</a-select-option>
                    <a-select-option :value="2">{{ langObj['本周'] }}</a-select-option>
                </a-select>

                <a-select ref="select" v-model:value="warningTrendFac" style="width: 120px; margin-left:10px"
                    size="small" @change="getwarningTrendChartData" :dropdownMatchSelectWidth="false">
                    <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{
                                item.factoryName
                            }}</a-select-option>
                </a-select>


            </div>

            <div class="mt-4 class flex items-center">
                <div v-if="visibleChartE" id="chartE" style="width: 300px; height: 150px"></div>
                <div class="ml-12">
                    <div class="flex items-center">
                        <div style="width:14px; height:14px; background-color: #d2de49"></div>
                        <div class="ml-2">{{ langObj['可用报警'] }}</div>
                    </div>
                    <div class="flex items-center mt-3">
                        <div style="width:14px; height:14px; background-color: #cd5f3b"></div>
                        <div class="ml-2">{{ langObj['警戒报警'] }}</div>
                    </div>
                    <div class="flex items-center mt-3">
                        <div style="width:14px; height:14px; background-color: #921e37"></div>
                        <div class="ml-2">{{ langObj['故障报警'] }}</div>
                    </div>
                </div>
            </div>

        </div>


        <!-- 设备列表 -->
        <a-modal v-model:visible="machineVisible" :closable="false" :footer="null"
            :bodyStyle="{ background: '#020F46' }" style="top: 30%" width="1400px" :maskClosable="false">
            <div style="color: #fff">
                <div class="w-full flex">
                    <div class="flex-1"></div>
                    <div class="cursor-pointer" @click="machineVisible = false">
                        <close-outlined style="font-size: 20px" />
                    </div>
                </div>
                <div class="text-center w-full mb-4" style="font-size: 24px">{{ getLanguage('设备列表', language) }}</div>

                <a-form :model="machineModelSearch" name="horizontal_login" layout="inline" autocomplete="off">
                    <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                        <a-select v-model:value="machineModelSearch.groupId" style="width: 220px" allowClear
                            :placeholder="getLanguage('请选择区域', language)">
                            <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.sonID">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item :label="getLanguage('健康状态', language)" name="condition">
                        <a-select v-model:value="machineModelSearch.condition" style="width: 220px" allowClear
                        :placeholder="getLanguage('请选择健康状态', language)">
                            <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item :label="getLanguage('运行状态', language)" name="status">
                        <a-select v-model:value="machineModelSearch.status" style="width: 220px" allowClear
                            :placeholder="getLanguage('请选择运行状态', language)">
                            <a-select-option v-for="(item, index) in statusList" :key="index" :value="item.value">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item :label="getLanguage('搜索', language)" name="status">
                        <a-input v-model:value="machineModelSearch.keyWord" :placeholder="getLanguage('请输入设备名称/编号', language)" />
                    </a-form-item>

                    <a-form-item>
                        <div class="flex items-center">
                            <div @click="resetMachine" class="box px-5 py-1 fff cursor-pointer ml-4"
                                style="background-color: #072499; color: #fff">
                                {{ getLanguage('重置', language) }}
                            </div>

                            <div @click="getDataMachine" class="box px-5 py-1 fff cursor-pointer ml-4"
                                style="background-color: #072499; color: #fff">
                                {{ getLanguage('查询', language) }}
                            </div>
                        </div>
                    </a-form-item>
                </a-form>

                <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                    <a-table style="width: 100%" size="small" :row-class-name="getRowClassName"
                        :columns="machineColumns" :data-source="machineDataList" :pagination="false">
                        <template #bodyCell="{ column, text, record }">
                            <template v-if="column.dataIndex === 'factoryId'">
                                <span>{{ getFactoryName(text) }}</span>
                            </template>

                            <template v-if="column.dataIndex === 'groupId'">
                                <span>{{ getGroupName(text) }}</span>
                            </template>

                            <template v-if="column.dataIndex === 'experiment'">
                                <span>{{
                                    getLanguage(whetherList.find((p) => p.value === text)?.label, language)
                                
                            }}</span>
                            </template>

                            <template v-if="column.dataIndex === 'condition'">
                                <div class="flex items-center">
                                    <div style="width: 14px; height: 14px; border-radius: 50%"
                                        :style="{ background: getMachienCondition(record)?.color }"></div>
                                    <div class="ml-1" :style="{ color: getMachienCondition(record)?.color }">
                                        {{ getLanguage(getMachienCondition(record)?.label, language) }}
                                    </div>
                                </div>
                            </template>

                            <template v-if="column.dataIndex === 'status'">
                                <div class="ml-1" :style="{ color: getMachienStatus(record)?.color }">
                                    {{ getLanguage(getMachienStatus(record)?.label, language) }}
                                </div>
                            </template>
                        </template>
                    </a-table>
                </div>










            </div>
        </a-modal>

        <!-- 报警列表 -->
        <a-modal v-model:visible="warnVisible" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
            style="top: 30%" width="1400px" :maskClosable="false">
            <div style="color: #fff">
                <div class="w-full flex">
                    <div class="flex-1"></div>
                    <div class="cursor-pointer" @click="warnVisible = false">
                        <close-outlined style="font-size: 20px" />
                    </div>
                </div>
                <div class="text-center w-full" style="font-size: 24px">报警记录</div>

                <div class="w-full">
                    <!-- <a-form
                        :model="warnSearch"
                        name="horizontal_login"
                        layout="inline"
                        autocomplete="off"
                    >
                        <a-form-item
                            label="设备区域"
                            name="groupId"
                        >
                            <a-select
                                v-model:value="warnSearch.groupId"
                                style="width: 220px"
                                placeholder="请选择设备区域"
                                @change="selectGroup"
                            >
                            <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id"> {{ item.name }} </a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-form-item
                            label="设备"
                            name="status"
                            >
                            <a-select
                                v-model:value="warnSearch.machienId"
                                style="width: 220px"
                                placeholder="请选择设备"
                                allowClear
                            >
                            <a-select-option v-for="(item, index) in machineList" :key="index" :value="item.id"> {{ item.name }} </a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-form-item
                            label="报警时间"
                            name="status"
                        >
                            <a-range-picker v-model:value="warnSearch.date"  :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
                        </a-form-item>

                        <a-form-item
                            label="报警类型"
                            name="status"
                            >
                            <a-select
                                v-model:value="warnSearch.type"
                                style="width: 220px"
                                allowClear
                                placeholder="请选择报警类型"
                            >
                                <a-select-option v-for="(item, index) in typeList" :key="index" :value="item.value"> {{ item.label }} </a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-form-item
                            label="严重程度"
                            name="status"
                            >
                            <a-select
                                v-model:value="searchModel.condition"
                                style="width: 220px"
                                allowClear
                                placeholder="请选择严重程度"
                            >
                            <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value"> {{ item.label }} </a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-form-item>
                            <div class="flex items-center">
                                <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4">重置</div>
                                <div @click="search" class="btn px-5 py-1 fff cursor-pointer ml-4" style="background-color: #072499;">查询</div>
                                
                            </div>
                        </a-form-item>
                        

                    </a-form> -->
                </div>






            </div>
        </a-modal>





    </div>
</template>

<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import * as echarts from "echarts";
import {
    transformDate,
    getFactoryName,
    getGroupName,
    transformDate2,
    getGroupListByFactory,
} from "../../common/tools";
import MapImg from "../../assets/map.png";
import { langList } from "../../common/lang";
import { getRealFactoryIdListById } from "../../common/tools";
import { getLanguage } from "../../common/translate"
let factory_id = ''

let value = ref('')

let stopLabel:any = ref('停机')
let isShowRate:any = ref(false)
let availabilityRatio:any = ref(0)

let totalTime: any = ref(null) //系统运行时间
let weightedAverage: any = ref(0)    //全厂运行设备健康分数
let factoryList: any = ref([])
let groupList: any = ref([])     //区域
let machineList: any = ref([])   //
let sensorList: any = ref([])   //

let conditionSelectFac = ref('')    //设备健康状态统计 工厂选择
let conditionChartData: any = ref([      //设备健康状态统计 数据
    { label: '健康', num: 0, color: '#61c08f' },
    { label: '可用', num: 0, color: '#d2de49' },
    { label: '警戒', num: 0, color: '#cd5f3b' },
    { label: '故障', num: 0, color: '#921e37' },
])

let statusSelectFac = ref('')    //设备运行状态统计 工厂选择
let statusChartData: any = ref([      //设备运行状态统计 数据
    { label: '运行', num: 0, color: '#06DEF5' },
    { label: '停机', num: 0, color: '#ADADAD' },
])


// 设备报警数量统计时间
let machineWarningTime = ref(1)
let machineWarningFac = ref('')
let machineWarningChartData = ref([
    { label: '可用报警', num: [0, 0, 0], color: '#DDD246' },
    { label: '警戒报警', num: [0, 0, 0], color: '#CB6A34' },
    { label: '故障报警', num: [0, 0, 0], color: '#B81212' },
])

// 各厂报警数量排行
let factoryWarningTime = ref(1)
let factoryWarningChartData: any = ref([])

// 设备报警趋势
let warningTrendTime = ref(1)
let warningTrendFac = ref('')
let warningTrendChartData: any = ref([])

let factory_name: any = ref('')
let factory_info: any = ref({})


let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let facPic = ref('')
let localeList:any = ref([])

onMounted(() => {
    getLang()
    init()
})

const init = () => {
    factory_id = localStorage.getItem('factory_id') || ''
    if (!factory_id) return
    if(factory_id === '616bb0a6e731767afe63723a' || factory_id === '63bfaa5b4e5403690c2f098c' || factory_id === '63e5dcac4e5403690c2f0fe0') {
        stopLabel.value = '待机'
        isShowRate.value = true
    }

    conditionSelectFac.value = factory_id
    statusSelectFac.value = factory_id
    machineWarningFac.value = factory_id
    warningTrendFac.value = factory_id
    loadFactory()
}

// 获取工厂
const loadFactory = async() => {
    localeList.value = []
    let groups:any = []
    factoryList.value = []
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/factories", config)
    if(result.data && result.data.length) {
        // 演示账号
        factoryList.value = result.data.filter((p:any) => p._id === factory_id || p.parentId === factory_id)

        let config: any = {
            params: {
                factoryId: factory_id
            },
            headers: {
                requestId: uuidv4(),
            },
        };
        let reslut = await request.get('/api/factories/info', config)
        if(reslut?.data) {
            if(reslut?.data?.picture) {
                facPic.value = reslut.data.picture.url
            } else {
                facPic.value = MapImg
            }

            let facInfo = reslut.data
            if(facInfo) {
                if(facInfo?.position?.length > 0) {
                    let list:any = []
                    setTimeout(() => {
                        facInfo.position.map((d:any) => {
                            let left = 0
                            let right = 0

                            let div:any = document.getElementById('facImg');
                            let width = div.offsetWidth || 0;
                            let height = div.offsetHeight || 0;
                            left = width * (d[0] / 100) - 25
                            right = height * (d[1] / 100) - 25
                            list.push([left, right])
                        }) 

                        localeList.value = list

                    }, 500);
                }

                if(!facInfo.position) {
                    facInfo.position = []
                }

                factory_name.value = facInfo.factoryName
                factory_info.value = facInfo
            } else {
                factory_name.value = ''
            }
            
            
        }

        

        //获取运行时间
        totalTime.value = 0
        let facL = factoryList.value.filter((p: any) => p.parentId === factory_id)
        if (facL && facL.length) {
            let time: any = 0
            facL.map((d: any) => {
                let targetTime: any = new Date(d.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                let time2 = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
                if (time2 > time) {
                    time = time2
                }
            })
            totalTime.value = time

        } else {
            let info = factoryList.value.find((p: any) => p._id === factory_id)
            if (info) {
                let targetTime: any = new Date(info.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                totalTime.value = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
            } else {
                totalTime.value = 0
            }

        }

        result.data.forEach((element: any) => {
            element.groups = element.groups.map((t: any) => ({
                ...t,
                Name: element.factoryName + "-" + t.name,
            }));
            groups = groups.concat(element.groups);
        });

        getMain(groups);
    }

}

// 获取区域列表
const getMain = async (groups: any) => {
    const config = {
        params: {
            factoryIds: getRealFactoryIdListById(factory_id) 
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/external/machines/workstatus", config)

    weightedAverage.value = 0
    groupList.value = [];
    machineList.value = [];
    sensorList.value = [];

    if (result && result.data) {
        let weightAll = 0
        let count = 0
        groups.forEach((item2: any) => {
            item2.machines = result.data.filter(
                (t2: any) => t2.groupId == item2.sonID
            );
            item2.machines.map((d: any) => {
                sensorList.value = sensorList.value.concat(d.sensors)
                if (!d.workStatus) {
                    d.workStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }
                if (!d.workStableStatus) {
                    d.workStableStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }

                if (d.workStableStatus.condition === 0) {
                    weightAll = weightAll + 100
                }
                if (d.workStableStatus.condition >= 1 && d.workStableStatus.condition <= 2) {
                    weightAll = weightAll + 80
                }
                if (d.workStableStatus.condition >= 3 && d.workStableStatus.condition <= 5) {
                    weightAll = weightAll + 60
                }
                if (d.workStableStatus.condition >= 6 && d.workStableStatus.condition <= 7) {
                    weightAll = weightAll + 30
                }
                count++
            })
            machineList.value = machineList.value.concat(item2.machines)

            groupList.value.push(item2)
        })
        weightedAverage.value = count ? (weightAll / count).toFixed(2) : 0
    }


    setTimeout(() => {
        getConditionChartData()
        getStatusChartData()
        getMachineWarningChartData()
        getFactoryWarningChartData()
        getwarningTrendChartData()
    }, 500);

}

// 获取运行设备数及运行率
const getRunMachine = (ev: any) => {
    let num: any = 0
    let rate: any = 0
    if (ev && ev.length) {
        ev.map((d: any) => {
            if (d.workStatus.wkStatus) {
                num++
            }
        })
        rate = ((num / ev.length) * 100).toFixed(2)
    }

    return { num, rate }
}

// 获取设备健康状态统计/设备运行状态统计 数据
const getConditionChartData = () => {
    let n1 = 0, n2 = 0, n3 = 0, n4 = 0
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === conditionSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (m.workStableStatus.condition === 0) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === conditionSelectFac.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                if (m.workStableStatus.condition === 0) {
                    n1++
                }
                if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                    n2++
                }
                if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                    n3++
                }
                if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                    n4++
                }
            })
        })
    }


    conditionChartData.value = [
        { label: '健康', num: n1, color: '#61c08f' },
        { label: '可用', num: n2, color: '#d2de49' },
        { label: '警戒', num: n3, color: '#cd5f3b' },
        { label: '故障', num: n4, color: '#921e37' },
    ]

    if((n1 + n2 + n3 + n4) >0) {
        availabilityRatio.value = (((n1 + n2) / (n1 + n2 + n3 + n4))*100).toFixed(2)
    } else {
        availabilityRatio.value = '0.00'
    }

    initConditionChart()
}

// 绘制设备健康状态统计图
const initConditionChart = () => {
    if (!document.getElementById("chartA")) return
    let myChart = echarts.init(document.getElementById("chartA") as HTMLDivElement);

    let legendData = conditionChartData.value.map((t: any) => t.label)
    let DData = conditionChartData.value.map((t: any) => ({
        value: t.num,
        name: t.label + "设备数：" + t.num
    }))

    let options = {
        title: {
            text: "",
            left: "center",
        },
        tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
        },
        legend: {
            show: false,
            orient: "vertical",
            right: 10,
            top: 30,
            textStyle: {
                color: "#ffffff",
                fontSize: 12,
            },
            data: legendData,
            selected: {
                健康: true,
            },
        },
        graphic: {
            type: "text",
            left: "center",
            top: "center",
            style: {
                text: langObj.value["总设备数"] +":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num),
                textAlign: "center",
                fontSize: 16,
                fill: "#ffffff",
            },
            tooltip: {
                show: false,
            },
        },
        series: [
            {
                name: "",
                type: "pie",
                radius: ["65%", "85%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 12,
                        fontWeight: "bold",
                        formatter: "{b}\n{c} ({d}%)",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: DData,
            },
        ],
        color: ['rgb(28, 196, 139)', 'rgb(206, 224, 8)', 'rgb(223, 87, 45)', 'rgb(162, 3, 53)']
    };

    myChart.setOption(options);
    myChart.on("mouseover", (params: any) => {
        const { name } = params;
        if(name){
            let nameArr = name.split("：");
            options.graphic.style.text = langObj.value[nameArr[0]] +"："+ nameArr[1];
        }else{
            options.graphic.style.text =  langObj.value["总设备数"] +":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num);
        }
       
        myChart.setOption(options);
    });
    myChart.on("mouseout", () => {
        options.graphic.style.text = langObj.value["总设备数"] +":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num)
        myChart.setOption(options);
        myChart.setOption(options);
    });

}

// 获取设备运行状态统计 数据
const getStatusChartData = () => {
    let s1 = 0, s2 = 0
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === statusSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (m.workStatus.wkStatus) {
                        s1++
                    } else {
                        s2++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === statusSelectFac.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                if (m.workStatus.wkStatus) {
                    s1++
                } else {
                    s2++
                }
            })
        })
    }

    statusChartData.value = [
        { label: '运行', num: s1, color: '#06DEF5' },
        { label: '停机', num: s2, color: '#ADADAD' },
    ]

    initStatusChart()
}

// 绘制设备运行状态统计图
const initStatusChart = () => {
    if (!document.getElementById("chartB")) return
    let myChart = echarts.init(document.getElementById("chartB") as HTMLDivElement);

    let legendData = statusChartData.value.map((t: any) => t.label)
    let DData = statusChartData.value.map((t: any) => ({
        value: t.num,
        name: t.label + "设备数：" + t.num
    }))

    let options = {
        title: {
            text: "",
            left: "center",
        },
        tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
        },
        legend: {
            show: false,
            orient: "vertical",
            right: 10,
            top: 30,
            textStyle: {
                color: "#ffffff",
                fontSize: 12,
            },
            data: legendData,
            selected: {
                健康: true,
            },
        },
        graphic: {
            type: "text",
            left: "center",
            top: "center",
            bouding: 'all',
            style: {
                text: langObj.value["总设备数"] +":" + (statusChartData.value[0].num + statusChartData.value[1].num),
                textAlign: "center",
                fontSize: 16,
                fill: "#ffffff",
            },
            tooltip: {
                show: false,
            },
        },
        series: [
            {
                name: "",
                type: "pie",
                radius: ["65%", "85%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 12,
                        fontWeight: "bold",
                        formatter: "{b}\n{c} ({d}%)",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: DData,
            },
        ],
        color: statusChartData.value.map((t: any) => t.color)
    };

    myChart.setOption(options);
    myChart.on("mouseover", (params: any) => {
        const { name } = params;
        if(name){
            let nameArr = name.split("：");
            if(nameArr[0] == '停机设备数') {
                nameArr[0] = stopLabel.value + '设备数'
            }
            options.graphic.style.text = langObj.value[nameArr[0]] +"："+ nameArr[1] 
        }
  
        myChart.setOption(options);
    });
    myChart.on("mouseout", () => {
        options.graphic.style.text = langObj.value["总设备数"] +":" + (statusChartData.value[0].num + statusChartData.value[1].num)
        myChart.setOption(options);
        myChart.setOption(options);
    });
}

// 获取设备报警数量
let visibleChartC = ref(true)
const getMachineWarningChartData = async () => {
    let time = new Date()
    let endDate = transformDate(time)
    let startDate: any = ''
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let week = time.getDay()

    //本年
    if (machineWarningTime.value === 1) {
        startDate = `${year}/01/01 00:00:00`
    }
    //本季度
    if (machineWarningTime.value === 2) {
        let m: any = 0
        if (month >= 1 && month <= 3) {
            m = 1
        }
        if (month >= 4 && month <= 6) {
            m = 4
        }
        if (month >= 7 && month <= 9) {
            m = 7
        }
        if (month >= 10 && month <= 12) {
            m = 10
        }
        m = m > 9 ? m : '0' + m
        startDate = `${year}/${m}/01 00:00:00`
    }
    //本月
    if (machineWarningTime.value === 3) {
        let m = month > 9 ? month : '0' + month
        startDate = `${year}/${m}/01 00:00:00`
    }
    // 本周
    if (machineWarningTime.value === 4) {
        let num = week - 1
        let date1 = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * num))
        let y = date1.getFullYear()
        let m = date1.getMonth() + 1 > 9 ? date1.getMonth() + 1 : '0' + (date1.getMonth() + 1)
        let d = date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()
        startDate = `${y}/${m}/${d} 00:00:00`
    }

    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === machineWarningFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [machineWarningFac.value]
    }

    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            begin: startDate,
            end: endDate,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)

    // 1未处理，2已检修, 3已关闭，
    let status1 = [0, 0, 0]
    let status2 = [0, 0, 0]
    let status3 = [0, 0, 0]

    if (result && result.data) {
        let statusL1 = result.data.list.filter((p: any) => !p.handleStatus || p.handleStatus === 1)
        let statusL2 = result.data.list.filter((p: any) => p.handleStatus === 2)
        let statusL3 = result.data.list.filter((p: any) => p.handleStatus === 3)

        status1[0] = statusL1.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
        status2[0] = statusL1.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
        status3[0] = statusL1.filter((p: any) => p.condition >= 6 && p.condition <= 7).length

        status1[1] = statusL2.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
        status2[1] = statusL2.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
        status3[1] = statusL2.filter((p: any) => p.condition >= 6 && p.condition <= 7).length


        status1[2] = statusL3.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
        status2[2] = statusL3.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
        status3[2] = statusL3.filter((p: any) => p.condition >= 6 && p.condition <= 7).length

    }

    visibleChartC.value = false
    visibleChartC.value = true

    setTimeout(() => {
        initMachineWarningChart(status1, status2, status3)
    }, 200);
}

// 绘制设备报警数量图
const initMachineWarningChart = (status1: any, status2: any, status3: any) => {
    if (!document.getElementById("chartC")) return
    let myChart = echarts.init(document.getElementById("chartC") as HTMLDivElement);
    let option = {
        grid: {
            top: '4%',
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            show: false,
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: [langObj.value['未处理'], langObj.value['已关闭'], langObj.value['已检修']],
                axisLabel: {
                    color: '#fff'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {    //网格线
                    lineStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: true //隐藏或显示
                },
            }
        ],
        series: [
            {
                name: langObj.value['可用'],
                type: 'bar',
                barGap: 0,
                emphasis: {
                    focus: 'series'
                },
                data: status1,
                itemStyle: {
                    color: '#d2de49'
                }
            },
            {
                name: langObj.value['警戒'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: status2,
                itemStyle: {
                    color: '#cd5f3b'
                }
            },
            {
                name: langObj.value['故障'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: status3,
                itemStyle: {
                    color: '#921e37'
                }
            },
        ]
    };

    myChart && myChart.setOption(option)
}

// 获取各厂报警数量排行数据
let visibleChartD = ref(true)
const getFactoryWarningChartData = async () => {
    let time = new Date()
    let endDate = transformDate(time)
    let startDate: any = ''
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let week = time.getDay()

    //本年
    if (factoryWarningTime.value === 1) {
        startDate = `${year}/01/01 00:00:00`
    }
    //本季度
    if (factoryWarningTime.value === 2) {
        let m: any = 0
        if (month >= 1 && month <= 3) {
            m = 1
        }
        if (month >= 4 && month <= 6) {
            m = 4
        }
        if (month >= 7 && month <= 9) {
            m = 7
        }
        if (month >= 10 && month <= 12) {
            m = 10
        }
        m = m > 9 ? m : '0' + m
        startDate = `${year}/${m}/01 00:00:00`
    }
    //本月
    if (factoryWarningTime.value === 3) {
        let m = month > 9 ? month : '0' + month
        startDate = `${year}/${m}/01 00:00:00`
    }
    // 本周
    if (factoryWarningTime.value === 4) {
        let num = week - 1
        let date1 = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * num))
        let y = date1.getFullYear()
        let m = date1.getMonth() + 1 > 9 ? date1.getMonth() + 1 : '0' + (date1.getMonth() + 1)
        let d = date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()
        startDate = `${y}/${m}/${d} 00:00:00`
    }

    // let factoryIds = factoryList.value.map((p:any) => p._id)

    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === machineWarningFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [machineWarningFac.value]
    }


    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            begin: startDate,
            end: endDate,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    factoryWarningChartData.value = []

    if (result && result.data) {
        let facIds = [... new Set(result.data.list.map((p: any) => p.factoryId))]
        facIds.map((d: any) => {
            let list = result.data.list.filter((p: any) => p.factoryId === d)
            let condition1 = list.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
            let condition2 = list.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
            let condition3 = list.filter((p: any) => p.condition >= 6 && p.condition <= 7).length

            // factoryWarningChartData.value.push([
            //     { label: '可用报警', num: condition1, color: '#DDD246' },
            //     { label: '警戒报警', num: condition2, color: '#CB6A34' },
            //     { label: '故障报警', num: condition3, color: '#B81212' },
            // ])

            factoryWarningChartData.value.push({
                factoryName: factoryList.value.find((p: any) => p._id === d)?.factoryName,
                data: [condition1, condition2, condition3],
            })
        })
    }

    visibleChartD.value = false
    visibleChartD.value = true

    setTimeout(() => {
        initFactoryWarningChart()
    }, 200);

}

// 绘制各厂报警数量排行图
const initFactoryWarningChart = () => {
    if (!document.getElementById("chartD")) return
    let myChart = echarts.init(document.getElementById("chartD") as HTMLDivElement);
    let list1: any = [], list2: any = [], list3: any = [], list4: any = []
    factoryWarningChartData.value.map((d: any) => {
        list1.push(d.factoryName)
        list2.push(d.data[0])
        list3.push(d.data[1])
        list4.push(d.data[2])
    })

    let option = {
        grid: {
            top: '4%',
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            show: false,
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: list1,
                axisLabel: {
                    color: '#fff'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {    //网格线
                    lineStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: true //隐藏或显示
                },
            }
        ],
        series: [
            {
                name: langObj.value['可用'],
                type: 'bar',
                barGap: 0,
                emphasis: {
                    focus: 'series'
                },
                data: list2,
                itemStyle: {
                    color: '#d2de49'
                }
            },
            {
                name: langObj.value['警戒'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: list3,
                itemStyle: {
                    color: '#cd5f3b'
                }
            },
            {
                name: langObj.value['故障'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: list4,
                itemStyle: {
                    color: '#921e37'
                }
            },
        ]
    };


    myChart && myChart.setOption(option)
}

// 获取设备报警趋势数据
let visibleChartE = ref(true)
const getwarningTrendChartData = async () => {
    let time = new Date()
    let endDate = transformDate(time)
    let startDate: any = ''
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let week = time.getDay()
    //本月
    if (warningTrendTime.value === 1) {
        let m = month > 9 ? month : '0' + month
        startDate = `${year}/${m}/01 00:00:00`
    }
    // 本周
    if (warningTrendTime.value === 2) {
        let num = week - 1
        let date1 = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * num))
        let y = date1.getFullYear()
        let m = date1.getMonth() + 1 > 9 ? date1.getMonth() + 1 : '0' + (date1.getMonth() + 1)
        let d = date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()
        startDate = `${y}/${m}/${d} 00:00:00`
    }

    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === warningTrendFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [warningTrendFac.value]
    }

    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            begin: startDate,
            end: endDate,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    warningTrendChartData.value = []

    if (result && result.data && result.data.list) {
        result.data.list.reverse()

        let dateList = result.data.list.map((d: any) => d.date.substring(0, 10))
        dateList = [... new Set(dateList)]

        dateList.map((d: any) => {
            let list = result.data.list.filter((p: any) => p.date.includes(d))

            let statusList1 = list.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
            let statusList2 = list.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
            let statusList3 = list.filter((p: any) => p.condition >= 6 && p.condition <= 7).length

            warningTrendChartData.value.push({
                date: d,
                statusList1,
                statusList2,
                statusList3,
            })
        })


    }

    visibleChartE.value = false
    visibleChartE.value = true

    setTimeout(() => {
        initWarningTrendChart()
    }, 200);

}

// 绘制设备报警趋势图
const initWarningTrendChart = () => {
    if (!document.getElementById("chartE")) return
    let myChart = echarts.init(document.getElementById("chartE") as HTMLDivElement);
    // myChart.clear()
    let colors: any = ['#DDD246', '#CB6A34', '#B81212']
    let xData: any = []

    xData = warningTrendChartData.value.map((d: any) => d.date.substring(5, 10))
    let statusList1 = warningTrendChartData.value.map((d: any) => d.statusList1)
    let statusList2 = warningTrendChartData.value.map((d: any) => d.statusList2)
    let statusList3 = warningTrendChartData.value.map((d: any) => d.statusList3)

    let option = {
        // color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            }
        },
        grid: {
            top: '3%',
            left: '3%',
            right: '5%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            trigger: 'axis'
        },
        dataZoom: [{ type: 'inside' }],
        legend: {
            show: false,
        },
        xAxis: [
            {
                show: true,
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: xData,
                splitLine: {
                    show: false,
                    lineStyle: {
                        opacity: 0.3
                    }
                },
                axisLabel: {
                    color: '#fff',
                    fontSize: 12,
                    showMaxLabel: true
                },
            }
        ],
        yAxis: {
            type: 'value',
            scale: true,
            splitLine: {    //网格线
                lineStyle: {
                    color: 'rgba(255,255,255,0.5)',
                    type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                },
                show: true //隐藏或显示
            },
        },

        series: [
            {
                name: langObj.value['可用报警'],
                type: 'line',
                data: statusList1,
                itemStyle: {
                    color: '#d2de49'
                }
            },
            {
                name: langObj.value['警戒报警'],
                type: 'line',
                data: statusList2,
                itemStyle: {
                    color: '#cd5f3b'
                }
            },
            {
                name: langObj.value['故障报警'],
                type: 'line',
                data: statusList3,
                itemStyle: {
                    color: '#921e37'
                }
            }
        ]
    };

    myChart && myChart.setOption(option)
}


// 设备列表弹框
let machineModelSearch = ref({
    condition: null,
    status: null,
    groupId: null,
    keyWord: '',
})
let machineVisible = ref(false)
let machineDataList: any = ref([])

let conditionList = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用", value: 1, color: "#d2de49" },
    { label: "可用", value: 2, color: "#d2de49" },
    { label: "警戒", value: 3, color: "#cd5f3b" },
    { label: "警戒", value: 4, color: "#cd5f3b" },
    { label: "警戒", value: 5, color: "#cd5f3b" },
    { label: "故障", value: 6, color: "#921e37" },
    { label: "故障", value: 7, color: "#921e37" },
];

let conditionLists = [
    { label: getLanguage("健康", language.value), value: 1, realValue: [0] },
    { label: getLanguage("可用", language.value), value: 2, realValue: [1, 2] },
    { label: getLanguage("警戒", language.value), value: 3, realValue: [3, 4, 5] },
    { label: getLanguage("故障", language.value), value: 4, realValue: [6, 7] },
];

let statusList = [
    { label: getLanguage("运行", language.value), value: 1, color: "#0AF9F6" },
    { label: getLanguage("停机", language.value), value: 0, color: "rgb(162, 166, 171)" },
];

let whetherList = [
    { label: "是", value: true },
    { label: "否", value: false },
];

const machineColumns = [
    {
        title: getLanguage('设备编号', language.value),
        dataIndex: "machineCode",
    },
    {
        title: getLanguage('设备名称', language.value),
        dataIndex: "machineName",
    },
    {
        title: getLanguage('所属工厂', language.value),
        dataIndex: "factoryId",
    },
    {
        title: getLanguage('设备区域', language.value),
        dataIndex: "groupId",
    },
    {
        title: getLanguage('设备位置', language.value),
        dataIndex: "position",
    },
    {
        title: getLanguage('设备厂家', language.value),
        dataIndex: "supplier",
    },
    {
        title: getLanguage('设备型号', language.value),
        dataIndex: "model",
    },
    {
        title: getLanguage('设备类型', language.value),
        dataIndex: "machineTypeAll",
    },
    {
        title: getLanguage('设备SN号', language.value),
        dataIndex: "sn",
    },
    {
        title: getLanguage('巡检序号', language.value),
        dataIndex: "sort",
    },
    {
        title: getLanguage('设备描述', language.value),
        dataIndex: "remark",
    },
    {
        title: getLanguage('健康状态', language.value),
        dataIndex: "condition",
    },
    {
        title: getLanguage('运行状态', language.value),
        dataIndex: "status",
    },
    //   {
    //     title: "操作",
    //     dataIndex: "act",
    //   },
];

const selectMachineCondition = (condition: any) => {
    resetMachine()
    machineModelSearch.value.condition = condition
    machineVisible.value = true
    getDataMachine()
}

const selectMachineStatus = (status: any) => {
    resetMachine()
    machineModelSearch.value.status = status
    machineVisible.value = true
    getDataMachine()
}


const resetMachine = () => {
    machineModelSearch.value = {
        condition: null,
        status: null,
        groupId: null,
        keyWord: '',
    }
}

const getDataMachine = async () => {
    let config: any = {
        params: {
            groupId: machineModelSearch.value.groupId,
            status: machineModelSearch.value.status,
            factoryId: localStorage.getItem("factory_id"),
            keyWord: machineModelSearch.value.keyWord,
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (machineModelSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
            (p: any) => p.value === machineModelSearch.value.condition
        )?.realValue;
    }

    let result = await request("/api/machines", config);

    if (result && result.data) {
        machineDataList.value = result.data
    } else {
        machineDataList.value = []
    }
}

// 获取设备健康状态
const getMachienCondition = (ev: any) => {
    if (ev && ev.workStatus && ev.workStatus.stableCondition) {
        return conditionList.find(
            (p: any) => p.value === ev.workStatus.stableCondition
        );
    } else {
        return conditionList[0];
    }
};

// 获取设备运行状态
const getMachienStatus = (ev: any) => {
    if (ev && ev.workStatus && ev.workStatus.scoreWkStatus) {
        return statusList.find((p: any) => p.value === ev.workStatus.scoreWkStatus);
    } else {
        return statusList[1];
    }
};

const getRowClassName = (record: any, index: any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? "even-row" : "odd-row";
};



// 设备报警列表弹窗
let warnVisible = ref(false)
let warnSearch: any = ref({
    groupId: null,
})

const selectWarningStatus = (condition: any) => {

    // warnVisible.value = true
}

</script>

<style lang="less" scoped>
.enW{
    width: 150px;
}

/deep/ .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #04165D;
    border: 1px solid #0D53B7;
    color: #fff;
}

/deep/ .ant-select-arrow {
    color: #fff
}


.title {
    width: 350px;
    height: 38px;
    font-size: 20px;
    color: #06DEF5;
    background: linear-gradient(90deg, #1c39c88f 0%, #00148d00 100%);

    .shu {
        width: 2px;
        height: 20px;
        background-color: #fff;
    }
}

.title2 {
    padding: 10px 100px;
    font-size: 40px;
    color: #06DEF5;
    background: linear-gradient(90deg, #00148d00 1%, #1c39c88f 48%, #00148d00 100%);
}

.box1 {
    width: 230px;
    height: 80px;
    position: relative;

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 10px;
        color: #06DEF5;
    }
}
</style>