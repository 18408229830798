import { memberStore } from './state';
import { RouteLocationNormalized } from "vue-router";


export function menuGuard(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    if(to.fullPath.includes('/report/mwDetail/')) return true
    let member: any = memberStore.member;
    if (!member || !member.menus || member.menus.length == 0) return "/forbidden-403";

    if( member.menus.findIndex((t: any) => t.route === to.path) > -1) return true;
    return "/forbidden-403";
}
