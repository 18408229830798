<template>
  <div style="padding:0px 0;height: 100%;">
    <iframe :src="query.url" id="mf" class="mf"  ref="ifream"></iframe>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { computed, onMounted,watch,ref } from "vue";
const router = useRouter();

let query =  router.currentRoute.value.query
// 在组件加载完成后执行一些操作
onMounted(() => {
  
});
const ifream = ref<any>();
watch(
  () => router.currentRoute.value.query,
  (newValue: any) => {
    query =  router.currentRoute.value.query
    
  },
  { immediate: true }
)

</script>
<style scoped>
.mf{
  width: 100%;
  height: 100%;
}
</style>
