import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68de4312"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cardHead" }
const _hoisted_2 = { style: {"position":"relative","top":"4px","left":"5px"} }
const _hoisted_3 = {
  class: "main",
  style: {"max-height":"1450px","overflow-y":"scroll"}
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { style: {"max-height":"600px","overflow-y":"scroll"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node_index_outlined = _resolveComponent("node-index-outlined")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _ctx.language === 'English' ? _ctx.enUS : _ctx.zhCN
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_a_card, { bordered: false }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_node_index_outlined),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.threshold.sensorPlace), 1)
            ])
          ]),
          extra: _withCtx(() => [
            _createVNode(_component_a_radio_group, { size: "default" }, {
              default: _withCtx(() => [
                (_ctx.formState.activeKey == 'Component')
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 0,
                      value: "b",
                      onClick: _ctx.add
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['新增']), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 1,
                      value: "c",
                      onClick: _ctx.copy
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['复制']), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 2,
                      value: "c",
                      onClick: _ctx.pasteOverlay
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['粘贴覆盖']), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.formState.activeKey == 'Component')
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 3,
                      value: "d",
                      onClick: _ctx.pasteAppend
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['粘贴追加']), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.isEdit)
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 4,
                      value: "c",
                      onClick: _ctx.qX
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['取消']), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 5,
                      value: "c",
                      disabled: !(_ctx.baseModelSource&&_ctx.baseModelSource.length),
                      onClick: _ctx.edit
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['编辑']), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.isEdit)
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 6,
                      value: "c",
                      onClick: _ctx.editSave
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['保存']), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.formState.activeKey == 'Component')
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 7,
                      value: "d",
                      onClick: _ctx.deleteThis
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['删除']), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_tabs, {
          activeKey: _ctx.formState.activeKey,
          "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.activeKey) = $event)),
          onChange: _ctx.tabsChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, {
              key: "Common",
              tab: _ctx.langObj['振动参数'],
              disabled: _ctx.isEdit
            }, null, 8, ["tab", "disabled"]),
            _createVNode(_component_a_tab_pane, {
              key: "Component",
              tab: _ctx.langObj['部件参数'],
              "force-render": "",
              disabled: _ctx.isEdit
            }, null, 8, ["tab", "disabled"])
          ]),
          _: 1
        }, 8, ["activeKey", "onChange"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_table, {
            bordered: "",
            emptyText: '暂无数据',
            columns: _ctx.columns,
            "data-source": _ctx.baseModelSource,
            pagination: false,
            "row-selection": {
          selectedRowKeys: _ctx.mainSelectedRowKeys,
          onChange: _ctx.onMainSelectChange,
        },
            onChange: _ctx.onMainSelectChange
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'code')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (!_ctx.isEdit)
                      ? (_openBlock(), _createBlock(_component_a_tag, {
                          key: 0,
                          color: "green"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(record.code) + ":" + _toDisplayString(record.paramValue), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_a_form, { model: record }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, {
                                label: record.code,
                                name: "paramValue",
                                rules: [{ required: record.required, message: _ctx.langObj['请输入']+record.code }]
                              }, {
                                default: _withCtx(() => [
                                  (record.dataType == 'radio')
                                    ? (_openBlock(), _createBlock(_component_a_radio_group, {
                                        key: 0,
                                        value: record.paramValue,
                                        "onUpdate:value": ($event: any) => ((record.paramValue) = $event)
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.examples, (item) => {
                                            return (_openBlock(), _createBlock(_component_a_radio, {
                                              value: item,
                                              key: item
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(item), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["value"]))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1032, ["value", "onUpdate:value"]))
                                    : (record.dataType == 'int')
                                      ? (_openBlock(), _createBlock(_component_a_input_number, {
                                          key: 1,
                                          value: record.paramValue,
                                          "onUpdate:value": ($event: any) => ((record.paramValue) = $event),
                                          placeholder: _ctx.langObj['请输入'],
                                          step: 1
                                        }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                      : (record.dataType == 'float')
                                        ? (_openBlock(), _createBlock(_component_a_input_number, {
                                            key: 2,
                                            value: record.paramValue,
                                            "onUpdate:value": ($event: any) => ((record.paramValue) = $event),
                                            placeholder: _ctx.langObj['请输入'],
                                            step: 0.01
                                          }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                        : (_openBlock(), _createBlock(_component_a_input, {
                                            key: 3,
                                            type: "text",
                                            value: record.paramValue,
                                            "onUpdate:value": ($event: any) => ((record.paramValue) = $event),
                                            placeholder: _ctx.langObj['请输入']
                                          }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                ]),
                                _: 2
                              }, 1032, ["label", "rules"])
                            ]),
                            _: 2
                          }, 1032, ["model"])
                        ]))
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["columns", "data-source", "row-selection", "onChange"])
        ]),
        _createVNode(_component_a_modal, {
          width: 1000,
          maskClosable: false,
          visible: _ctx.modalState.visible,
          "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalState.visible) = $event)),
          title: _ctx.modalState.title,
          "confirm-loading": _ctx.modalState.confirmLoading,
          onOk: _ctx.handleOk
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_a_table, {
                class: "modal-table",
                bordered: "",
                columns: _ctx.columnsZB,
                pagination: false,
                "data-source": _ctx.baseMData
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'params')
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(text, (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_tag, {
                          color: "#2db7f5",
                          key: index
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name) + "-" + _toDisplayString(item.explan), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'max_num')
                    ? (_openBlock(), _createBlock(_component_a_radio_group, {
                        key: 1,
                        value: record.count,
                        "onUpdate:value": ($event: any) => ((record.count) = $event),
                        name: "radioGroup"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_radio, { value: "0" }, {
                            default: _withCtx(() => [
                              _createTextVNode("0")
                            ]),
                            _: 1
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.max_num, (it, index2) => {
                            return (_openBlock(), _createBlock(_component_a_radio, {
                              value: it,
                              key: index2
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(it), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["value", "onUpdate:value"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["columns", "data-source"])
            ])
          ]),
          _: 1
        }, 8, ["visible", "title", "confirm-loading", "onOk"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}