<template>
  <div>
    <a-layout-content style="padding: 0 20px">
      <a-form layout="inline">
        <a-form-item label="工厂">
          <a-select @change="selectFactory" allowClear :showSearch="true" v-model:value="searchModel.factoryId"
                    style="width: 250px">
            <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{
                item.factoryName
              }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="区域">
          <a-select @change="selectGroup" allowClear :showSearch="true" v-model:value="searchModel.groupId"
                    style="width: 250px">
            <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">{{
                item.name
              }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="设备">
          <a-select allowClear :showSearch="true" v-model:value="searchModel.machineId" style="width: 250px">
            <a-select-option v-for="(item, index) in machineList" :key="index" :value="item.id">{{
                item.machineName
              }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <!-- <a-form-item label="诊断经理">
            <a-select allowClear :showSearch="true" v-model:value="searchModel.diagnosticManager" style="width: 180px">
                <a-select-option v-for="(item, index) in diagnosticManagerList" :key="index" :value="item._id">{{ item.userName }}</a-select-option>
            </a-select>
        </a-form-item>

        <a-form-item label="客户经理">
            <a-select allowClear :showSearch="true" v-model:value="searchModel.accountManager" style="width: 180px">
                <a-select-option v-for="(item, index) in accountManagerList" :key="index" :value="item._id">{{ item.userName }}</a-select-option>
            </a-select>
        </a-form-item> -->

        <a-form-item>
          <a-button type="primary" @click="search">筛选</a-button>
        </a-form-item>

      </a-form>
      <div class="main">
        <a-table bordered emptyText="暂无数据" :columns="columns" :data-source="dataList" :pagination="pagination"
                 @change="handleTableChange"
                 size="small">
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'factoryId'">
              {{ getFactoryName(text) }}
            </template>

            <template v-if="column.dataIndex === 'groupId'">
              {{ getGroupName(text) }}
            </template>

            <template v-if="column.dataIndex === 'diagnosticManager'">
              {{ getManager(text, 1) }}
            </template>

            <template v-if="column.dataIndex === 'accountManager'">
              {{ getManager(text, 2) }}
            </template>

            <template v-if="column.dataIndex === 'accountSupervisor'">
              {{ getManager(record.accountSupervisor, 2) }}
            </template>

            <template v-if="column.dataIndex === 'act'">
              <a @click="edit(record)">编辑</a>
            </template>
          </template>
        </a-table>
      </div>
    </a-layout-content>


    <a-modal
        :width="850" :maskClosable="false"
        v-model:visible="visible" title="编辑"
        @ok="submit">
      <a-form :model="form" autocomplete="off" :label-col="{ style: { width: '150px' } }">
        <a-form-item label="设备名称" name="machineName">
          <a-input v-model:value="form.machineName" readonly/>
        </a-form-item>

        <a-form-item label="诊断经理" name="diagnosticManager" required>
          <a-select allowClear :showSearch="true" v-model:value="form.diagnosticManager">
            <a-select-option v-for="(item, index) in diagnosticManagerList" :key="index" :value="item._id">
              {{ item.nickName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="客户经理" name="accountManager" required>
          <a-select allowClear :showSearch="true" v-model:value="form.accountManager">
            <a-select-option v-for="(item, index) in accountManagerList" :key="index" :value="item._id">{{
                item.nickName
              }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="客户主管" name="accountSupervisor" required>
          <a-select allowClear :showSearch="true" v-model:value="form.accountSupervisor">
            <a-select-option v-for="(item, index) in accountManagerList" :key="index" :value="item._id">
              {{ item.nickName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>


  </div>
</template>


<script lang="ts" setup>
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "vue-router";
import { computed, defineComponent, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { TableProps } from "ant-design-vue";
import { message } from "ant-design-vue";
import { getGroupListByFacID, getFactoryName, getGroupName } from "../../common/tools";

let searchModel: any = ref({
  factoryId: '',
  machineId: '',
  groupId: '',
  skip: 1,
  take: 20
})
let total: any = ref(0)
let pagination = computed(() => ({
  total: total.value,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let dataList: any = ref([])
const columns = [
  {
    title: '项目',
    dataIndex: "factoryId",
    align: "center"
  },
  {
    title: '区域',
    dataIndex: "groupId",
    align: "center"
  },
  {
    title: '设备',
    dataIndex: "machineName",
    align: "center"
  },
  {
    title: '诊断经理',
    dataIndex: "diagnosticManager",
    align: "center"
  },
  {
    title: '客户经理',
    dataIndex: "accountManager",
    align: "center"
  },
  {
    title: '客户主管',
    dataIndex: "accountSupervisor",
    align: "center"
  },
  {
    title: '操作',
    dataIndex: "act",
    align: "center"
  }
];

let factoryList: any = ref([])
let groupList: any = ref([])
let machineList: any = ref([])
let visible: any = ref(false)
let form: any = ref({
  machineId: '',
  factory: '',
  diagnosticManager: '',
  accountManager: '',
  accountSupervisor: ''
})
let diagnosticManagerList: any = ref([])
let accountManagerList: any = ref([])

onMounted(() => {
  init()
})

const init = () => {
  getManagers()
  getFactoryList()
  search()
}

// 获取诊断/客户经理列表
const getManagers = async () => {
  const config = {
    params: {
      factoryId: "65e29561f747d7e32bbed049",
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let resultRole = await request.get("/api/roles", config)
  let rolelist: any = []
  if (resultRole?.data?.list) {
    rolelist = resultRole.data.list
  }
  let diagnosticManagerRoleId = rolelist.find((p: any) => p.name === '诊断工程师')?.id
  let accountManagerRoleId = rolelist.find((p: any) => p.name === '客户支持')?.id

  if (diagnosticManagerRoleId) {
    let config2 = {
      params: {
        roleId: diagnosticManagerRoleId,
        factoryId: "65e29561f747d7e32bbed049",
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result2 = await request.get("/api/members", config2)
    if (result2?.data?.list) {
      diagnosticManagerList.value = result2.data.list
    }
  }

  if (accountManagerRoleId) {
    let config2 = {
      params: {
        roleId: accountManagerRoleId,
        factoryId: "65e29561f747d7e32bbed049",
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result2 = await request.get("/api/members", config2)
    if (result2?.data?.list) {
      accountManagerList.value = result2.data.list
    }
  }
}

// 获取工厂列表
const getFactoryList = async () => {
  let result = await request.get('/api/factories')
  if (result?.data) {
    factoryList.value = result.data
  }
}

// 选择工厂
const selectFactory = (ev: any) => {
  if (ev) {
    groupList.value = getGroupListByFacID(ev)
  } else {
    groupList.value = []
    machineList.value = []
    searchModel.value.groupId = ''
    searchModel.value.machineId = ''
  }
}

// 选择区域
const selectGroup = async (ev: any) => {
  const config = {
    params: {
      factoryId: searchModel.value.factoryId,
      groupId: searchModel.value.groupId,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines", config)
  if (result?.data) {
    machineList.value = result.data
  }
}


const search = async () => {
  const config = {
    params: {
      factoryId: searchModel.value.factoryId,
      groupId: searchModel.value.groupId,
      machineId: searchModel.value.machineId,
      skip: searchModel.value.skip,
      take: searchModel.value.take
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/principal", config)

  if (result?.data?.list) {
    dataList.value = result.data.list
    total.value = result.data.total
  }
}

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  search();
};

const edit = (ev: any) => {
  form.value = JSON.parse(JSON.stringify(ev))
  visible.value = true
}

const submit = async () => {

  let result = form.value.id ? await request.put('/api/principal', form.value) : await request.post('/api/principal', form.value)
  if (result?.status == 200) {
    message.success('操作成功')
    visible.value = false
    search()
  } else {
    message.warning('操作失败')
  }
}


const getManager = (ev: any, type: any) => {
  if (type == 1) {
    return diagnosticManagerList.value.find((p: any) => p._id === ev)?.nickName
  }
  if (type == 2) {
    return accountManagerList.value.find((p: any) => p._id === ev)?.nickName
  }
}

</script>
