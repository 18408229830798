<template>
  <a-form
    :model="formState"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 18 }"
  >
    <a-form-item label="创建时间" required>
      <a-date-picker
        v-model:value="formState.date"
        show-time
        placeholder="请选择创建时间"
      />
    </a-form-item>

    <a-form-item label="设备">
      <a-input v-model:value="formState.machineName" readonly />
    </a-form-item>

    <a-form-item label="故障模式">
      <a-cascader
        class="w-full"
        v-model:value="formState.faultEvent"
        :options="cascaderOptions"
        :show-search="{ filter }"
      ></a-cascader>
    </a-form-item>

    <a-form-item label="严重程度">
      <div class="w-full flex items-center">
        <a-select v-model:value="formState.grade" placeholder="请选择设备">
          <a-select-option
            v-for="(item, index) in conditionList"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
        <a-button style="margin-left: 30px" type="primary" @click="addItem()"
          >添加</a-button
        >
      </div>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 22, offset: 0 }">
      <div class="w-full" style="color: #fff">
        <div
          v-for="(item, i) in formState.diagnosisDetails"
          :key="i"
          style="width: 100%; margin-top: 10px"
        >
          <div style="width: 100%; padding: 0 16px 0 60px; display: flex">
            <div style="width: 100px">
              <a-popover placement="top">
                <template #content>
                  <div class="flex items-center">
                    <div
                      style="
                        padding: 5px;
                        cursor: pointer;
                        border: 2px solid #61c08f;
                        color: #61c08f;
                      "
                      @click="changeGrade(i, 0)"
                    >
                      <span>{{
                        formState.diagnosisDetails[i].faultEvent
                      }}</span>
                    </div>
                    <div
                      class="ml-4"
                      style="
                        padding: 5px;
                        cursor: pointer;
                        border: 2px solid #d2de49;
                        color: #d2de49;
                      "
                      @click="changeGrade(i, 1)"
                    >
                      <span>{{
                        formState.diagnosisDetails[i].faultEvent
                      }}</span>
                    </div>
                    <div
                      class="ml-4"
                      style="
                        padding: 5px;
                        cursor: pointer;
                        border: 2px solid #cd5f3b;
                        color: #cd5f3b;
                      "
                      @click="changeGrade(i, 3)"
                    >
                      <span>{{
                        formState.diagnosisDetails[i].faultEvent
                      }}</span>
                    </div>
                    <div
                      class="ml-4"
                      style="
                        padding: 5px;
                        cursor: pointer;
                        border: 2px solid #921e37;
                        color: #921e37;
                      "
                      @click="changeGrade(i, 6)"
                    >
                      <span>{{
                        formState.diagnosisDetails[i].faultEvent
                      }}</span>
                    </div>
                  </div>
                </template>

                <div
                  :style="{ border: loadColor(i)[0], color: loadColor(i)[1] }"
                  style="
                    text-align: center;
                    padding: 5px 0 5px 0;
                    margin-bottom: 10px;
                  "
                  nz-popover
                  nzPopoverTrigger="click"
                >
                  <span>{{ formState.diagnosisDetails[i].faultEvent }}</span>
                </div>
              </a-popover>
              <div>
                <CloseSquareOutlined
                  @click="del(i)"
                  style="cursor: pointer; font-size: 25px"
                />
              </div>
            </div>
            <div style="flex: 1; padding-left: 5px">
              <a-textarea
                v-model:value="item.diagnoseMessage"
                placeholder=""
                :rows="4"
              />
            </div>
          </div>

          <div class="w-full mt-1 mb-1" style="padding: 0 16px 0 164px">
            <div class="card p-2 w-full flex">
              <div class="flex-1">
                <div style="font-weight: 700">故障原因:</div>
                <a-textarea
                  v-model:value="item.faultCause"
                  placeholder=""
                  :rows="4"
                />
              </div>
              <div class="flex-1">
                <div style="font-weight: 700">建议及措施:</div>
                <a-textarea
                  v-model:value="item.faultSuggest"
                  placeholder=""
                  :rows="4"
                />
              </div>
            </div>
          </div>

          <div style="width: 100%; padding: 0 16px 0 44px; display: flex">
            <div style="width: 120px"></div>
            <div style="flex: 1">
              <div class="card w100">
                <div class="card-title flex">
                  <span>前台分数</span>
                  <div style="flex: 1"></div>
                  <a @click="openHis(i)">添加</a>
                </div>
                <!-- <div class="card-title flex">
                  <span>后台指标</span>
                  <div style="flex: 1"></div>
                  <a @click="openHisD(i)">添加</a>
                </div> -->
                <div>
                  <div
                    v-for="(scoreItem, j) in item.scoreData"
                    :key="j"
                    class="his-list flex items-center"
                  >
                    <span
                      @click="watchScore(scoreItem)"
                      style="cursor: pointer; margin-right: 5px"
                      >{{ scoreItem.title }}</span
                    >
                    <div class="flex-1"></div>
                    <a-button type="primary" @click="deletScore(i, j)">
                      删除
                    </a-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-form-item>
    <a-form-item label="图片" name="pic">
      <a-upload
        v-model:file-list="fileList"
        list-type="picture-card"
        @preview="handlePreview"
        :custom-request="customRequest"
        :auto-upload="false"
        accept="image"
        :max-count="6"
      >
        <div v-if="!fileList || fileList.length < 9">
          <plus-outlined />
          <div style="margin-top: 8px">上传</div>
        </div>
      </a-upload>
      <a-modal
        :visible="previewVisible"
        :title="previewTitle"
        :footer="null"
        @cancel="handleCancel"
      >
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
      <div v-for="(item, index) in fileList" :key="index">
        <a-row style="border: 1px solid #eee">
          <a-col :span="6"
            ><a-image
              alt="example"
              style="width: 80px; height: 80px; margin-left: 20px"
              :src="item.url"
          /></a-col>
          <a-col :span="14">
            <a-textarea
              style="margin-top: 15px"
              type="textarea"
              v-model:value="item.message"
              placeholder="请输入"
            />
          </a-col>
          <a-col :span="4" @click="deleteThis(index)">
            <DeleteOutlined style="font-size: 25px; margin: 28px" />
          </a-col>
        </a-row>
      </div>
    </a-form-item>

    <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
      <a-button type="primary" @click="submit">提交</a-button>
    </a-form-item>
  </a-form>

  <a-modal
    v-model:visible="isHisVisible"
    title="截取前台指标"
    :footer="null"
    :maskClosable="false"
    width="1200px"
    :zIndex="1002"
  >
    <selectHisComponent
      @sendForm="getForm"
      :machineInfo="machineInfo"
    ></selectHisComponent>
  </a-modal>
  <a-modal
    v-model:visible="isHisDVisible"
    title="后台指标"
    :footer="null"
    :maskClosable="false"
    width="1200px"
    :zIndex="1002"
  >
    <selectScoreComponent
      @sendForm="getForm"
      :machineInfo="machineInfo"
    ></selectScoreComponent>
  </a-modal>
  <a-modal
    v-model:visible="chartVisible"
    title="查看分数图"
    :footer="null"
    :maskClosable="false"
    width="1200px"
  >
    <div id="scoreChart" style="min-width: 700px; height: 470px"></div>
  </a-modal>
</template>

<script lang="ts" setup>
import {
  ref,
  defineProps,
  watch,
  onMounted,
  defineEmits,
  defineExpose,
} from "vue";
import { v4 as uuidv4 } from "uuid";
import request from "../../../common/request";
import { message } from "ant-design-vue";
import selectHisComponent from "./select-histrend.vue";
import selectScoreComponent from "./select-score.vue";
import * as echarts from "echarts";
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';

let formState: any = ref({
  date: "",
  factoryID: "",
  machineId: "",
  faultEvent: "",
  suggestion: "",
  grade: null,
  sensorId: '',
  diagnosisDetails: [],
});

let factoryList: any = ref([]);
let machineList: any = ref([]);
let cascaderOptions: any = ref([]);
interface Option {
  value: string;
  label: string;
  disabled?: boolean;
  children?: Option[];
}
const filter = (inputValue: string, path: Option[]) => {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );
};
const conditionList = [
  { label: "健康", value: 0 },
  { label: "可用上", value: 1 },
  { label: "可用下", value: 2 },
  { label: "警戒上", value: 3 },
  { label: "警戒中", value: 4 },
  { label: "警戒下", value: 5 },
  { label: "故障上", value: 6 },
  { label: "故障下", value: 7 },
];

let machineInfo: any = null;
let isHisVisible = ref(false);
let isHisDVisible = ref(false);
let hisIndex: any = null;
let chartVisible = ref(false);

const props: any = defineProps({
  reportMachineInfo: null,
  reportCreateInfo: null,
});

watch(
  () => props.reportMachineInfo,
  (newValue, oldValue) => {
    if (props.reportMachineInfo) {
      init();
    }
  }
);

// 添加子集
const addItem = () => {
  let form = formState.value;
  if (!form.faultEvent || !form.faultEvent.length) {
    message.warning("请选择故障模式后再添加!");
    return;
  }
  if (!form.grade && form.grade != 0) {
    message.warning("请选择严重程度后再添加!");
    return;
  }

  let list1 =
    cascaderOptions.value.find((p: any) => p.value === form.faultEvent[0])
      ?.children || [];
  let fault = list1.find((p: any) => p.value === form.faultEvent[1]);

  formState.value.diagnosisDetails.push({
    faultEvent: form.faultEvent[1],
    grade: form.grade,
    sonId: new Date().getTime().toString(),
    faultCause: fault.faultCause,
    faultSuggest: fault.faultSuggest,
  });
};

// 删除子集元素
const del = (index: number) => {
  formState.value.diagnosisDetails.splice(index, 1);
};

// 获取颜色
const loadColor = (i: any) => {
  let border = "";
  let color = "";
  if (formState.value.diagnosisDetails[i].grade == 1) {
    border = "2px solid #d2de49";
    color = "#d2de49";
  }
  if (formState.value.diagnosisDetails[i].grade == 3) {
    border = "2px solid #cd5f3b";
    color = "#cd5f3b";
  }
  if (formState.value.diagnosisDetails[i].grade == 6) {
    border = "2px solid #921e37";
    color = "#921e37";
  }
  if (formState.value.diagnosisDetails[i].grade == 0) {
    border = "2px solid #61c08f";
    color = "#61c08f";
  }
  return [border, color];
};

// 获取故障模式列表
const getFaultModelList = () => {
  cascaderOptions.value = [];
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request
    .post("/api/faultinfos", { FeatureType: "" }, config)
    .then((res: any) => {
      if (res) {
        let list = res.data;
        let expects = list.filter(
          (item: { featureType: string }) => item.featureType === "Expect"
        );
        let commons = list.filter(
          (item: { featureType: string }) => item.featureType === "Common"
        );
        let others = list.filter(
          (item: { featureType: string }) => item.featureType === "Other"
        );
        let expectList: any[] = [];
        let commonsList: any[] = [];
        let othersList: any[] = [];
        expects.forEach((item: any) => {
          if (
            expectList.findIndex(
              (ele: any) => ele.value === item.segmentFault
            ) === -1
          ) {
            expectList.push({
              value: item.segmentFault,
              label: item.segmentFault,
              faultSuggest: item.faultSuggest,
              faultCause: item.faultCause,
            });
          }
        });

        commons.forEach((item: any) => {
          if (
            commonsList.findIndex(
              (ele: any) => ele.value === item.segmentFault
            ) === -1
          ) {
            commonsList.push({
              value: item.segmentFault,
              label: item.segmentFault,
              faultSuggest: item.faultSuggest,
              faultCause: item.faultCause,
            });
          }
        });

        others.forEach((item: any) => {
          if (
            othersList.findIndex(
              (ele: any) => ele.value === item.segmentFault
            ) === -1
          ) {
            othersList.push({
              value: item.segmentFault,
              label: item.segmentFault,
              faultSuggest: item?.faultSuggest,
              faultCause: item?.faultCause,
            });
          }
        });

        cascaderOptions.value = [
          {
            value: "专业指标",
            label: "专业指标",
            children: expectList,
          },
          {
            value: "振动指标",
            label: "振动指标",
            children: commonsList,
          },
          {
            value: "其他",
            label: "其他",
            children: othersList,
          },
        ];
      }
    });
};

// 状态修改
const changeGrade = (i: number, grade: number) => {
  formState.value.diagnosisDetails[i].grade = grade;
};

// 添加后台指标
//打开历史趋势选择
const openHis = (i: number) => {
  if (!formState.value.machineId) {
    message.warning("请选择设备");
    return;
  }

  let machine = {
    id: props.reportMachineInfo.id,
    machineName: props.reportMachineInfo.machineName,
  };
  machineInfo = machine;
  hisIndex = i;
  setTimeout(() => {
    isHisVisible.value = true;
  }, 500);
};
const openHisD = (i: number) => {
  if (!formState.value.machineId) {
    message.warning("请选择设备");
    return;
  }

  let machine = {
    id: props.reportMachineInfo.id,
    machineName: props.reportMachineInfo.machineName,
  };
  machineInfo = machine;
  hisIndex = i;
  setTimeout(() => {
    isHisDVisible.value = true;
  }, 500);
};


const getFactoryList = async () => {
  let result = await request.get("/api/members/info");
  result.data.factoryIds = result.data?.factories.map((t: any) => t.id);
  let memberInfo = result.data;
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result2 = await request.get("/api/factories", config);

  let list = result2.data.filter((p: any) =>
    memberInfo.factoryIds.includes(p._id)
  );

  let boldList = list.map((p: any) => p.parentId).filter((p: any) => p);

  boldList = [...new Set(boldList)];

  factoryList.value = list.filter((p: any) => !boldList.includes(p._id));
};

const selectFactory = async (ev: any) => {
  const config = {
    params: { factoryId: ev },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines", config);
  machineList.value = result.data;
};

const getForm = (ev: any) => {
  if (!formState.value.diagnosisDetails[hisIndex].scoreData) {
    formState.value.diagnosisDetails[hisIndex].scoreData = [];
  }
  formState.value.diagnosisDetails[hisIndex].scoreData.push(ev);
  isHisVisible.value = false;
};

// 查看chart
const watchScore = (ev: any) => {
  chartVisible.value = true;
  setTimeout(() => {
    initChart(ev);
  }, 500);
};

const initChart = (item: any) => {
  const { date, value, title, markLine } = item;
  const ec = echarts as any;
  let myChart = ec.init(document.getElementById("scoreChart"));
  let option = {
    title: {
      text: title,
      left: "40%",
      top: "0",
    },
    grid: {
      left: "6%",
      right: "2%",
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: date,
    },
    yAxis: {
      type: "value",
      scale: true,
      min: 0,
      max: 100,
      splitNumber: 5,
    },
    dataZoom: [
      {
        id: "dataZoomX",
        type: "slider",
        xAxisIndex: [0],
        filterMode: "filter",
        bottom: "0",
      },
      {
        type: "inside",
      },
    ],
    series: [
      {
        data: value,
        type: "line",
        markLine: markLine,
        connectNulls: true,
      },
    ],
  };

  option && myChart.setOption(option);
};

// 删除
const deletScore = (i: any, j: any) => {
  formState.value.diagnosisDetails[i].scoreData.splice(j, 1);
};

const emits = defineEmits(["status", "sendId"]);

// 提交
const submit = async () => {
  let form = formState.value;
  console.log('form', form)
  if(!form.date) {
    message.warning("请选择创建时间");
    return
  }
  
  let date = tranformDate(form.date);

  let content = "";
  // let diagnosisDetails =  form.diagnosisDetails || []
  let trendList:any = []
  form.diagnosisDetails &&
    form.diagnosisDetails.map((item: any) => {
      content = content + item.diagnoseMessage;
      item.scoreData &&
        item.scoreData.map((d: any) => {
          trendList.push({
            ...d,
            sonId: item.sonId
          })
          // subnitTrend(d, item.sonId);
        });
    });

  let trendResult = await request.put("/api/reports/trend", trendList);
  console.log('trendResult', trendResult)
  if(trendResult?.data) {} else {
    message.error('提交失败')
    return
  } 

  let diagnosisDetails = form.diagnosisDetails || [];

  let list = diagnosisDetails.map((p: any) => ({
    sonId: p.sonId,
    diagnoseMessage: p.diagnoseMessage,
    faultEvent: p.faultEvent,
    faultCause: p.faultCause,
    faultSuggest: p.faultSuggest,
    grade: p.grade,
  }));

  let submitForm = {
    date,
    factoryId: form.factoryID,
    sensorId: form.sensorId,
    // faultCause: form.faultCause,
    type: 1,
    title: "",
    content,
    machineIds: [form.machineId],
    expertReport: list,
    pictures: fileList.value.map((t: any) => ({
      url: t.url,
      remark: t.message,
    })),
    workflow: false,
  };

  console.log('submitForm', submitForm)
  // return

  let result = await request.post("/api/reports", submitForm);
  if (result && result.status === 200) {
    message.success("新增成功");
    // emits("status", true);
    emits("sendId", result.data);
  } else {
    message.warning("新增失败");
  }
};

const subnitTrend = async (item: any, sonId: any) => {
  let form = {
    date: item.date,
    title: item.title,
    value: item.value,
    markLine: item.markLine,
    sonId,
  };
  let result = await request.post("/api/reports/trend", form);
};

const tranformDate = (date: any) => {
  let time = new Date(date);
  let year = time.getFullYear();
  let month =
    time.getMonth() + 1 > 9 ? time.getMonth() + 1 : "0" + (time.getMonth() + 1);
  let day = time.getDate() > 9 ? time.getDate() : "0" + time.getDate();
  let hour = time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
  let min = time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes();
  let second =
    time.getSeconds() > 9 ? time.getSeconds() : "0" + time.getSeconds();
  return `${year}/${month}/${day} ${hour}:${min}:${second}`;
};

const init = () => {
  getFaultModelList();
  if(props.reportCreateInfo) {
    formState.value = props.reportCreateInfo
    formState.value.date = dayjs(props.reportCreateInfo.date, 'YYYY/MM/DD HH:mm:ss')
    // formState.value.date = moment(props.reportCreateInfo.date, 'YYYY/MM/DD HH:mm:ss') 
    formState.value.diagnosisDetails = props.reportCreateInfo.expertReport.diagnosisDetails
    formState.value.sensorId = props.reportCreateInfo?.expertReport?.sensorId
  } else {
    formState.value = {
      date: "",
      factoryID: "",
      machineId: "",
      faultEvent: "",
      grade: null,
      diagnosisDetails: [],
    }
  }

  if (props.reportMachineInfo) {
    setData();
  }
};

// 设置传参
const setData = () => {
  formState.value.machineName = props.reportMachineInfo.machineName;
  formState.value.machineId = props.reportMachineInfo.id;
  formState.value.factoryID = props.reportMachineInfo.factoryId;
  if(!props.reportCreateInfo) {
    formState.value.sensorId = props.reportMachineInfo.sensorId;
  }
};

onMounted(() => {
  init();
});

//**图片上传* */
let fileList = ref<any>([]);
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};
const deleteThis = (index: any) => {
  fileList.value.splice(index, 1);
};
const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};
</script>

<style lang="less" scoped>
/deep/ .ant-upload-list-picture-card-container {
  display: none !important;
}
.card {
  border: 1px solid #f0f0f0;
}

.w100 {
  width: 100%;
}

.card-title {
  border-bottom: 1px solid #f0f0f0;
  padding: 5px 16px 5px 16px;
}

.his-list {
  margin: 5px 16px 5px 16px;
}

.flex {
  display: flex;
}
</style>
