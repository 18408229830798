<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
  <div>
    <a-layout-content style="padding: 0 20px">
      <a-form layout="inline">
        <a-form-item :label="langObj['关键词']">
          <a-input v-model:value="formState.keyword" :placeholder="langObj['昵称/用户名/手机号']" />
        </a-form-item>
        <a-form-item :label="langObj['所属工厂']">
          <a-select ref="select" allowClear v-model:value="formState.factoryId" :options="factoryList"
            :placeholder="langObj['请选择所属工厂']" style="width: 180px">
          </a-select>
        </a-form-item>
        <a-form-item :label="langObj['是否启用']">
          <a-select ref="select" v-model:value="formState.enable" style="width: 120px">
            <a-select-option value="-1">{{langObj['全部']}}</a-select-option>
            <a-select-option value="1">{{langObj['是']}}</a-select-option>
            <a-select-option value="0">{{langObj['否']}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="formState.skip = 1, search()">{{langObj['搜索']}}</a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="add">
            <template #icon><plus-outlined /></template>{{langObj['新增']}}</a-button>
        </a-form-item>
      </a-form>
      <div class="main">
        <a-table bordered :emptyText="langObj['暂无数据']" :columns="columns" :data-source="dataSource" :pagination="pagination" @change="handleTableChange"
          :loading="loading" size="small">
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'act'">
              <a-button type="primary" @click="roleSetting(record)">{{langObj['配置']}}</a-button>
              <!-- <a-divider type="vertical" />
              <a-button type="primary" @click="roleSetting(record)">{{langObj['配置权限']}}</a-button> -->
            </template>
            <template v-else-if="column.dataIndex === 'roles'">
              <span>
                <a-tag v-for="tag in text" :key="tag" :color="'volcano'">
                  {{ tag.name }}
                </a-tag>
              </span>
            </template>
            <template v-else-if="column.dataIndex === 'enable'">
              <span>
                {{ text ? langObj['是'] : langObj['否'] }}
              </span>
            </template>
          </template>
        </a-table>
      </div>
    </a-layout-content>
    <a-modal :width="850" :maskClosable="false" v-model:visible="modalState.visible" :title="modalState.title"
    :okText="langObj['确定']"
    :cancelText="langObj['关闭']"
      :confirm-loading="modalState.confirmLoading" @ok="handleOk">
      <a-form :model="modalForm" ref="formStateRef" name="basic" autocomplete="off" :rules="rules"
        :label-col="{ style: { width: '150px' } }">
        <a-form-item :label="langObj['用户名']" required autocomplete="off" auto-complete="off">
          <a-input type="text" autocomplete="off" auto-complete="off" v-model:value="modalForm.userName" :placeholder="langObj['输入用户名']" @change="clearValidate('userName')" />
        </a-form-item>
        <a-form-item :label="langObj['昵称']" name="nickName">
          <a-input v-model:value="modalForm.nickName" :placeholder="langObj['输入昵称']" />
        </a-form-item>
        <a-form-item :label="langObj['所属工厂']" name="factoryId" required>
          <a-select ref="select" v-model:value="modalForm.factoryId" :options="factoryList" :placeholder="langObj['请选择所属工厂']"
            @change="selectFactory">
          </a-select>
        </a-form-item>
        <a-form-item :label="langObj['密码']" name="password">
          <a-input type="password" autocomplete="off" auto-complete="off" v-model:value="modalForm.password"
            :placeholder="modalForm.id ? langObj['不修改密码无需输入'] : langObj['请输入密码']" />
        </a-form-item>
        <a-form-item :label="langObj['手机号']" name="phone">
          <a-input v-model:value="modalForm.phone" :placeholder="langObj['请输入手机号']" />
        </a-form-item>
        <a-form-item :label="langObj['是否启用']" name="enable">
          <a-radio-group name="radioGroup" v-model:value="modalForm.enable">
            <a-radio :value="true">{{langObj['是']}}</a-radio>
            <a-radio :value="false">{{langObj['否']}}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :label="langObj['角色']" name="roleIds">
          <a-select ref="select" v-model:value="modalForm.roleIds" :placeholder="langObj['请选择角色']" mode="multiple"
            @change="clearValidate('role')">
            <a-select-option v-for="(item, index) in roleList" :key="index" :value="item.id">{{ item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label="langObj['菜单']" name="menuIds" @change="clearValidate('menuIds')"
          >
          <a-tree v-model:checkedKeys="modalForm.menusIds" checkable :tree-data="menuList">
            <template #title="{ title, key }">
              {{ langObj[title] }}
            </template>
          </a-tree>
        </a-form-item>
        <a-form-item :label="langObj['工厂权限']" name="menuIds" @change="clearValidate('menuIds')"
          >
          <a-tree v-model:checkedKeys="modalForm.powerIds" checkable :tree-data="factoryListS" @check="selectTree">
            <template #title="{ title, key }">
              {{ title }}
            </template>
          </a-tree>
        </a-form-item>


      </a-form>
    </a-modal>
  </div>
</a-config-provider>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "vue-router";
import { computed, defineComponent, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { TableProps } from "ant-design-vue";
import { message } from "ant-design-vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();
const loading = ref(false);
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
operateLogs('访问账号管理');
/**操作日志记录 */
const modalState = reactive({
  visible: false,
  title: "配置",
  confirmLoading: false,
});
let formState = reactive({
  factoryId: '',
  keyword: "",
  enable: "-1",
  skip: 1,
  take: 10,
});

const rules = {
    password: [
      {
        max: 22,
        min: 6,
        required: true,
        pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{12,}$/),
        message:
          "密码至少包含数字，大小写字母和字符的四种组合，长度在12-22之间",
      },
    ],
  }; 

// 新增
const add = () => {
  rules.password[0].required = true;
  modalState.title = langObj.value["新增"];
  modalForm.value = {
    enable: true,
    factoryId: "",
    id: 0,
    nickName: "",
    userName: "",
    password: '',
    phone: '',
  };
  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate()
  }, 200);
};
let total = ref(0)
let pagination = computed(() => ({
  total: total.value,
  current: formState.skip,
  pageSize: formState.take,
}));

let dataSource = ref([]);
const columns = [
  {
    title: langObj.value["昵称"],
    dataIndex: "nickName",
    align: "center"
  },
  {
    title: langObj.value["用户名"],
    dataIndex: "userName",
    align: "center"
  },
  {
    title: langObj.value["所属工厂"],
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: langObj.value["角色"],
    dataIndex: "roles",
    align: "center"
  },
  {
    title: langObj.value["是否启用"],
    dataIndex: "enable",
    align: "center"
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
    align: "center"
  },
];
const handleTableChange = (pagination:any, filters:any, sorter:any) => {
  console.log('pagination', pagination)
        formState.skip = pagination.current 
        formState.take = pagination.pageSize 
        search();
  };

const checkedNodes = ref<any>([])
const selectTree = (key: any, val: any) => {
  checkedNodes.value = val.checkedNodes
};

// 保存
const handleOk = () => {
  formStateRef.value
    .validate()
    .then((result: any) => {
      let postForm = JSON.parse(JSON.stringify(modalForm.value))

      let groupsList:any = [] 
      allFactory.value.map((d:any) => {
        // groupsList = groupsList.concat(d.groups)
        if(d?.groups?.length > 0) {
          groupsList = groupsList.concat(d.groups.map((p:any) => ({
            name: p.name,
            sonID: p.sonID,
            factoryId: d._id,
            factoryParentId: d.parentId,
          })))
        }
      })

      let factoryIds:any = []
      let groupIds:any = []

      postForm.powerIds && postForm.powerIds.map((d:any) => {
        let info = groupsList.find((p:any) => p.sonID === d)
        if(info) {
          groupIds.push(d)
          factoryIds.push(info.factoryId)
          if(info.factoryParentId) {
            factoryIds.push(info.factoryParentId)
          }
        }
      })

      factoryIds = [...new Set(factoryIds)]
      groupIds = [...new Set(groupIds)]

      postForm.factoryIds = factoryIds
      postForm.groupIds = groupIds

      postForm.menusIds = postForm.menusIds || []
      let menuIds:any = []
      postForm.menusIds.map((d:any) => {
        menuIds.push(d)
        let p = menuListAll.value.find((p:any) => p.id === d)
        if(p?.parentId !== '0') {
          menuIds.push(p?.parentId)
        }
      })
      postForm.menusIds = [... new Set(menuIds)]
      postForm.platform = 2

      if (postForm.id) {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.put("/api/member", postForm, config).then((res) => {
          if (res) {
            operateLogs('修改账号，账号名称：' + postForm.userName + ",id:" + postForm.id);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      } else {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.post("/api/member", postForm, config).then((res) => {
          if (res) {
            operateLogs('新增账号，账号名称：' + postForm.userName);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });

};

let modalForm:any = ref<any>({
  enable: true,
  factoryId: "",
  id: 0,
  nickName: "",
  userName: "",
  phone: '',

});
const formStateRef = ref<any>();
const roleSetting = async (val: any) => {
  rules.password[0].required = false;
  delete val.password
  if(val?.factoryId) {
    getRole(val.factoryId)
  }
  modalState.title = "配置";

  modalForm.value = JSON.parse(JSON.stringify(val))
  if(modalForm.value?.roles?.length > 0) {
    modalForm.value.roleIds = modalForm.value.roles.map((d:any) => d.id)
  } else {
    modalForm.value.roleIds = []
  }
  if(val.memberPowersInfo) {
    modalForm.value.powerIds = val.memberPowersInfo.groupIds
    modalForm.value.menusIds = val.memberPowersInfo.menusIds
  }

  let index = modalForm.value.menusIds?.length ? modalForm.value.menusIds.length - 1 : -1

  for (let i = index; i >= 0; i--) {
    const element = modalForm.value.menusIds[i];
    let parentInfo = menuList.value.find((p:any) => p.id === element)
    if(parentInfo) {
      modalForm.value.menusIds.splice(i, 1)
    }
  }

  modalForm.value.id = modalForm.value._id
  delete modalForm.value._id
  

  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500);
};
const clearValidate = (name: any) => {
  formStateRef.value.clearValidate(name);
};

// 查询
const search = () => {
  const config = {
    params: formState,
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/members", config).then((res) => {
    if (res) {
      
      dataSource.value = res.data.list;
      total.value = res.data.count;
    }
  });
};

search();
const roleList = ref<any>();

const selectFactory = (ev:any) => {
  console.log('ev', ev)
  getRole(ev)
}

let menuList = ref<any>();
let menuListAll = ref<any>();
let menuListNotLevel = ref<any>([]);
const getMenu = () => {
  request.get("/api/menus").then((res: any) => {
    if (res) {
      menuListAll.value = res.data
      res.data = res.data.map((t: any) => ({
        ...t,
        key: t.id,
      }));
      menuListNotLevel.value = res.data;
      var oneLevel = res.data.filter((t: any) => t.parentId == 0);
      oneLevel.forEach((t: any) => {
        var list = res.data.filter((t2: any) => t2.parentId == t.id);
        if (list.length) {
          t.children = list;
        }
      });
      menuList.value = oneLevel;
    }
  });
};
getMenu();




// 查询
const getRole = (factoryId:any) => {
  const config = {
    params: { factoryId, skip: 1, take: 1000 },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/roles", config).then((res: any) => {
    if (res) {
      roleList.value = res.data.list;
    }
  });
};
let factoryList = ref<any>()
const loadFactory = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {

      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory"
      }));
      // let factory = [];
      // for (var i = 0; i < list.length; i++) {
      //   var element = list[i];
      //   if (!element.parentId) {
      //     element.children = list.filter((t: any) => t.parentId == element.id);
      //     factory.push(element);
      //   }
      // }
      factoryList.value = list;
    }
  });
};
loadFactory()


let factoryListS = ref<any>();

let allFactory = ref<any>([]);
let allGroup = ref<any>([]);
let allMachine = ref<any>([]);

const loadFactorys = () => {
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      res.data.forEach((element: any) => {
        allGroup.value = allGroup.value.concat(element.groups)
      });
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: 'factory',
        children: t.groups.map((group: any) => ({
          type: 'group',
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
          factoryId: t._id,
          factoryPId: t.parentId,
        })),
      }));
      let factory:any = [];
      let parentFs = list.filter((p:any) => !p.parentId)
      parentFs.map((d:any) => {
        let sonFactory = list.filter((p:any) => p.parentId === d.id)
        if(sonFactory && sonFactory.length) {
          d.children = sonFactory
        }
        factory.push(d);
      })
      factoryListS.value = factory;
    }
  });
};



onMounted(() => {
  loadFactorys()
})


</script>
<style  scoped>
.main {
  margin-top: 30px;
}
</style>