<template>
    <a-config-provider :locale="language === 'English' ? enUS : zhCN">
        <div class="w-full box1 p-3">
            <div class="w-full flex items-center">
                <div style="width: 120px;">{{ langObj['采样时间']+"：" }}</div>
                <a-range-picker @change="getChartData" style="width: 300px;margin-left: 20px;" v-model:value="date"
                    :disabled-date="disabledDate" @calendarChange="onCalendarChange" />

                <a-spin :spinning="loading">
                    <a-checkbox-group @change="changeType" style="margin-left: 20px;" v-model:value="checkedList" :options="plainOptions" >
                        <a-checkbox v-for="(item,index) in plainOptions" :key="index" :value="item.value">{{ item.label }}</a-checkbox>
                    </a-checkbox-group>
                </a-spin>
                
            </div>

            <div v-for="(item, index) in chartList" :key="index" class="mt-4 w-full">
                <a-spin :spinning="loading">
                    <div :id="'chart4' + index" style="width: 100%; height: 350px"></div>
                </a-spin>
            </div>
        </div>
    </a-config-provider>
</template>
<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../../common/request";
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import { transformDate2, transformDate } from "../../../common/tools";
import { Dayjs } from 'dayjs';
import { langList } from "../../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let plainOptions:any = ref([])
let checkedList = ref([])

let date: any = ref(null)


const props: any = defineProps({
    sensorInfo: null,
});
let sensorInfo: any = ref()
sensorInfo.value = props.sensorInfo

watch(
    () => props.sensorInfo,
    (newValue, oldValue) => {
        sensorInfo.value = props.sensorInfo
        init();
    }
);

let chartList = ref([])
let loading:any = ref(false)

onMounted(() => {
    init()
})

const init = () => {
    console.log('sensorInfo', sensorInfo.value)
    if (!sensorInfo.value?.id) return

    if (sensorInfo.value.dataType && sensorInfo.value.dataType.length) {
        if (sensorInfo.value.dataType.includes('高频加速度')) {
            sensorInfo.value.dataType.map((d: any, i: any) => {
                if (d === '高频加速度') {
                    sensorInfo.value.dataType.unshift(sensorInfo.value.dataType.splice(i, 1)[0]);
                }
            })
        }

        plainOptions.value = sensorInfo.value.dataType.map((d:any) => ({label: langObj.value[d], value: d}));
    }
   
//     console.log('checkedList.value', checkedList.value)
// return
    if (plainOptions.value.length) {
        if(checkedList.value?.length > 0) {
            // checkedList.value =  checkedList.value
        } else {
            checkedList.value = [plainOptions.value[0]?.value]
        }
        
        getChartData()
    }
}

const changeType = (ev: any) => {
    getChartData()
}

const getChartData = async () => {
    loading.value = true
    chartList.value = []
    let begin = ''
    let end = ''
    if (date.value && date.value.length === 2) {
        begin = transformDate2(date.value[0], 1)
        end = transformDate2(date.value[1], 2)
    } else {
        end = transformDate(new Date())
        begin = transformDate(new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 1)))
    }

    // end = '2023/07/31 09:55:10'
    // begin = '2023/07/22 13:00:11'

    let search = {
        sensorId: sensorInfo.value.id,
        type: checkedList.value,
        begin,
        end,
    }

    let result = await request.post('/api/external/sensors/quota', search)

    if (result && result.data) {
        chartList.value = result.data
    } else {
        loading.value = false
    }

    setTimeout(() => {
        loading.value = false
        chartList.value.map((d: any, index: any) => {
            initChart({ xData: d.date, yData: d.value }, 'chart4' + index, langObj.value[`${d.type}(${d.unit})`])
        })
    }, 200);


}


const initChart = (data: any, id: any, title: any) => {
    let { xData, yData } = data
    xData = xData.reverse()
    yData = yData.reverse()
    const ec = echarts as any;
    if (!document.getElementById(id)) return
    let myChartA = ec.init(document.getElementById(id));

    let option = {
        color: ['#00FFF4'],
        title: {
            text: title,
            textStyle: {
                color: '#fff'
            },
            left: 'center',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '8%',
        },
        toolbox: {
            trigger: 'axis'
        },
        dataZoom: [{ type: 'inside' }],
        xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
                showMaxLabel: true
            },
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.3
                }
            },
            data: xData
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: yData,
                type: 'line'
            }
        ]
    };

    myChartA && myChartA.setOption(option)
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
    if (!dates.value || (dates.value as any).length === 0) {
        return false;
    }
    const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
    const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
    return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
    dates.value = val;
};


</script>

<style lang="less" scoped>
/deep/ .ant-picker {
    background-color: #07249933 !important;
}

.box1 {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.btn1 {
    padding: 5px 20px;
    background-color: #072499;
    border-radius: 2px;
}
</style>
