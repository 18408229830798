<template>
    <div class="w-full">
        <div>
            <a-form
                :model="searchModel"
                name="horizontal_login"
                layout="inline"
                autocomplete="off"
            >
                <a-form-item
                    :label="langObj['设备区域']"
                    name="groupId"
                >
                    <a-select
                        v-model:value="searchModel.groupId"
                        style="width: 220px"
                        @change="selectGroup"
                        :placeholder="langObj['选择设备区域']"
                    >
                        <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id"> {{ item.name }} </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                    :label="langObj['设备']"
                    name="status"
                    >
                    <a-select
                        v-model:value="searchModel.machineId"
                        :placeholder="langObj['选择设备']"
                        style="width: 220px"
                    >
                    <a-select-option v-for="(item, index) in machineList" :key="index" :value="item.id"> {{ item.name }} </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                    :label="langObj['更新时间']"
                    name="status"
                >
                    <a-range-picker v-model:value="searchModel.date"  :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
                </a-form-item>
                <a-form-item
                    :label="langObj['严重程度']"
                    name="grade"
                    >
                    <a-select
                        v-model:value="searchModel.grade"
                        style="width: 220px"
                        :placeholder="langObj['选择严重程度']"
                    >
                        <a-select-option value="0"> {{langObj['可用']}} </a-select-option>
                        <a-select-option value="1"> {{ langObj['警戒']}} </a-select-option>
                        <a-select-option value="2"> {{langObj['故障']}} </a-select-option>
                    </a-select>
                </a-form-item>
                <!-- <a-form-item
                    label="故障模式"
                    name="status"
                    >
                    <a-select
                        v-model:value="searchModel.fault"
                        style="width: 220px"
                    >
                        <a-select-option value=""> {{ '全设备测点' }} </a-select-option>
                    </a-select>
                </a-form-item> -->

                <a-form-item
                    :label="langObj['搜索']"
                    name="status"
                    >
                    <a-input style="width: 220px"  :placeholder="langObj['请输入关键词搜索']" v-model:value="searchModel.keyword"/>
                </a-form-item>

                <a-form-item>
                    <div class="flex items-center">
                        <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4">{{langObj['重置']}}</div>

                        <div @click="search" class="btn px-5 py-1 fff cursor-pointer ml-4" style="background-color: #072499;">{{langObj['查询']}}</div>
                       
                    </div>
                </a-form-item>
                

            </a-form>
        </div>

        <div class="box mt-4" style="width: 100%;">
            <a-table 
            style="width: 100%;" 
            size="small" 
            :row-class-name="getRowClassName" 
            :columns="columns" 
            :data-source="data"
            :pagination="pagination"
            @change="handleTableChange"
            >
                <template #bodyCell="{ column, text, record }">
                    <template v-if="column.dataIndex === 'gradeText'">
                        <div class="w-full flex items-center justify-items-center">
                            <div style="width: 12px; height: 12px; border-radius: 50%;margin-right: 5px;" :style="{'background':  gradeList.find(p => p.value === text)?.color}"></div>
                            <span>{{langObj[gradeList.find(p => p.value === text)?.label ] }} </span>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'faultEvents'">
                        <span>{{langObj[text] || text}} </span>
                    </template>

                    <template v-if="column.dataIndex === 'act'">
                        <a @click="details(record)" style="color: #00FFF4 !important">{{langObj['查看详情']}}</a>
                    </template>
                </template>
               
            </a-table>
        </div>



        <a-drawer v-model:visible="visible" class="custom-class" size="large" :closable="false"  placement="right">
            <template #title>
            </template>
            <DiagnosticReportDetails ref="mrDref" :reportId="reportId" @cancel="cancel"></DiagnosticReportDetails>
        </a-drawer>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate, transformDate2, getGroupListByFactory, getRealFactoryIdList } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import DiagnosticReportDetails from "./common/DiagnosticReportDetails2.vue";
import { Dayjs } from 'dayjs';
import { langList } from "../../common/lang";
import { getGroupName } from "../../common/tools";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();

const columns = [
    {
        title: langObj.value['更新时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['设备区域'],
        dataIndex: 'groupName',
    },
    {
        title: langObj.value['设备名称'],
        dataIndex: 'machineName',
    },
    {
        title: langObj.value['严重程度'],
        dataIndex: 'gradeText',
    },
    {
        title: langObj.value['故障模式'],
        dataIndex: 'faultEvents',
    },
    {
        title: langObj.value['诊断描述'],
        dataIndex: 'content',
    },
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },
]

let gradeList = [
  { value: -1, label: "健康", color: '#61c08f' },
  { value: 0, label: "可用", color: '#d2de49' },
  { value: 1, label: "警戒", color: '#cd5f3b' },
  { value: 2, label: "故障", color: '#921e37' },
];

const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));

let searchModel:any = ref({
    groupId: null,
    machineId: null,
    keyword: '',
    date: null,
    total: 0,
    skip: 1,
    take: 10,
    sort: 'date',
    grade:null,
    order: -1
})
let data:any = ref([])
let groupList:any = ref([])
let machineList:any = ref([])
let visible = ref(false)
let reportId = ref('')
let mrDref = ref()

const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'odd-row' : 'even-row';
}

onMounted(() => {
    init()
})

const init = () => {
    getGroupList()
    search()
}

// 获取区域列表
const getGroupList = () => {
    groupList.value = getGroupListByFactory()
}

// 选择区域
const selectGroup = (ev:any) => {
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    searchModel.value.machineId = ''
    if(ev) {
        machineList.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
    } else {
        machineList.value = []
    }
}

const search = () => {
    searchModel.value.skip = 1
    getData()
}

const reset = () => {
    searchModel.value = {
        ...searchModel.value,
        grade:null,
        groupId: null,
        machineId: null,
        keyword: '',
        date: null,
    }
}

// 获取数据
const getData = async() => {
    let config: any = {
        params: {
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            type: [1],
            keyword: searchModel.value.keyword,
            sort: searchModel.value.sort,
            order: searchModel.value.order,
            machineId: searchModel.value.machineId,
            factoryIds: getRealFactoryIdList(),
            grade:searchModel.value.grade,
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (searchModel.value.date && searchModel.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
        config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
    }

    let result = await request.get('/api/external/reports', config)

    if(result && result.status === 200) {
        let member = JSON.parse(localStorage.getItem('memberInfo') || '')
        result.data.list.forEach((element: any) => {
            if (element.expertReport) {
            element.expertReport.faultCause = element.expertReport.faultCause
                ? element.expertReport.faultCause.replace(/\n/g, "<br>")
                : "";
            element.gradeText = element.expertReport.grade

            let faultEvents = ''
            element.expertReport.diagnosisDetails.forEach((item2: any, index2:any) => {
                var grade = gradeList.find((t) => t.value == item2.grade);
                item2.gradeText = grade ? grade.label : "";
                faultEvents = faultEvents + item2.faultEvent
            });

            element.faultEvents = faultEvents
            }
            if (element.machines && element.machines[0]) {
            element.machineName = element.machines[0].machineName;
            // element.groupName = member.groups.find((p:any) => p.id === element.machines[0].groupId)?.name
            element.groupName = getGroupName(element.machines[0]?.groupId)
            }
        });

        data.value = result.data.list
        searchModel.value.total = result.data.count
    }
}

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if(Object.keys(sorter).length) { 
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    searchModel.value.sort = sorter.field
    searchModel.value.order = order
  }
  // 处理分页数据
  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  getData();
};

const details = (ev:any) => {
    visible.value = true
    reportId.value = ev.id
    setTimeout(() => {
        mrDref.value.show(ev.id)
    }, 500);
}

const cancel = (ev:any) => {
    if(ev) {
        visible.value = false
        reportId.value = ''
    }
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};


</script>


<style lang="less" scoped>
.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}
.btn {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.fff {
    color: #fff
}

/deep/ .ant-table-tbody > tr > td {
    border-bottom: 0px solid #fff
}

/deep/ .ant-picker-input > input {
    color: #fff;
}

/deep/ .ant-picker-clear span {
    background: #000 !important;
}

/deep/ .ant-drawer-close span svg {
    color: #000 !important;
}
// /deep/ .anticon-close svg {
//     color: #000 !important;
// }



</style>


