import '@/design/index.less';
import 'ant-design-vue/dist/antd.css';

import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Antd from 'ant-design-vue';
import VueAxios from 'vue-axios';
//导入所有图标库
import * as Icons from "@ant-design/icons-vue";
import type { Component } from 'vue'

const app = createApp(App)
const IconData: Record<string, Component> = Icons

Object.keys(IconData).forEach((key: string) => {
    app.component(key, IconData[key]);
})


import { dispatchEventStrage } from './common/watchLocalStorage'
app.use(dispatchEventStrage)


app.use(router).use(Antd).use(VueAxios, axios).mount('#app');
