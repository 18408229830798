<template>
    <div class="w-full px-5 py-6">
        <a-form layout="inline">
            <a-form-item label="细分故障">
                <a-input
                    v-model:value="searchModel.segmentFault"
                    placeholder="搜索细分故障"
                >
                </a-input>
            </a-form-item>

            <a-form-item label="故障原因">
                <a-input
                    v-model:value="searchModel.faultCause"
                    placeholder="搜索故障原因"
                >
                </a-input>
            </a-form-item>

            <a-form-item label="建议及措施">
                <a-input
                    v-model:value="searchModel.faultSuggest"
                    placeholder="搜索建议及措施"
                >
                </a-input>
            </a-form-item>


            <a-form-item label="">
            <a-button type="primary" @click="dealWithData">
                <template #icon><SearchOutlined /></template>搜索</a-button
            >
            </a-form-item>
        </a-form>


        <a-tabs v-model:activeKey="activeKey" @change="selectTab">
            <a-tab-pane key="Expect" tab="专业指标" ></a-tab-pane>
            <a-tab-pane key="Common" tab="振动指标" ></a-tab-pane>
        </a-tabs>

        <a-table
            bordered
            :emptyText="'暂无数据'"
            :columns="columns"
            :data-source="dataList"
            :pagination="pagination"
            @change="handleTableChange"
        >
            <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'featureType'">
                    <span v-if="text === 'Expect'">专业指标</span>
                    <span v-if="text === 'Common'">振动指标</span>
                </template>

                <template v-if="column.dataIndex === 'act'">
                    <a @click="edit(record)">编辑</a>
                </template>
            </template>
        </a-table>


        <a-modal 
            v-model:visible="visible" 
            title="编辑" 
            :maskClosable="false"
            :footer="null"
            width="800px"
        >
            <a-form
                :model="form"
                name="basic"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 16 }"
            >
                <a-form-item
                    label="类型"
                    name="featureType"
                    :rules="[{ required: true, message: '请选择类型' }]"
                >
                    <!-- <a-input v-model:value="form.featureType" /> -->
                    <a-select
                        ref="select"
                        v-model:value="form.featureType"
                        :options="typeList"
                    > </a-select>
                    
                </a-form-item>

                <a-form-item
                    label="细分故障"
                    name="segmentFault"
                >
                    <a-input v-model:value="form.segmentFault" />     
                </a-form-item>

                <a-form-item
                    label="故障释义"
                    name="faultExplain"
                >
                    <a-textarea v-model:value="form.faultExplain" :rows="4" /> 
                </a-form-item>

                <a-form-item
                    label="故障原因"
                    name="faultCause"
                >
                    <a-textarea v-model:value="form.faultCause" :rows="4" /> 
                </a-form-item>

                <a-form-item
                    label="建议及措施"
                    name="faultSuggest"
                >
                    <a-textarea v-model:value="form.faultSuggest" :rows="4" /> 
                </a-form-item>

                <a-form-item :wrapper-col="{ offset: 4, span: 16 }">
                    <a-button type="primary" @click="submit">提交</a-button>
                </a-form-item>
            </a-form>
        </a-modal>

    </div>
    
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { ref, reactive, toRaw, onMounted, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";

const columns = [
{
        title: '类型',
        dataIndex: 'featureType',
        width: '120px',
    },
    {
        title: '细分故障',
        dataIndex: 'segmentFault',
        width: '120px',
    },
    {
        title: '故障释义',
        dataIndex: 'faultExplain',
        width: '150px',
    },
    {
        title: '故障原因',
        dataIndex: 'faultCause',
        width: '150px',
    },
    {
        title: '建议及措施',
        dataIndex: 'faultSuggest',
        width: '150px',
    },
    {
        title: '操作',
        dataIndex: 'act',
        width: '100px',
    },
]

let activeKey = ref('Expect')

let searchModel:any = ref({
    segmentFault: '',
    faultCause: '',
    faultSuggest: '',
    skip: 1,
    take: 20,
    total: 0,
})

const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));

let dataListAll:any = ref([])
let dataList:any = ref([])
let visible = ref(false)
let form:any = ref({})

const typeList = [
    {label: '专业指标', value: 'Expect'},
    {label: '振动指标', value: 'Common'},
]

onMounted(() => {
    init()
})

const init = () => {
    searchModel.value.skip = 1
    search()
}

const search = async() => {
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.post("/api/faultinfos",{FeatureType:''}, config)
    if(result.status === 200) {
        dataListAll.value = result.data
    } else {
        dataListAll.value = []
    }
    
    dealWithData()
}

const dealWithData = () => {
    let list = JSON.parse(JSON.stringify(dataListAll.value))
    list = list.filter((p:any) => p.featureType === activeKey.value)

    if(searchModel.value.segmentFault) {
        list = list.filter((p:any) => p.segmentFault.includes(searchModel.value.segmentFault))
    }
    if(searchModel.value.faultCause) {
        list = list.filter((p:any) => p.faultCause.includes(searchModel.value.faultCause))
    }
    if(searchModel.value.faultSuggest) {
        list = list.filter((p:any) => p.faultSuggest.includes(searchModel.value.faultSuggest))
    }

    dataList.value = list
    searchModel.value.total = dataList.value.length
}



const edit = (ev:any) => {
    form.value = JSON.parse(JSON.stringify(ev))
    visible.value = true
}


const submit = async() => {
    let result = await request.put('/api/faultinfos', form.value)
    if(result && result.data) {
        message.success('操作成功')
        visible.value = false
        init()
    } else {
        message.warning('操作失败,请联系管理员！')
    }   
}



const handleTableChange = (ev:any) => {
    searchModel.value.skip = ev.current
    searchModel.value.take = ev.pageSize
}


// 选择指标
const selectTab = (ev:any) => {
    searchModel.value.skip = 1
    dealWithData()
}

</script>
<style scoped>
.bule_list_page {
  height: calc(100vh - 100px) !important;
}
</style>
