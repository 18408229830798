/* eslint-disable prefer-rest-params */
export const dispatchEventStrage:any = () => {
    const signSetItem = localStorage.setItem
    localStorage.setItem = function(key:any, val:any){
        const setEvent:any = new Event('setItemEvent')
        setEvent.key = key
        setEvent.newValue = val
        window.dispatchEvent(setEvent)
        const a:any = arguments
        signSetItem.apply(this, a)
    }
}