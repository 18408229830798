<template>
    <div class="bule_list_page_RI p-3" style="color: #fff; min-height: 90vh;">
        <div class="flex items-center my-2">
            <div class="flex-1"></div>
            <span class="pr-4">{{langObj['报告统计时间']}}: {{ startTime }} -- {{ endTime }}</span>
            <span>{{langObj['报告日期']}}: {{ endTime }}</span>
        </div>

        <div class="px-5 py-5 rounded-md flex items-center justify-between">
            <div>
                <span class="text-xl pr-2">{{ getReportTitle(reportInfo.title) }}</span>
                <span>{{ endTime }}</span>
            </div>

            <div class="text-xl flex items-center space-x-2 text-gray-500 print:sr-only">
                <button v-if="isEditable" @click="share()" nz-button nzType="text">
                    <ShareAltOutlined class="cursor-pointer"/>
                </button>

                <button v-if="isEditable" @click="handleEdit()" nz-button nzType="text" class="ml-2">
                    <EditOutlined class="cursor-pointer" />
                </button>

                <!-- <button (click)="forwarding()" nz-button nzType="text">
                <i class="cursor-pointer" nz-icon nzType="share-alt" nzTheme="outline"></i>
            </button>

            <button *permission="'isAdmin'" (click)="handleDown()" nz-button nzType="text">
                <i class="cursor-pointer" nz-icon nzType="download" nzTheme="outline"></i>
            </button> -->

            </div>

        </div>

        <div class="my-2 flex items-center flex-wrap space-x-0 md:flex-nowrap md:space-x-2 fff">

            <div class="rounded-md p-3 my-2 box w-1/2 md:w-1/3 print:1/3">
                <div> {{ reportType === 3 ? langObj['本周设备健康总览'] : langObj['本月设备健康总览'] }} </div>
                <div v-show="chartShow" id="chartA" class="w-full h-72"></div>
            </div>

            <div class="rounded-md p-3 my-2 box w-1/2 md:w-1/3 print:1/3">
                <div> {{ reportType === 3 ? langObj['本周设备事件提醒总览'] : langObj['本月设备事件提醒总览'] }} </div>
                <div v-show="chartShow" id="chartB" class="w-full h-72"></div>
            </div>

            <div class="rounded-md p-3 my-2 box w-full md:w-1/3 print:1/3">
                <div>{{langObj['故障模式统计(本年)']}}</div>
                <div v-show="chartShow" id="chartC" class="w-full h-72"></div>
            </div>

        </div>


        <div class="rounded-md p-3 box">
            <div class="flex items-center mb-2">
                <h4 class="my-2 fff">{{langObj['设备详情清单']}}</h4>

                <div class="ml-12 flex items-center" v-if="edit">
                    <div class="mr-2">诊断经理：</div>
                    <a-select @change="selectManage" v-model:value="diagnosticManager" allowClear style="width: 200px">
                        <a-select-option v-for="(item, index) in diagnosticManagerList" :key="index"
                            :value="item._id">{{ item.userName }}</a-select-option>
                    </a-select>
                </div>

                <div class="flex-1"></div>

                <div v-if="edit">
                    <button @click="handleCancle()"
                        class="mr-4 bg-gray-200 rounded-sm px-4 py-1 cursor-pointer hover:bg-opacity-50">{{langObj['取消']}}</button>
                    <button @click="handleSave()"
                        class="mr-1 bg-blue-500 text-gray-50 rounded-sm px-4 py-1 cursor-pointer hover:bg-opacity-60">{{langObj['保存']}}</button>
                </div>
            </div>

            <div class="rounded-md border shadow">

                <div class="rounded-sm">

                    <a-table :dataSource="tableData" :columns="columns" :pagination="false" @change="tableChange"
                        size="small" :row-class-name="getRowClassName">
                        <template #faultCauseTitle>
                            <div class="flex items-center">
                                <span>{{langObj['故障原因']}}</span>
                                <div class="flex-1"></div>
                                <a v-if="!isShowFaultCause && !edit" @click="isShowFaultCause = true">{{langObj['展开']}}</a>
                                <a v-if="isShowFaultCause && !edit" @click="isShowFaultCause = false">{{langObj['隐藏']}}</a>
                            </div>
                        </template>

                        <template #faultSuggestTitle>
                            <div class="flex items-center">
                                <span>{{langObj['建议及措施']}}</span>
                                <div class="flex-1"></div>
                                <a v-if="!isShowFaultSuggest && !edit" @click="isShowFaultSuggest = true">{{langObj['展开']}}</a>
                                <a v-if="isShowFaultSuggest && !edit" @click="isShowFaultSuggest = false">{{langObj['隐藏']}}</a>
                            </div>
                        </template>

                        <template #machineName="{ text, record }">
                            <a @click="handleMachineName(record)">
                                {{ text }}
                            </a>
                        </template>

                        <template #status="{ text, record }">
                            <div v-if="!edit">
                                <span v-if="text === '故障'" style="color: #921e37;">{{ langObj[text] }}</span>
                                <span v-else-if="text === '警戒'" style="color: #cd5f3b;">{{ langObj[text] }}</span>
                                <span v-else-if="text === '可用'" style="color: #d2de49;">{{ langObj[text] }}</span>
                                <span v-else-if="text === '健康'" style="color: #61c08f;">{{ langObj[text] }}</span>
                                <span v-else>{{ langObj[text] }}</span>
                            </div>

                            <div v-if="edit">
                                <a-select class="p-2 rounded-md border border-gray-200 w-full focus:outline-none"
                                    v-model:value="record.status" :options="statusList">
                                </a-select>
                            </div>

                        </template>

                        <template #mark="{ text, record }">
                            <div v-if="!edit">
                                <span>{{ langObj[text]  }}</span>
                            </div>

                            <div v-if="edit">
                                <div v-if="edit">
                                    <a-select class="p-2 rounded-md border border-gray-200 w-full focus:outline-none"
                                        v-model:value="record.mark" :options="markList">
                                    </a-select>
                                </div>
                            </div>

                        </template>

                        <template #diagnosisDetails="{ text, record, index }">
                            <div v-if="!edit">
                                <div class="flex items-center w-full print:flex-wrap">
                                    <div class="w-36 print:w-full">
                                        <div :title="langObj[record.faultEvent] || record.faultEvent" class="px-2 py-1 rounded-sm w-full"
                                            v-if="record.grade === -1" style="color: #61c08f;border: 1px solid #61c08f;">
                                            {{ langObj[record.faultEvent] || record.faultEvent }}</div>
                                        <div :title="langObj[record.faultEvent] || record.faultEvent" class="px-2 py-1 rounded-sm w-full"
                                            v-if="record.grade === 0" style="color: #CEE008;border: 1px solid #CEE008;">
                                            {{ langObj[record.faultEvent] || record.faultEvent }}</div>
                                        <div :title="langObj[record.faultEvent] || record.faultEvent" class="px-2 py-1 rounded-sm w-full"
                                            v-if="record.grade === 1" style="color: #DF572D;border: 1px solid #DF572D;">
                                            {{ langObj[record.faultEvent] || record.faultEvent }}</div>
                                        <div :title="langObj[record.faultEvent] || record.faultEvent" class="px-2 py-1 rounded-sm w-full"
                                            v-if="record.grade === 2" style="color: #A20335;border: 1px solid #A20335;">
                                            {{ langObj[record.faultEvent] || record.faultEvent }}</div>
                                    </div>
                                    <div :title="record.diagnoseMessage" class="flex-1 px-2 print:py-2">
                                        {{ record.diagnoseMessage }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="edit">
                                <div class="flex items-center w-full flex-wrap">
                                    <div class="w-1/2">
                                        <a-cascader class="w-full" v-model:value="record.faultEvent"
                                            :options="cascaderOptions" :show-search="{ filter }"
                                            @change="selectFault($event, index)" :allowClear="false"></a-cascader>
                                    </div>

                                    <div class="w-1/2">
                                        <a-select :placeholder="langObj['严重程度']" v-model:value="record.grade"
                                            class="p-2 rounded-md border border-gray-200 w-full focus:outline-none">
                                            <a-select-option v-for="(grade, index) in gradeList" :key="index"
                                                :value="grade.value">{{ langObj[grade.label] }}</a-select-option>
                                        </a-select>
                                    </div>

                                    <div class="mt-2 w-full">
                                        <!-- <a-textarea v-model:value="record.diagnoseMessage"
                                            class="p-2 rounded-md border border-gray-200 w-full focus:outline-none"
                                            :cols="100" :rows="3"></a-textarea> -->
                                        <a-textarea class="w-full" :cols="100" v-model:value="record.diagnoseMessage"  :maxlength="-1" :rows="3" />
                                    </div>

                                </div>

                                <div class="px-2">
                                    <CloseCircleOutlined @click="deleteEle(index)" class="cursor-pointer" />
                                </div>


                                <div class="mt-3">
                                    <button @click="handleCreate(index)"
                                        class="mr-1 bg-blue-300 text-gray-50 rounded-md px-4 py-1 cursor-pointer hover:bg-blue-400 w-full">{{langObj['新增']}}</button>
                                </div>
                            </div>

                        </template>

                        <template #faultCause="{ record }">
                            <div v-if="edit">
                                <a-textarea v-model:value="record.faultCause" :rows="4" />
                            </div>
                            <div v-else>
                                <div v-for="(txt, i) in record.faultCauseList || []" :key="i">
                                    <div v-if="i <= 1">{{ txt }}</div>
                                    <div v-if="i > 1 && isShowFaultCause">{{ txt }}</div>
                                    <div v-if="i > 1 && !isShowFaultCause">
                                        <div v-if="i === 2">......</div>
                                        <div v-if="i > 2" hidden></div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template #faultSuggest="{ record }">
                             <div v-if="edit">
                                <a-textarea v-model:value="record.faultSuggest" :rows="4" />
                            </div>
                            <div v-else>
                                <div v-for="(txt, i) in record.faultSuggestList || []" :key="i">
                                    <div v-if="i <= 1">{{ txt }}</div>
                                    <div v-if="i > 1 && isShowFaultSuggest">{{ txt }}</div>
                                    <div v-if="i > 1 && !isShowFaultSuggest">
                                        <div v-if="i === 2">......</div>
                                        <div v-if="i > 2" hidden></div>
                                    </div>
                                </div>
                            </div>


                            
                        </template>

                        <template #repairList="{ text }">
                            <div v-for="(repair, i) in text" :key="i" class="py-1 border-b border-gray-300 text-xs">
                                {{ repair }}</div>
                        </template>
                    </a-table>
                </div>
            </div>
        </div>


        <a-modal :width="850" :maskClosable="false" v-model:visible="visible" :footer="null" :title="langObj['周报转发：复制链接转发，用户可在电脑端或手机端查看']">
            <div class="w-full flex items-center" style="color: #fff; font-size: 16px;">
                <div>{{langObj['pc端链接']}}: </div>
                <div class="ml-2" id="pc">{{ urlpc }}</div>
                <div class="flex-1"></div>
                <div class="cursor-pointer" @click="copyUrl('pc')">
                    <CopyOutlined />
                </div>
            </div>
            <div class="w-full flex items-center" style="color: #fff; font-size: 16px;">
                <div>{{langObj['手机端链接']}}: </div>
                <div class="ml-2" id="mb">{{ urlmb }}</div>
                <div class="flex-1"></div>
                <div class="cursor-pointer" @click="copyUrl('mb')">
                    <CopyOutlined />
                </div>
            </div>
        </a-modal>


    </div>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { transformDate } from "../../common/tools";
import { useRouter, useRoute } from "vue-router";
import { computed, defineComponent, onMounted, h } from "vue";
import { ref, reactive, toRaw } from "vue";
import { message, TableProps } from 'ant-design-vue';
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import { langList } from "../../common/lang";
import { getFactoryName, getGroupName } from "../../common/tools";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();

const route = useRoute()
let reportInfo: any = ref({})
let chartData: any = ref([])
let incidents: any = ref({})
let faultMode: any = ref(null)
let edit = ref(false)
let weekReports: any = ref([])
let statusList: any = ref([  //设备状态
    { label: langObj.value['故障'], value: '故障' },
    { label: langObj.value['警戒'], value: '警戒' },
    { label: langObj.value['可用'], value: '可用' },
    { label: langObj.value['健康'], value: '健康' },
    { label: langObj.value['短暂开机'], value: '短暂开机' },
    { label: langObj.value['未开机'], value: '未开机' },
    { label: langObj.value['传输异常'], value: '传输异常' },
])
let markList: any = ref([    //变化趋势
    { label: langObj.value['明显发展'], value: '明显发展' },
    { label: langObj.value['发展'], value: '发展' },
    { label: langObj.value['轻微发展'], value: '轻微发展' },
    { label: langObj.value['异常特征减弱'], value: '异常特征减弱' },
    { label: langObj.value['有变化'], value: '有变化' },
    { label: langObj.value['无明显变化'], value: '无明显变化' },
    { label: langObj.value['恢复健康'], value: '恢复健康' },
])
let gradeList = [
    { value: -1, label: '健康' },
    { value: 0, label: '可用' },
    { value: 1, label: '警戒' },
    { value: 2, label: '故障' },
];
let cascaderOptions: any = ref([])
interface Option {
    value: string;
    label: string;
    disabled?: boolean;
    children?: Option[];
}
const filter = (inputValue: string, path: Option[]) => {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};

let tableDataSave: any = ref([])
let tableData: any = ref([])
let isShowFaultCause = ref(false)
let isShowFaultSuggest = ref(false)

let faultInfoList: any = []

let reportType: any = ref(3)

let columns = [
    {
        title: langObj.value['设备名称'],
        dataIndex: 'machineName',
        slots: { customRender: 'machineName' },
        customCell: (_: any, index: any) => ({ rowspan: _.span, }),
        align: "center"
    },
    {
        title:  langObj.value['设备区域'],
        dataIndex: 'groupName',
        sorter: true,
        customCell: (_: any, index: any) => ({ rowspan: _.span, }),
        align: "center"
    },
    {
        title:  langObj.value['设备状态'],
        dataIndex: 'status',
        sorter: true,
        customCell: (_: any, index: any) => ({ rowspan: _.span, }),
        slots: { customRender: 'status' },
        align: "center"
    },
    {
        title:  langObj.value['状态变化'],
        dataIndex: 'mark',
        sorter: true,
        slots: { customRender: 'mark' },
        customCell: (_: any, index: any) => ({ rowspan: _.span, }),
        align: "center"
    },
    {
        title:  langObj.value['诊断结果'],
        dataIndex: 'diagnosisDetails',
        slots: { customRender: 'diagnosisDetails' },
        align: "center"
    },
    {
        width: '200px',
        dataIndex: 'faultCause',
        slots: { title: 'faultCauseTitle', customRender: 'faultCause' },
        align: "center"
    },
    {
        width: '200px',
        dataIndex: 'faultSuggest',
        slots: { title: 'faultSuggestTitle', customRender: 'faultSuggest' },
        align: "center"
    },
    {
        title: reportType.value === 3 ?  langObj.value['本周检修/检查'] :  langObj.value['本月检修/检查'],
        dataIndex: 'repairList',
        slots: { customRender: 'repairList' },
        align: "center"
    }
]

let chartShow = ref(false)

let startTime: any = ref('')
let endTime: any = ref('')
let diagnosticManagerList:any = ref([])
let diagnosticManager:any = ref('')

// 修改报告
const handleEdit = () => {
    edit.value = true
}

// 取消
const handleCancle = () => {
    edit.value = false
    dealWithData()
}

// 保存
const handleSave = async () => {
    let machineIds = tableDataSave.value.map((p: any) => p.machineId)
    machineIds = [... new Set(machineIds)]
    let weekReport: any = []
    machineIds.map((machineId: any) => {
        let info = tableDataSave.value.find((p: any) => p.machineId === machineId)
        let list = tableDataSave.value.filter((p: any) => p.machineId === machineId)
        let diagnosisDetails: any = []
        list && list.length && list.map((d: any) => {
            if (Array.isArray(d.faultEvent)) {
                d.faultEvent = d.faultEvent.pop()
            }
            diagnosisDetails.push({
                diagnoseMessage: d.diagnoseMessage,
                faultCause: d.faultCause,
                faultEvent: d.faultEvent,
                faultSuggest: d.faultSuggest,
                grade: d.grade,
            })
        })

        weekReport.push({
            groupId: info.groupId, //
            groupName: info.groupName,
            machineId: info.machineId,
            machineName: info.machineName,
            mark: info.mark,
            markSort: markList.value.findIndex((ele: any) => ele.label === info.mark),
            status: info.status,
            statusSort: statusList.value.findIndex((ele: any) => ele.label === info.status),
            diagnosisDetails,
        })

    })

    weekReport.map((d:any, index:any) => {
        let oldInfo = weekReports.value.find((p:any) => p.machineId === d.machineId)

        let isChanged = false
        if(d.status !== oldInfo.status) {
            isChanged = true
        }
        if(d.mark !== oldInfo.mark) {
            isChanged = true
        }

        let newList = d.diagnosisDetails.map((p:any) => ({
            diagnoseMessage: p.diagnoseMessage,
            faultCause: p.faultCause,
            faultEvent: p.faultEvent,
            faultSuggest: p.faultSuggest,
            grade: p.grade,
        }))

        let oldList = oldInfo.diagnosisDetails.map((p:any) => ({
            diagnoseMessage: p.diagnoseMessage,
            faultCause: p.faultCause,
            faultEvent: p.faultEvent,
            faultSuggest: p.faultSuggest,
            grade: p.grade,
        }))

        if(JSON.stringify(newList) !== JSON.stringify(oldList)) {
            isChanged = true
        }
        d.isChanged = isChanged
    })

    let params = {}

    if (reportInfo.value.type === 3) {
        params = {
            ...reportInfo.value,
            weekReport
        }
    } else {
        params = {
            ...reportInfo.value,
            monthReport: weekReport
        }
    }

    let result = await request.put('/api/reports', params)
    if (result?.data) {
        edit.value = false
        init()
        message.success('操作成功')
    } else {
        message.warning('操作失败')
    }

}

// 新增
const handleCreate = (index: any) => {
    let data = tableData.value.find((p: any) => p.machineId === tableData.value[index].machineId && p.span)
    data.span++
    let form = {
        ...tableData.value[index],
        diagnoseMessage: "",
        faultCause: "",
        faultEvent: "",
        faultSuggest: "",
        grade: -1,
        span: 0,
        faultSuggestList: [],
        faultCauseList: [],
    }

    tableData.value.splice(index + 1, 0, form)
}

// 删除
const deleteEle = (index: any) => {
    console.log('tableData.value[index].span', tableData.value[index].span)
    console.log(' tableData.value',  tableData.value)
    // return

    if (tableData.value[index].span) {
        if (tableData.value[index].span > 1) {
            // 下级继承
            tableData.value[index + 1].span = tableData.value[index].span - 1
            tableData.value.splice(index, 1)
        } else {
            if(tableData.value[index].span === 1) {
                tableData.value[index] = {
                    ...tableData.value[index],
                    diagnoseMessage: null,
                    faultCause: null,
                    faultEvent: null,
                    faultSuggest: null,
                    grade: null,
                    span: 1,
                    faultSuggestList: null,
                    faultCauseList: null,
                }
            } else {
                tableData.value.splice(index, 1)
            }
        }
    } else {
        // 上级span-1
        let data = tableData.value.find((p: any) => p.machineId === tableData.value[index].machineId && p.span)
        data.span--
        tableData.value.splice(index, 1)
    }
}

// 设备跳转
const router = useRouter()
const handleMachineName = (ev: any) => {
    let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
    if (ev.machineId && memberInfo.machines.find((p: any) => p.id === ev.machineId)) {
        localStorage.setItem('machineId', ev.machineId)
        router.push('/diagnosis/intelligent')
    }
}

// 本周设备健康总览绘图
const initChartA = () => {
    const ec = echarts as any;
    if (!document.getElementById('chartA')) return

    let myChart = ec.init(document.getElementById('chartA'));
    myChart.clear()
    if (!chartData.value || !chartData.value.length) {
        myChart.clear()
        return
    }
    const option = {
        color: chartData.value.map((p: any) => p.color),

        tooltip: {
            trigger: 'item',
        },
        legend: {
            bottom: 'center',
            orient: 'vertical',
            left: '5%',
            textStyle: {
                color: '#fff',
            },
            formatter: function (name: any) {
                return `${langObj.value[name]} ( ${ option.series[0].data.find((p: any) => p.name === name)?.value} )`;
            },
        },
        series: [
            {
                name: langObj.value['设备状态'],
                type: 'pie',
                radius: ['50%', '70%'],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 3,
                },
                label: {
                    show: false,
                    position: 'center',
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '14',
                        fontWeight: 'normal',
                    },
                },
                labelLine: {
                    show: false,
                },
                data: chartData.value,
            },
        ],
    };

    myChart.setOption(option);
}

// 本周设备事件提醒总览
const initChartB = (status1: any, status2: any, status3: any) => {
    if (!document.getElementById("chartB")) return
    let myChart = echarts.init(document.getElementById("chartB") as HTMLDivElement);
    let option = {
        grid: {
            top: '4%',
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            show: false,
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: [langObj.value['智能诊断'], langObj.value['门限报警']],
                axisLabel: {
                    color: '#fff'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {    //网格线
                    lineStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: true //隐藏或显示
                },
            }
        ],
        series: [
            {
                name: langObj.value['可用'],
                type: 'bar',
                barGap: 0,
                emphasis: {
                    focus: 'series'
                },
                data: status1,
                itemStyle: {
                    color: '#d2de49'
                }
            },
            {
                name: langObj.value['警戒'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: status2,
                itemStyle: {
                    color: '#cd5f3b'
                }
            },
            {
                name: langObj.value['故障'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: status3,
                itemStyle: {
                    color: '#921e37'
                }
            },
        ]
    };

    myChart && myChart.setOption(option)
}

// 故障模式统计(本年)
const initChartC = () => {
    let data = reportInfo.value.expertFaultModeStatistics || {}
    let chartData: any[] = [];

    Object.keys(data).forEach(ele => {
        let min = {
            value: data[ele],
            name: ele,
        }
        chartData.push(min)
    })

    const ec = echarts as any;
    if (!document.getElementById('chartC')) return

    let myChart = ec.init(document.getElementById('chartC'));
    myChart.clear()
    const option = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: 'center',
            orient: 'vertical',
            left: '2%',
            textStyle: {
                color: "#fff"
            },
            formatter: function (name: any) {
                return `${langObj.value[name] || name}`;
            },
        },
        series: [
            {
                name: langObj.value['故障模式'],
                type: 'pie',
                radius: ['50%', '70%'],
                center: ['65%', '50%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 3,
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '14',
                        fontWeight: 'normal'
                    }
                },
                labelLine: {
                    show: false
                },
                data: chartData
            }
        ]
    };


    myChart.setOption(option)

}


// 处理数据
const dealWithData = async() => {
    const {
        weekReport,
        monthReport,
    } = reportInfo.value;

    columns[7].title = reportInfo.value.type === 3 ? langObj.value['本周检修/检查'] : langObj.value['本月检修/检查']

    let reportList: any = reportInfo.value.type === 3 ? weekReport : monthReport

    let statusList2 = reportList.map((d: any) => d.status);
    let healthList = statusList2.filter((p: any) => p === '健康');
    let availableList = statusList2.filter((p: any) => p === '可用');
    let alertList = statusList2.filter((p: any) => p === '警戒');
    let faultList = statusList2.filter((p: any) => p === '故障');
    let notbootList = statusList2.filter((p: any) => p === '未开机');

    chartData.value = [];
    healthList.length && chartData.value.push({ value: healthList.length, name: '健康', sort: 0, color: '#1CC48B' });
    availableList.length && chartData.value.push({ value: availableList.length, name: '可用', sort: 1, color: '#CEE008' });
    alertList.length && chartData.value.push({ value: alertList.length, name: '警戒', sort: 2, color: '#DF572D' });
    faultList.length && chartData.value.push({ value: faultList.length, name: '故障', sort: 3, color: '#A20335' });
    notbootList.length && chartData.value.push({ value: notbootList.length, name: '未开机', sort: 4, color: 'rgb(162, 166, 171)' });

    if (reportList && reportList.length > 0) {
        reportList.forEach((element: any, i:any) => {
            element.classIndex = i
            element.markSort = markList.value.findIndex((ele: any) => ele.value === element.mark)
            element.statusSort = statusList.value.findIndex((ele: any) => ele.value == element.status)
            if (element.repair) {
                element.repairList = element.repair.split("|")
            } else {
                element.repairList = []
            }
        });

        weekReports.value = reportList
    }

    let config = {
        params: {
            factoryId: reportInfo.value.factoryId,
            begin: startTime.value,
            end: endTime.value,
        },
        headers: {
            requestId: uuidv4(),
        },
    }

    let warningResult = await request.get('/api/report/warning', config)
    console.log('warningResult', warningResult)
    let status1 = [0, 0]
    let status2 = [0, 0]
    let status3 = [0, 0]
    if(warningResult?.data) {
        status1 = [warningResult.data.type1[0], warningResult.data.type4[0]]
        status2 = [warningResult.data.type1[1], warningResult.data.type4[1]]
        status3 = [warningResult.data.type1[2], warningResult.data.type4[2]]
    }

    chartShow.value = true
    setTimeout(() => {
        initChartA()
        initChartB(status1, status2, status3)
        initChartC()
    }, 1000);

    let sortTwo = (a:any, b:any) => {
        if(a.statusSort > b.statusSort) {
            return 1
        } else if(a.statusSort === b.statusSort) {
            if(a.markSort > b.markSort) {
                return 1
            } else {
                return -1
            }
        } else {
            return -1
        }
    }

    reportList.sort((a:any, b:any) => {return sortTwo(a, b)})

    // 新表格数据
    tableData.value = []
    reportList && reportList.map((item: any) => {
        if (item.diagnosisDetails && item.diagnosisDetails.length) {
            item.diagnosisDetails.map((son: any, index: any) => {
                tableData.value.push({
                    ...son,
                    machineName: item.machineName,
                    machineId: item.machineId,
                    // groupName: item.groupName,
                    groupName: getGroupName(item.groupId),
                    mark: item.mark,
                    status: item.status,
                    span: index === 0 ? item.diagnosisDetails.length : 0,
                    groupId: item.groupId,
                    repairList: item.repairList,
                    classIndex: item.classIndex,
                })
            })
        } else {
            tableData.value.push({
                machineName: item.machineName,
                machineId: item.machineId,
                // groupName: item.groupName,
                groupName: getGroupName(item.groupId),
                mark: item.mark,
                status: item.status,
                span: 1,
                groupId: item.groupId,
                repairList: item.repairList,
                classIndex: item.classIndex,
            })
        }
    })

    tableData.value.map((d: any) => {
        if (d.faultCause) {
            d.faultCauseList = d.faultCause.replaceAll('\n', '\r\n').split('\r\n').filter((d: any) => d)
        }
        if (d.faultSuggest) {
            d.faultSuggestList = d.faultSuggest.replaceAll('\n', '\r\n').split('\r\n').filter((d: any) => d)
        }
    })

    tableDataSave.value = JSON.parse(JSON.stringify(tableData.value))
    selectManage(null)
}

// 排序
const tableChange = (pagination: any, filters: any, sorter: any) => {
    if (!sorter.order) {
        return
    }

    function compare(property: any, sort: any) {
        return function (a: any, b: any) {
            var value1 = a[property];
            var value2 = b[property];
            if(sort === 'ascend') {
                return value1 >= value2 ? 1 : -1
            } else {
                return value1 <= value2 ? 1 : -1
            }
            // return sort === 'ascend' ? value1 >= value2 : value2 < value1
        }
    }
    // 设备区域
    console.log('sorter.field',sorter.field)
    console.log('sorter.order',sorter.order)
    console.log('tableData.value',tableData.value)
    if (sorter.field === 'groupName') {
        tableData.value.sort(compare(sorter.field, sorter.order))
    }

    // 设备状态
    if (sorter.field === 'status') {
        let machineIds: any = tableData.value.map((p: any) => p.machineId)
        machineIds = [...new Set(machineIds)]
        let machines: any = []
        machineIds.map((d: any) => {
            let list = tableData.value.filter((p: any) => p.machineId === d)
            machines.push({
                list,
                statusSort: statusList.value.findIndex((ele: any) => ele.label === langObj.value[list[0].status])
            })
        })
        machines.sort(compare('statusSort', sorter.order))
        let tableList: any = []
        machines.map((d: any) => {
            tableList = tableList.concat(d.list)
        })

        tableData.value = tableList
    }

    // 状态变化
    if (sorter.field === 'mark') {
        let machineIds: any = tableData.value.map((p: any) => p.machineId)
        machineIds = [...new Set(machineIds)]
        let machines: any = []
        machineIds.map((d: any) => {
            let list = tableData.value.filter((p: any) => p.machineId === d)
            machines.push({
                list,
                markSort: markList.value.findIndex((ele: any) => ele.label === langObj.value[list[0].mark])
            })
        })
        machines.sort(compare('markSort', sorter.order))
        let tableList: any = []
        machines.map((d: any) => {
            tableList = tableList.concat(d.list)
        })

        tableData.value = tableList
    }

}

// 选择诊断经理
const selectManage = async(ev: any) => {
    let list = tableDataSave.value
    if(ev) {
        const config = {
            params: {
                diagnosticManager: ev
            },
            headers: {
                requestId: uuidv4(),
            },
        }
        let result = await request.get('/api/principal/all', config)
        let machineIds:any = []
        if(result?.data?.length) {
            machineIds = result.data.map((p:any) => p.machineId)
        }
        tableData.value = list.filter((p:any) => machineIds.includes(p.machineId))
    } else {
        tableData.value = list
    }
}

// 获取faultInfos
const getFaultInfos = () => {
    cascaderOptions.value = [];
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };
    request.post("/api/faultinfos", { FeatureType: '' }, config).then((res: any) => {
        if (res) {
            faultInfoList = res.data
            let list = res.data
            let expects = list.filter((item: { featureType: string; }) => item.featureType === "Expect")
            let commons = list.filter((item: { featureType: string; }) => item.featureType === "Common")
            let others = list.filter((item: { featureType: string; }) => item.featureType === "Other")
            let expectList: any[] = []
            let commonsList: any[] = []
            let othersList: any[] = []
            expects.forEach((item: any) => {
                if (expectList.findIndex((ele: any) => ele.value === item.segmentFault) === -1) {
                    expectList.push({
                        value: item.segmentFault,
                        label: item.segmentFault,
                    })
                }
            })

            commons.forEach((item: any) => {
                if (commonsList.findIndex((ele: any) => ele.value === item.segmentFault) === -1) {
                    commonsList.push({
                        value: item.segmentFault,
                        label: item.segmentFault,
                    })
                }
            })

            others.forEach((item: any) => {
                if (othersList.findIndex((ele: any) => ele.value === item.segmentFault) === -1) {
                    othersList.push({
                        value: item.segmentFault,
                        label: item.segmentFault,
                    })
                }
            })

            cascaderOptions.value = [
                {
                    value: '专业指标',
                    label: langObj.value['专业指标'],
                    children: expectList,
                },
                {
                    value: '振动指标',
                    label: langObj.value['振动指标'],
                    children: commonsList,
                },
                {
                    value: '其他',
                    label: langObj.value['其他'],
                    children: othersList,
                },
            ];
        }
    });
}

// 选择fault
const selectFault = (ev: any, index: any) => {
    if (ev && ev.length === 2) {
        let faultInfo = faultInfoList.find((p: any) => p.segmentFault === ev[1])

        if (faultInfo.faultCause) {
            tableData.value[index].faultCause = faultInfo.faultCause;
            tableData.value[index].faultCauseList = faultInfo.faultCause.split('\r\n').filter((d: any) => d)
        } else {
            tableData.value[index].faultCause = ''
            tableData.value[index].faultCauseList = []
        }

        if (faultInfo.faultSuggest) {
            tableData.value[index].faultSuggest = faultInfo.faultSuggest;
            tableData.value[index].faultSuggestList = faultInfo.faultSuggest.split('\r\n').filter((d: any) => d)
        } else {
            tableData.value[index].faultSuggest = ''
            tableData.value[index].faultSuggestList = []
        }

    }
}


let visible = ref(false)
let urlpc:any = ref('')
let urlmb:any = ref('')
const share = () => {
    urlpc.value = location.host + '/#/week-report/' + route.params.id + '?lang=' + language.value
    urlmb.value = location.host + '/#/week-report-mobile/' + route.params.id + '?lang=' + language.value
    visible.value = true
}

//复制功能
const copyUrl = (rf:any) => {
    let node = document.getElementById(rf)
    if(window.getSelection() && node) {
        window.getSelection()?.selectAllChildren(node);
    }
    let copy = document.execCommand("Copy");
    if (copy) {
        document.execCommand("Copy");
        message.success('复制完成')
    } else {
        message.warning('由于浏览器版本问题，复制失败，请手动复制~')
    }
}

let isEditable:any = ref(false)
const init = () => {
    chartShow.value = false
    getFaultInfos()
    const config = {
        params: {
            id: route.params.id
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    reportInfo.value = {}
    request.get("/api/reports/info", config).then((res) => {
        if (res && res.status === 200) {
            // 演示账号
            isEditable.value = true



            reportInfo.value = res.data
            reportType.value = res.data.type
            endTime.value = transformDate(reportInfo.value.date)
            let num = reportType.value === 3 ? 7 : 30
            startTime.value = transformDate(new Date(endTime.value).getTime() - (1000 * 60 * 60 * 24 * num))

            dealWithData()
        }
    });
}

onMounted(() => {
    init()
    getManagers()
})

const getReportTitle = (title:any) => {
    if(!title) {
        title = ''
    }
    if(language.value == 'English') {
        return title.replace('周报', 'weekly report')
    } else {
        return title
    }
}


// 获取诊断经理列表
const getManagers = async() => {
    const config = {
        params: {
            factoryId: "65e29561f747d7e32bbed049",
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let resultRole = await request.get("/api/roles", config)
    let rolelist:any = []
    if(resultRole?.data?.list) {
        rolelist = resultRole.data.list
    }
    let diagnosticManagerRoleId = rolelist.find((p:any) => p.name === '诊断工程师')?.id

    if(diagnosticManagerRoleId) {
        let config2 = {
            params: {
                roleId: diagnosticManagerRoleId,
                factoryId: "65e29561f747d7e32bbed049",
            },
            headers: {
                requestId: uuidv4(),
            },
        };
        let result2 = await request.get("/api/members", config2)
        if(result2?.data?.list) {
            diagnosticManagerList.value = result2.data.list
        }
    }

    console.log('diagnosticManagerList', diagnosticManagerList.value)
}


const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return record.classIndex % 2 === 0 ? "even-row" : "odd-row";
};
 

</script>

<style lang="less" scoped>
    /deep/ td.ant-table-column-sort {
        background: none !important;
    }
    .fff {
        color: #fff
    }

    .box {
        border: 1px solid #0d53b7cc;
    }

    .bule_list_page_RI {
        background: #000A32 !important;
        color: white;
        overflow-y: scroll;
        height: calc(100vh - 100px) !important;
    }
</style>
