import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-179923cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cardHead" }
const _hoisted_2 = { style: {"position":"relative","top":"4px","left":"5px"} }
const _hoisted_3 = {
  class: "main",
  style: {"max-height":"1450px","overflow-y":"scroll"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { style: {"max-height":"600px","overflow-y":"scroll"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node_index_outlined = _resolveComponent("node-index-outlined")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _ctx.locale === 'en' ? _ctx.enUS : _ctx.zhCN
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_a_card, { bordered: false }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_node_index_outlined),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.threshold.sensorPlace), 1)
            ])
          ]),
          extra: _withCtx(() => [
            _createVNode(_component_a_radio_group, { size: "default" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_button, {
                  value: "b",
                  onClick: _ctx.add
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.langObj['新增']), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                (_ctx.isEdit)
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 0,
                      value: "c",
                      onClick: _ctx.qX
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['取消']), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 1,
                      value: "c",
                      disabled: !(_ctx.dataSource&&_ctx.dataSource.length),
                      onClick: _ctx.enableEdit
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['编辑']), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.isEdit)
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 2,
                      value: "c",
                      onClick: _ctx.saveConfig
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['保存']), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_radio_button, {
                  value: "d",
                  onClick: _ctx.copy
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.langObj['复制']), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_radio_button, {
                  value: "d",
                  onClick: _ctx.pasteOverlay
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.langObj['粘贴']), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_popconfirm, {
                  title: _ctx.langObj['确定删除所选数据么']+'?',
                  "ok-text": _ctx.langObj['确定'],
                  "cancel-text": _ctx.langObj['取消'],
                  onConfirm: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteThis()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_button, { value: "d" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['删除']), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["title", "ok-text", "cancel-text"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_tabs, {
          activeKey: _ctx.formState.activeKey,
          "onUpdate:activeKey": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.activeKey) = $event)),
          onChange: _ctx.tabsChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, {
              key: "Expect",
              tab: _ctx.langObj['专业指标'],
              disabled: _ctx.isEdit
            }, null, 8, ["tab", "disabled"]),
            _createVNode(_component_a_tab_pane, {
              key: "Common",
              tab: _ctx.langObj['振动指标'],
              disabled: _ctx.isEdit,
              "force-render": ""
            }, null, 8, ["tab", "disabled"]),
            _createVNode(_component_a_tab_pane, {
              key: "Other",
              tab: _ctx.langObj['其他'],
              disabled: _ctx.isEdit
            }, null, 8, ["tab", "disabled"])
          ]),
          _: 1
        }, 8, ["activeKey", "onChange"]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createBlock(_component_a_table, {
            bordered: "",
            emptyText: '暂无数据',
            "row-selection": {
          selectedRowKeys: _ctx.mainSelectedRowKeys,
          onChange: _ctx.onMainSelectChange,
        },
            key: _ctx.formState.activeKey+'tb',
            columns: _ctx.columns,
            "data-source": _ctx.dataSource,
            pagination: false,
            onChange: _ctx.onMainSelectChange
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'scores')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (!_ctx.isEdit)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.scores, (item) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: item.faultId+(item.name || item.scoreName)
                            }, [
                              (!((item.name || item.scoreName).indexOf('Vexp')>-1))
                                ? (_openBlock(), _createBlock(_component_a_tag, {
                                    color: "green",
                                    key: item.faultId+(item.name || item.scoreName)
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name || item.scoreName) + ":" + _toDisplayString(item.scoreValue), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_a_form, { layout: "inline" }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.scores, (item) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: item.faultId+(item.name || item.scoreName)
                                }, [
                                  (!((item.name || item.scoreName).indexOf('Vexp')>-1))
                                    ? (_openBlock(), _createBlock(_component_a_form_item, {
                                        key: item.faultId+(item.name || item.scoreName),
                                        label: item.name || item.scoreName,
                                        name: item.name || item.scoreName,
                                        required: item.required
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(), _createBlock(_component_a_input_number, {
                                            key: item.faultId+(item.name || item.scoreName),
                                            step: 0.01,
                                            value: item.scoreValue,
                                            "onUpdate:value": ($event: any) => ((item.scoreValue) = $event),
                                            placeholder: _ctx.langObj['请输入']
                                          }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                        ]),
                                        _: 2
                                      }, 1032, ["label", "name", "required"]))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["row-selection", "columns", "data-source", "onChange"]))
        ]),
        _createVNode(_component_a_modal, {
          width: 850,
          maskClosable: false,
          visible: _ctx.modalState.visible,
          "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalState.visible) = $event)),
          title: _ctx.modalState.title,
          "confirm-loading": _ctx.modalState.confirmLoading,
          onOk: _ctx.handleOk
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_a_table, {
                class: "modal-table",
                bordered: "",
                "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
          },
                columns: _ctx.columnsZB,
                pagination: false,
                "data-source": _ctx.displayData,
                onChange: _ctx.handleTableChange
              }, null, 8, ["row-selection", "columns", "data-source", "onChange"])
            ])
          ]),
          _: 1
        }, 8, ["visible", "title", "confirm-loading", "onOk"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}