<template>
  <a-config-provider :locale="locale === 'en' ? enUS : zhCN">
  <div>
    <a-card :bordered="false">
      <template #title>
        <div class="cardHead">
          <node-index-outlined />
          <span style="position: relative; top: 4px; left: 5px">{{
            threshold.sensorPlace
          }}</span>
        </div>
      </template>
      <template #extra>
        <a-radio-group size="default">
          <!-- <a-radio-button value="a" @click="TB">同步标准</a-radio-button> -->
          <a-radio-button value="b" @click="add">{{langObj['新增']}}</a-radio-button>
          <a-radio-button value="c" v-if="isEdit" @click="qX"
            >{{langObj['取消']}}</a-radio-button
          >
          <a-radio-button value="c" v-if="!isEdit" :disabled="!(dataSource&&dataSource.length)" @click="enableEdit"
            >{{langObj['编辑']}}</a-radio-button
          >
          <a-radio-button value="c" v-if="isEdit" @click="saveConfig"
            >{{langObj['保存']}}</a-radio-button
          >
          <a-radio-button value="d" @click="copy">{{langObj['复制']}}</a-radio-button>
          <a-radio-button value="d" @click="pasteOverlay"
            >{{langObj['粘贴']}}</a-radio-button
          >
          <!-- <a-radio-button value="d" @click="pasteAppend"
            >粘贴追加</a-radio-button
          > -->
          <a-popconfirm
            :title="langObj['确定删除所选数据么']+'?'"
            :ok-text="langObj['确定']"
            :cancel-text="langObj['取消']"
            @confirm="deleteThis()"
          >
            <a-radio-button value="d">{{langObj['删除']}}</a-radio-button>
          </a-popconfirm>


          <!-- <a-radio-button value="d" @click="deleteThis">{{langObj['删除']}}</a-radio-button> -->
        </a-radio-group>
      </template>
    </a-card>
    <a-tabs v-model:activeKey="formState.activeKey" @change="tabsChange">
      <a-tab-pane key="Expect" :tab="langObj['专业指标']" :disabled="isEdit"></a-tab-pane>
      <a-tab-pane
        key="Common"
        :tab="langObj['振动指标']"
        :disabled="isEdit"
        force-render
      ></a-tab-pane>
      <a-tab-pane key="Other" :tab="langObj['其他']" :disabled="isEdit"></a-tab-pane>
    </a-tabs>
    <div class="main" style="max-height: 1450px; overflow-y: scroll">
      <a-table
        bordered
        :emptyText="'暂无数据'"
        :row-selection="{
          selectedRowKeys: mainSelectedRowKeys,
          onChange: onMainSelectChange,
        }"
        :key="formState.activeKey+'tb'"
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        @change="onMainSelectChange"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'scores'">
            <div v-if="!isEdit">
              <span  v-for="item in record.scores"
                :key="item.faultId+(item.name || item.scoreName)">
                <a-tag
                v-if="!((item.name || item.scoreName).indexOf('Vexp')>-1)"
                color="green"
                :key="item.faultId+(item.name || item.scoreName)"
                >{{ item.name || item.scoreName }}:{{ item.scoreValue }}</a-tag
              >
              </span>
            
            </div>
            <div v-else>
              <a-form
                layout="inline" >
              <span
              v-for="item in record.scores"
                :key="item.faultId+(item.name || item.scoreName)"
              >
              <a-form-item
              v-if="!((item.name || item.scoreName).indexOf('Vexp')>-1)"
                :key="item.faultId+(item.name || item.scoreName)"
                :label="item.name || item.scoreName"
                :name="item.name || item.scoreName"
                :required="item.required"
              >
                <a-input-number
                  :key="item.faultId+(item.name || item.scoreName)"
                  :step="0.01"
                  v-model:value="item.scoreValue"
                  :placeholder="langObj['请输入']"
                />
              </a-form-item></span>
            </a-form>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <a-modal
      :width="850"
      :maskClosable="false"
      v-model:visible="modalState.visible"
      :title="modalState.title"
      :confirm-loading="modalState.confirmLoading"
      @ok="handleOk"
    >
      <div style="max-height: 600px; overflow-y: scroll">
        <a-table class="modal-table"
          bordered
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :columns="columnsZB"
          :pagination="false"
          :data-source="displayData"
          @change="handleTableChange"
        />
      </div>
    </a-modal>
  </div>
</a-config-provider>
</template>
<script lang="ts">
import request from "../../../common/request";
import { langList } from "../../../common/lang";
import { defineComponent, watch } from "vue";
import { ref, reactive, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import type { TableProps } from "ant-design-vue";
import { operateLogs } from "@/common/logs";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { locale } from "moment";


export default defineComponent({
  props: {
    threshold: {
      type: Object,
      default: () => ({}),
    },
    baseModel: {
      type: Array,
      default: () => [],
    },
    
    
  },
  computed: {
    displayData():any {
      const { current, pageSize } = this.pagination;
      const start = (current - 1) * pageSize;
      const end = start + pageSize;
      console.log(this.pagination);
      return this.baseModelSource.slice(start, end);
    },
  },
  watch: {
    threshold: {
      handler(value) {
        debugger
        this.dataSource.value = this.$props.threshold.thresholds[
          this.formState.activeKey
        ].map((t: any) => ({ ...t, key: t.faultId || t.id }));
      },
      deep: true, //
    },
    
    baseModel: {
      handler(value) {
        debugger
        this.baseModelSource.value = value.filter(
          (t: any) => t.featureType == this.formState.activeKey
        );
        this.pagination.total = this.baseModelSource.value.length;
        console.log(value);
      },
      deep: true, //
    },
  },
  setup(props, { emit }) {
    watch(()=>props.threshold, (newVal, oldVal) => { 
      dataSource.value = props.threshold.thresholds[
          formState.activeKey
        ].map((t: any) => ({ ...t, key: t.faultId || t.id }));
    })
    const sensorsSource = ref([]);
   

    let langObj: any = ref({})
    let language: any = ref('Chinese')
    const getLang = () => {
        language.value = localStorage.getItem('language') || 'Chinese'
        langObj.value = langList[language.value]
        console.log(langObj.value,"langObj.valuelangObj.value")
    }
    getLang()


    let dataSource = ref<any>();
    const pagination = ref({
      total: 0,
      current: 1,
      pageSize: 10000,
    });
    const formState = reactive({
      activeKey: "Expect",
    });
    const columns = [
      {
        title: langObj.value["细分故障"],
        dataIndex: "segmentFault",

      },
      {
        title: langObj.value["计算方法"],
        dataIndex: "faultFunction",
      },
      {
        title: langObj.value["指标"],
        dataIndex: "scores",
      },
    ];
    const columnsZB = [
      {
        title: langObj.value["细分故障"],
        dataIndex: "segmentFault",
      },
      {
        title: langObj.value["计算方法"],
        dataIndex: "faultFunction",
      },
    ];

    const modalState = ref({
      visible: false,
      title: langObj.value["新增"],
      confirmLoading: false,
      roleName: "",
      meun: [],
    });

    // 新增
    const add = () => {
      selectedRowKeys.value = dataSource.value.map((t: any) => t.faultId);
      modalState.value.visible = true;
    };
    let baseModelSource = ref<any>();
    const tabsChange = () => {
      baseModelSource.value = props.baseModel.filter(
        (t: any) => t.featureType == formState.activeKey
      );
      pagination.value.total = baseModelSource.value.length;
      dataSource.value = props.threshold.thresholds[formState.activeKey].map(
        (t: any) => ({ ...t, key: t.faultId || t.id })
      );
    };

    const handleTableChange = (val: any) => {
      pagination.value = val;
    };
    let threshold = ref<any>();

    console.log(props.threshold);
    threshold.value = props.threshold;
    baseModelSource.value = props.baseModel.filter(
      (t: any) => t.featureType == "Expect"
    );
    pagination.value.total = baseModelSource.value.length;

    const selectedRowKeys = ref<any>();
    const selectedRows = ref<any>();
    const onSelectChange = (selectedRowKeys2: any, selectedRows2: any) => {
      console.log("selectedRowKeys changed: ", selectedRowKeys2);
      console.log("selectedRows changed: ", selectedRows2);
      selectedRowKeys.value = selectedRowKeys2;
      selectedRows.value = selectedRows2;
    };
    // 添加确定
    const handleOk = () => {
      var selected = JSON.parse(JSON.stringify(selectedRows.value))
      dataSource.value = selected.concat(dataSource.value);
      dataSource.value = Array.from(
        dataSource.value
          .reduce((m: any, t: any) => m.set(t.faultFunction, t), new Map())
          .values()
      );
      
      modalState.value.visible = false;
      isEdit.value = true;
    };
    // 保存配置
    const saveConfig = async () => {
      console.log('dataSource.value', dataSource.value);
      var param:any = {
        sensorId: threshold.value.sensorId,
        featureType: formState.activeKey,
        faultFunctions: [],
        scoreNames: [],
      };
      // dataSource.value.forEach(async (item:any) => {
      //   param.scoreNames.push(item.scoreName);
      //   param.faultFunctions.push(item.faultFunction);
      //   param[item.scoreName + "_name"] = item.faultName;
      //   await item.scores.forEach(async (t:any) => {
      //     param[t.name] = t.scoreValue || t.default;
      //   });
      // });
      var flag = true;
      var msg = "";
      for (var i = 0; i < dataSource.value.length; i++) {
        var item: any = dataSource.value[i];
        console.log(item);
        param.scoreNames.push(item.scoreName);
        param.faultFunctions.push(item.faultFunction);
        param[item.scoreName + "_name"] = item.faultName||item.segmentFault;
        for (var j = 0; j < item.scores.length; j++) {
          var t = item.scores[j];
          if((t.name && t.name.includes('Vexp')) || (t.scoreName && t.scoreName.includes('Vexp'))) {
            console.log('t', t)
            t.scoreValue = t.default
          }
          param[t.name || t.scoreName] = t.scoreValue || t.default||t.scoreValue ;
          if (!t.scoreValue && t.scoreValue != 0) {
            msg = "参数：" + (item.featureName || item.mainFault) + '的' + t.name + "未输入";
            flag = false;
            break;
          }
        }
      }
      if (!flag) {
        message.warning(msg);
        return;
      }
      console.log(param);
      const config = {
        headers: {
          requestId: uuidv4(),
        },
      };
      request.put("/api/machines/threshold", param, config).then((res) => {
        if (res) {
          operateLogs('保存模型配置，传感器：'+threshold.value.sensorPlace);
          message.success(langObj.value["操作成功"]);
          isEdit.value = false;
          emit("search");
        }
      });
    };

    const isEdit = ref(false);
    const enableEdit = () => {
      isEdit.value = true;
    };
    dataSource.value = threshold.value.thresholds[formState.activeKey].map(
      (t: any) => ({ ...t, key: t.faultId || t.id })
    );

    const mainSelectedRowKeys = ref<any>();
    const mainSelectedRows = ref<any>();
    const onMainSelectChange = (selectedRowKeys2: any, selectedRows2: any) => {
      console.log("selectedRowKeys changed: ", selectedRowKeys2);
      console.log("selectedRows changed: ", selectedRows2);
      mainSelectedRowKeys.value = selectedRowKeys2;
      mainSelectedRows.value = selectedRows2;
    };
    const copyInfo = ref();
    const copySensorId = ref();
    const copyType = ref();

    const qX = () => {
      emit("search");
      isEdit.value = false;
    };

    // 复制
    const copy = () => {
      if (!mainSelectedRows.value||!mainSelectedRows.value.length) {
        message.warning(langObj.value["请选择复制的内容"]);
        return;
      }
      localStorage.setItem("copyType", formState.activeKey);
      localStorage.setItem("sensorId", threshold.value.sensorId);
      localStorage.setItem("copyRows", JSON.stringify(mainSelectedRows.value));
      message.success(langObj.value["复制成功"]);
    };
    // 粘贴覆盖
    const pasteOverlay = () => {
      if (localStorage.getItem("copyRows")) {
        copyInfo.value = JSON.parse(localStorage.getItem("copyRows") || "");
        copySensorId.value = localStorage.getItem("sensorId");
        copyType.value = localStorage.getItem("copyType");
        if (
          copySensorId.value == threshold.value.sensorId ||
          formState.activeKey != copyType.value
        ) {
          message.warning(langObj.value["不允许粘贴"]);
          return;
        }
        dataSource.value = dataSource.value.concat(copyInfo.value);
        dataSource.value = Array.from(
          dataSource.value
            .reduce((m: any, t: any) => m.set(t.faultFunction, t), new Map())
            .values()
        );
        message.success(langObj.value["粘贴成功"]);
        isEdit.value = true;
      } else {
        message.warning(langObj.value["没有复制的内容"]);
        return;
      }
    };
    // 粘贴追加
    const pasteAppend = () => {
      if (localStorage.getItem("copyRows")) {
        copyInfo.value = JSON.parse(localStorage.getItem("copyRows") || "");
        copySensorId.value = localStorage.getItem("sensorId");
        copyType.value = localStorage.getItem("copyType");
        if (
          copySensorId.value == threshold.value.sensorId ||
          formState.activeKey != copyType.value
        ) {
          message.warning(langObj.value["不允许粘贴"]);
          return;
        }
        dataSource.value = copyInfo.value.concat(dataSource.value);
        dataSource.value = Array.from(
          dataSource.value
            .reduce((m: any, t: any) => m.set(t.faultFunction, t), new Map())
            .values()
        );
        message.success(langObj.value["粘贴成功"]);
        isEdit.value = true;
      } else {
        message.warning(langObj.value["没有复制的内容"]);
        return;
      }
    };
    // 删除
    const deleteThis = () => {
      if (!mainSelectedRows.value||!mainSelectedRows.value.length) {
        message.warning(langObj.value["请选择内容"]);
        return;
      }
      var param: any = {
        sensorId: threshold.value.sensorId,
        featureType: formState.activeKey,
        faultFunctions: [],
        scoreNames: [],
        scores: [],
      };
      // dataSource.value.forEach(async (item:any) => {
      //   param.scoreNames.push(item.scoreName);
      //   param.faultFunctions.push(item.faultFunction);
      //   param[item.scoreName + "_name"] = item.faultName;
      //   await item.scores.forEach(async (t:any) => {
      //     param[t.name] = t.scoreValue || t.default;
      //   });
      // });
      for (var i = 0; i < mainSelectedRows.value.length; i++) {
        var item: any = mainSelectedRows.value[i];
        param.scoreNames.push(item.scoreName);
        param.faultFunctions.push(item.faultFunction);
        for (var j = 0; j < item.scores.length; j++) {
          var t = item.scores[j];
          param.scores.push(t.name || t.scoreName);
        }
      }
      console.log(JSON.stringify(param));
      const config = {
        params: param,
        data: param,
        headers: {
          requestId: uuidv4(),
        },
      };
      request.delete("/api/machines/threshold", config).then((res) => {
        if (res) {
          message.success(langObj.value["操作成功"]);
          isEdit.value = false;
          emit("search");
        }
      });
    };
    // 同步标准
    const TB = () => {
      const config = {
        data:{ id: threshold.value.sensorId },
        headers: {
          requestId: uuidv4(),
        },
      };
      request.put("/api/sensors/parameter/nationalsta",config.data, config).then((res: any) => {
        if (res.data) {
          message.success(langObj.value["操作成功"]);
        }else{
          message.warning(res.message||langObj.value['操作失败'])
        }
      });
    };
    return {
      qX,
      copyInfo,
      TB,
      pasteAppend,
      deleteThis,
      pasteOverlay,
      copy,
      threshold,
      sensorsSource,
      dataSource,
      formState,
      columns,
      handleOk,
      handleTableChange,
      onMainSelectChange,
      modalState,
      add,
      columnsZB,
      pagination,
      baseModelSource,
      tabsChange,
      selectedRowKeys,
      onSelectChange,
      isEdit,
      enableEdit,
      saveConfig,
      mainSelectedRowKeys,
      langObj
    };
  },
});
</script>
<style scoped>
/deep/ .ant-card-head {
  padding-left: 0px;
}
.cardHead {
  color: #1890ff;
  /* border-left:3px solid  #1890ff; */
  padding-left: 0px;
  font-size: 18px;
}
.main {
  margin-top: 30px;
}
</style>
