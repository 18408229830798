export const icons =  {
    "step-backward": "step-backward",
    "step-forward": "step-forward",
    "fast-backward": "fast-backward",
    "fast-forward": "fast-forward",
    "shrink": "shrink",
    "arrows-alt": "arrows-alt",
    "down": "down",
    "up": "up",
    "left": "left",
    "right": "right",
    "caret-up": "caret-up",
    "caret-down": "caret-down",
    "caret-left": "caret-left",
    "caret-right": "caret-right",
    "up-circle": "up-circle",
    "down-circle": "down-circle",
    "left-circle": "left-circle",
    "right-circle": "right-circle",
    "double-right": "double-right",
    "double-left": "double-left",
    "forward": "forward",
    "backward": "backward",
    "rollback": "rollback",
    "enter": "enter",
    "retweet": "retweet",
    "swap": "swap",
    "swap-left": "swap-left",
    "swap-right": "swap-right",
    "arrow-up": "arrow-up",
    "arrow-down": "arrow-down",
    "arrow-left": "arrow-left",
    "arrow-right": "arrow-right",
    "play-circle": "play-circle",
    "play-circle-o": "play-circle-o",
    "up-square": "up-square",
    "down-square": "down-square",
    "left-square": "left-square",
    "right-square": "right-square",
    "login": "login",
    "logout": "logout",
    "menu-fold": "menu-fold",
    "menu-unfold": "menu-unfold",
    "question": "question",
    "question-circle": "question-circle",
    "plus": "plus",
    "plus-circle": "plus-circle",
    "pause": "pause",
    "pause-circle": "pause-circle",
    "minus": "minus",
    "minus-circle": "minus-circle",
    "plus-square": "plus-square",
    "minus-square": "minus-square",
    "info": "info",
    "info-circle": "info-circle",
    "exclamation": "exclamation",
    "exclamation-circle": "exclamation-circle",
    "close": "close",
    "close-circle": "close-circle",
    "close-square": "close-square",
    "check": "check",
    "check-circle": "check-circle",
    "check-square": "check-square",
    "clock-circle": "clock-circle",
    "warning": "warning",
    "lock": "lock",
    "unlock": "unlock",
    "area-chart": "area-chart",
    "pie-chart": "pie-chart",
    "bar-chart": "bar-chart",
    "line-chart": "line-chart",
    "dot-chart": "dot-chart",
    "radar-chart": "radar-chart",
    "heat-map": "heat-map",
    "fall": "fall",
    "rise": "rise",
    "stock": "stock",
    "box-plot": "box-plot",
    "fund": "fund",
    "sliders": "sliders",
    "bars": "bars",
    "alert": "alert",
    "tool": "tool",
    "apartment": "apartment",
    "appstore": "appstore",
    "dashboard": "dashboard",
    "container": "container",
    "reconciliation": "reconciliation",
    "user": "user",
    "book": "book",
    "calendar": "calendar",
    "cloud": "cloud",
    "cloud-download": "cloud-download",
    "code": "code",
    "copy": "copy",
    "credit-card": "credit-card",
    "delete": "delete",
    "desktop": "desktop",
    "download": "download",
    "edit": "edit",
    "ellipsis": "ellipsis",
    "file": "file",
    "file-add": "file-add",
    "file-excel": "file-excel",
    "file-exclamation": "file-exclamation",
    "file-image": "file-image",
    "file-pdf": "file-pdf",
    "file-powerpoint": "file-powerpoint",
    "file-text": "file-text",
    "file-unknown": "file-unknown",
    "file-word": "file-word",
    "filter": "filter",
    "folder": "folder",
    "folder-add": "folder-add",
    "folder-open": "folder-open",
    "format-painter": "format-painter",
    "gift": "gift",
    "hdd": "hdd",
    "heart": "heart",
    "home": "home",
    "inbox": "inbox",
    "info-circle-fill": "info-circle-fill",
    "insurance": "insurance",
    "interaction": "interaction",
    "layout": "layout",
    "like": "like",
    "lock-fill": "lock-fill",
    "mail": "mail",
    "medicine-box": "medicine-box",
    "meh": "meh",
    "message": "message",
    "mobile": "mobile",
    "notification": "notification",
    "phone": "phone",
    "picture": "picture",
    "printer": "printer",
    "pushpin": "pushpin",
    "red-envelope": "red-envelope",
    "rocket": "rocket",
    "save": "save",
    "schedule": "schedule",
    "security-scan": "security-scan",
    "setting": "setting",
    "shop": "shop",
    "shopping-cart": "shopping-cart",
    "sketch": "sketch",
    "smile": "smile",
    "sound": "sound",
    "star": "star",
    "switcher": "switcher",
    "tablet": "tablet",
    "tag": "tag",
    "tags": "tags",
    "trademark": "trademark",
    "trophy": "trophy",
    "usb": "usb",
    "video-camera-add": "video-camera-add",
    "video-camera": "video-camera",
    "wallet": "wallet",
    "wechat": "wechat",
    "weibo-circle": "weibo-circle",
    "weibo-square": "weibo-square",
    "yahoo": "yahoo",
    "youtube": "youtube",
    "yuque": "yuque",
    "zhihu": "zhihu"
}