import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { authGuard } from "@/common/auth-guard";
import { menuGuard } from "@/common/menu-guard";
import HomeView from "../views/HomeView.vue";
import System from "../components/system/Index.vue";
import Member from "../components/system/Member.vue"; // 账号管理
import Role from "../components/system/Role.vue"; // 角色管理
import Menu from "../components/system/Menu.vue"; // 菜单配置

import WiredCollection from "../components/system/WiredCollection.vue"; // 有线采集
import BigScreen from "../components/system/BigScreen.vue"; // 大屏配置

/**日志 */
import OperateLogs from "../components/system/OperateLogs.vue"; // 操作日志
import LoginLogs from "../components/system/LoginLogs.vue"; // 登录日志
import DataChangesLogs from "../components/system/DataChangesLogs.vue"; // 数据变更日志

import Log from "../components/system/Log.vue"; // 系统日志
import UserLog from "../components/system/UserLog.vue"; // 用户日志

/**诊断管理 */
import Model from "../components/system/Model.vue"; // 模型配置
import Dpc from "../components/system/Dpc.vue"; // 参数配置
import SingleWash from "../components/system/SingleWash.vue"; //数据清洗配置
import EventOrder from "../components/system/EventOrder.vue"; // 诊断工单管理

/**设备诊断 新*/
import Overview from "../components/diagnosis/overview.vue"; // 设备健康总览
import SensorN from "../components/diagnosis/sensor.vue"; // 设备健康总览


/**报警管理 */
import SmartAlarm from "../components/system/SmartAlarm.vue"; // 状态监测报警
import DiagnosisWaring from "../components/system/DiagnosisWaring.vue"; // 状态监测报警
import RuleSetting from "../components/system/RuleSetting.vue"; // 基础设置
import MonitorWaring from '../components/system/MonitorWaring.vue' // 系统监测报警

/** DT*/
import CaptureCard from "../components/system/CaptureCard.vue"; // 采集器管理

/**状态监测 */
import SensorMonitoring from "../components/system/SensorMonitoring.vue"; // 传感器监测
import SoftwareMonitoring from "../components/system/SoftwareMonitoring.vue"; // 软件监测

/**知识库 */
import Metric from "../components/knowledge/metric.vue"

/**非标 */
import Nonstandard from "../components/nonstandard/Index.vue"

// 大屏
import cliqueDashboard from "../components/dashboard/clique.vue"  //集团大屏
import factoryDashboard from "../components/dashboard/factory.vue"  //厂级大屏
import machineDashboard from "../components/dashboard/machine.vue"  //设备大屏

// 设备管理
import Equipment from "../components/equipment/equipment.vue"

// 报警管理
import WarningList from "../components/warning-manage/warning-list.vue"
// 报警规则
import PushRule from "../components/warning-manage/push-rule.vue"
// 报告
import ReportList from "../components/report4/list.vue"
import ReportTemplate from "../components/report4/template.vue"
import MWReportInfo from "../components/report4/MWReportInfo.vue"

// 知识库
import KnowledgeFault from "../components/knowledge/fault.vue"
import KnowledgeModel from "../components/knowledge/model.vue"

import factoryModel from "../components/system/factory.vue"

// 开关机
import onModel from "../components/system/On.vue"
// 设备绑定
import MachineBind from "../components/system/machine-bind.vue"

//工况模型配置
import simpleModel from '../components/model/simple.vue'
import groupModel from '../components/model/group.vue'
import vibrationModel from '../components/model/vibration.vue';

//巡检管理
import statistics from '../components/inspection/statistics.vue'
import report from '../components/inspection/report.vue'
import diagnosis from '../components/inspection/diagnosis.vue'

import InspectionThreshold from "../components/system/inspectionThreshold.vue"



const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        beforeEnter: authGuard,
        component: HomeView,
        children: [
            // { path: "dashboard", component: Dashboard },
            {
                path: "dashboard",
                redirect: "/dashboard/clique",
                children: [
                    { path: "clique", component: cliqueDashboard, beforeEnter: menuGuard },
                    { path: "factory", component: factoryDashboard, beforeEnter: menuGuard },
                    { path: "machine", component: machineDashboard, beforeEnter: menuGuard },
                ]
            },

            {
                path: "diagnosisN",
                redirect: "/diagnosisN/overview",
                children: [
                    { path: "overview", component: Overview, beforeEnter: menuGuard },
                    { path: "sensor", component: SensorN, beforeEnter: menuGuard },
                    { path: "simple-model", component: simpleModel, beforeEnter: menuGuard },
                    { path: "group-model", component: groupModel, beforeEnter: menuGuard },
                    { path: "vibration-model", component: vibrationModel, beforeEnter: menuGuard }
                ],
            },

            {
                path: "equipment",
                redirect: "/equipment/list",
                children: [
                    { path: "list", component: Equipment, beforeEnter: menuGuard },
                ],
            },

            {
                path: "warning-manage",
                redirect: "/warning-manage/warning-list",
                children: [
                    { path: "warning-list", component: WarningList, beforeEnter: menuGuard },
                    { path: "push-rule", component: PushRule, beforeEnter: menuGuard },
                    // 报警管理中的智能诊断配置,需要隐藏专家看护配置
                    {
                        path: "diagnosisWaring",
                        component: DiagnosisWaring,
                        props: { hideExpertCareOptions: true },
                        beforeEnter: menuGuard
                    },
                    // 报警管理中的指标报警配置,需要隐藏专家看护配置
                    {
                        path: "smartAlarm",
                        component: SmartAlarm,
                        props: { hideExpertCareOptions: true },
                        beforeEnter: menuGuard
                    }
                ],
            },

            {
                path: "report",
                redirect: "/report/list",
                children: [
                    { path: "list", component: ReportList, beforeEnter: menuGuard },
                    { path: "mwDetail/:id", component: MWReportInfo },
                    { path: "template", component: ReportTemplate, beforeEnter: menuGuard },
                ],
            },

            {
                path: "knowledge",
                redirect: "/knowledge/fault",
                children: [
                    { path: "fault", component: KnowledgeFault, beforeEnter: menuGuard },
                    { path: "model", component: KnowledgeModel, beforeEnter: menuGuard },
                ],
            },
            // statistics
            {
                path: "inspection",
                redirect: "/inspection/statistics",
                children: [
                    { path: "statistics", component: statistics, beforeEnter: menuGuard },
                    { path: "report", component: report, beforeEnter: menuGuard },
                    { path: "diagnosis", component: diagnosis, beforeEnter: menuGuard },
                ],

            },

            {
                path: "system",
                redirect: "/system/role",
                component: System,
                children: [
                    { path: "member", component: Member, beforeEnter: menuGuard },
                    { path: "role", component: Role, beforeEnter: menuGuard },
                    { path: "menu", component: Menu, beforeEnter: menuGuard },
                    { path: "model", component: Model, beforeEnter: menuGuard },
                    { path: "dpc", component: Dpc, beforeEnter: menuGuard },
                    { path: "eventOrder", component: EventOrder, beforeEnter: menuGuard },
                    { path: "singleWash", component: SingleWash, beforeEnter: menuGuard },
                    { path: "ruleSetting", component: RuleSetting, beforeEnter: menuGuard },
                    { path: "diagnosisWaring", component: DiagnosisWaring, beforeEnter: menuGuard },
                    { path: "smartAlarm", component: SmartAlarm, beforeEnter: menuGuard },
                    { path: "factory", component: factoryModel, beforeEnter: menuGuard },
                    { path: "on", component: onModel, beforeEnter: menuGuard },
                    { path: "machine-bind", component: MachineBind, beforeEnter: menuGuard },
                    { path: "inspection-threshold", component: InspectionThreshold, beforeEnter: menuGuard }
                ],
            },

            { path: "", redirect: "dashboard" },
            { path: "metric", component: Metric },
            { path: "nonstandard", component: Nonstandard },

        ],
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/LoginView.vue"),
    },
    {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
    { path: "/forbidden-403", component: () => import("../views/forbidden-403.vue") },
    {
        path: "/week-report/:id",
        name: "week-report",
        component: () => import("../components/output/week-report.vue"),
    },
    
    {
        path: "/week-report-mobile/:id",
        name: "week-report-mobile",
        component: () => import("../components/output/week-report-mobile.vue"),
    },
    {
        path: "/base-report-mobile/:id",
        name: "base-report-mobile",
        component: () => import("../components/output/base-report-mobile.vue"),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
