<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

.ant-table-row-selected {
  background-color: #0d53b7cc;
}
</style>
<script>
// 进入全屏
window.requestFullscreen = function requestFullscreen() {
  if (document.documentElement.requestFullscreen) {
    document.getElementById("ant-layout-content").requestFullscreen();
  } else if (document.documentElement.webkitRequestFullscreen) {
    document.getElementById("ant-layout-content").webkitRequestFullscreen();
  } else if (document.documentElement.msRequestFullscreen) {
    document.getElementById("ant-layout-content").msRequestFullscreen();
  }
}

// 退出全屏
window.exitFullscreen = function exitFullscreen() {
  if (document.fullscreenElement) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}

// 监听 F11 键的按下事件
document.addEventListener('keydown', function (event) {
  if (event.keyCode === 122) { // F11 的键码为 122
    window.requestFullscreen();
    event.preventDefault(); // 阻止浏览器默认行为
  }
});

// 监听 ESC 键的按下事件
document.addEventListener('keydown', function (event) {
  if (event.keyCode === 27) { // ESC 的键码为 27
    window.exitFullscreen();
  }
});
</script>
